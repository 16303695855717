import React, { FC, useEffect, useMemo, useState } from "react"

import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Popover, Tooltip, Checkbox } from "antd"

import { IAttributionItem } from "../../../../../../app/types/filter"
import { useActions } from "../../../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../../../shared/common/hooks/useTypedSelector"

const ManualSelect: FC<IAttributionItem> = ({
                                              id,
                                              icon,
                                              name,
                                              selectValues,
                                              description,
                                              isDashboardAttributes,
                                            }) => {
  const filtersReq = useTypedSelector((state) =>
      isDashboardAttributes
          ? state.attributes.attributesDashboard
          : state.attributes.attributes
  )

  const {
    setAttributes,
    setAllDataFetching,
    setDashboardAttributes,
    setAllBlocksFetching,
  } = useActions()

  const [ isPopoverVisible, setIsPopoverVisible ] = useState(false)
  const [ selectedIds, setSelectedIds ] = useState<string[]>([])

  useEffect(() => {
    initialCurrentSelection()
  }, [])

  const isAddModalBlockDefaultValue = useMemo(
      () => selectValues.find((el) => el?.default),
      [ selectValues ]
  )

  const initialCurrentSelection = () => {
    const initialValue = filtersReq[`${id}`]
    if (isAddModalBlockDefaultValue) {
      activateSelection(isAddModalBlockDefaultValue, false)
      return
    }
    if (initialValue) {
      const _index = String(initialValue)
      activateSelection(
          selectValues.find((value) => value.id === _index),
          true
      )
    } else {
      activateSelection(selectValues[0], true)
    }
  }

  const activateSelection = (value: any, initial = false) => {
    // Проверяем, что value существует и имеет id
    if (!value || !value.id) return

    let updatedSelectedIds = [ value.id ] // Только один параметр выбран

    setSelectedIds(updatedSelectedIds)

    // Создаем payload для фильтров
    const payload = {
      ...filtersReq,
      [`${id}`]: updatedSelectedIds,
    }

    // Если ничего не изменилось, не обновляем фильтры
    const isPayloadEqualPrevious =
        JSON.stringify(payload) === JSON.stringify(filtersReq)
    if (!initial && !isPayloadEqualPrevious) {
      if (isDashboardAttributes) {
        setDashboardAttributes(payload)
      } else {
        setAttributes(payload)
      }
    }

    // Активируем обработку данных
    if (isDashboardAttributes) {
      setAllBlocksFetching({ value: true })
      setTimeout(() => {
        setAllBlocksFetching({ value: false })
      }, 3000)
    }

    !initial && setAllDataFetching({ value: true })
    setIsPopoverVisible(false)
  }

  useEffect(() => {
    if (id && filtersReq && filtersReq[`${id}`]) {
      setSelectedIds(filtersReq[`${id}`])
    }
  }, [ filtersReq, id ])

  const handleVisibleChange = (newVisible: boolean) => {
    setIsPopoverVisible(newVisible)
  }

  const handleCheckboxChange = (checked: boolean, valueId: string) => {
    const newSelectedIds = checked
        ? [ ...selectedIds, valueId ]
        : selectedIds.filter((id) => id !== valueId)
    setSelectedIds(newSelectedIds)

    // Немедленно обновляем фильтры, как в activateSelection
    const payload = {
      ...filtersReq,
      [`${id}`]: newSelectedIds,
    }

    if (isDashboardAttributes) {
      setDashboardAttributes(payload)
    } else {
      setAttributes(payload)
    }

    // Обновляем данные сразу
    setAllDataFetching({ value: true })

    // попап
    setIsPopoverVisible(false)
  }

  const renderSelectionList = () => (
      <div className="params__attr-select-list">
        {/* Рендер с чекбоксами */}
        {!selectValues.find((el) => el.icon) ? (
            selectValues.map((value) => (
                <div key={value.id} className="segment-list-item">
                  <Checkbox
                      checked={selectedIds.includes(value.id)}
                      onChange={(e) => handleCheckboxChange(e.target.checked, value.id)}
                  >
                    {value.name}
                  </Checkbox>
                </div>
            ))
        ) : (
            // Рендер без чекбоксов
            selectValues.map((value) => (
                <div
                    key={value.id}
                    className="segment-list-item"
                    onClick={() => {
                      setIsPopoverVisible(false)
                      activateSelection(value, false) // Выбираем только один элемент
                    }}
                >
                  <div>
                    {value.icon ? value.icon : null} {value.name}
                  </div>
                </div>
            ))
        )}
      </div>
  )

  // Отображаем выбранные значения через |
  const displaySelection = selectedIds
      .map((id) => selectValues.find((item) => item.id === id)?.name || "")
      .filter((name) => name.trim() !== "") // Убираем пустые имена
      .join(" | ")

  return (
      <div className="params__attr-list-item">
        <Popover
            content={renderSelectionList()}
            open={isPopoverVisible}
            onOpenChange={handleVisibleChange}
            trigger="click"
            placement="bottomLeft"
            showArrow={false}
            overlayStyle={{ paddingTop: 0 }}
        >
          <Button icon={icon ? icon : null} className="params__segment-show-button">
            {name}&nbsp;
            {displaySelection || "Не выбрано"}
            <DownOutlined />
          </Button>
        </Popover>

        {description && (
            <span style={{ marginLeft: 6, marginRight: 10, marginTop: 3 }}>
          <Tooltip
              placement="bottom"
              title={
                <div
                    className="page-title-tooltip-text"
                    dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              }
              color="#fff"
              zIndex={9999}
              trigger="click"
              overlayClassName="page-title-tooltip"
          >
            <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
          </Tooltip>
        </span>
        )}
      </div>
  )
}

export default ManualSelect
