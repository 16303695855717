import React, { FC } from "react";

import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import cn from "classnames";
import { Dropdown, Menu, Tooltip } from "antd";
import { getCookie } from "../../../../../../helpers/utils/cookies";

const ExpandIcon: FC<any> = ({
  loading,
  expanded,
  depth,
  expandable,
  onExpand,
  setExpandedAll,
  pathStr2,
}) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (expanded) {
      setExpandedAll((prev: string[]) => prev.filter((el) => el === pathStr2));
    }
    onExpand(!expanded);
  };
  const handleExpandAllClick = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setExpandedAll((prev: string[]) => [...new Set([...prev, pathStr2])]);
    onExpand(!expanded);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleExpandAllClick}>
        Раскрыть все
      </Menu.Item>
    </Menu>
  );

  const isNumberDisplay = getCookie("chosenDisplay") === "number";

  return (
    <>
      <div
        className="table-expanded-indent"
        style={{ marginLeft: 25 * depth }}
      ></div>
      {!loading ? (
        !expanded ? (
          <div
            className={cn("table-expanded-icon", { spaced: !expandable })}
            style={{
              display: depth === 0 && !expandable ? "none" : "inline-flex",
            }}
          >
            {expandable && onExpand && !isNumberDisplay ? (
              <Dropdown overlay={menu} placement="bottomLeft">
                <div onClick={handleClick}>
                  {expanded ? (
                    <MinusSquareOutlined style={{ color: "#5b5b5b" }} />
                  ) : (
                    <PlusSquareOutlined style={{ color: "#5b5b5b" }} />
                  )}
                </div>
              </Dropdown>
            ) : null}
          </div>
        ) : (
          <div
            onClick={expandable && onExpand ? handleClick : undefined}
            className={cn("table-expanded-icon", { spaced: !expandable })}
            style={{ display: depth === 0 && !expandable ? "none" : "block" }}
          >
            {expanded ? (
              <MinusSquareOutlined style={{ color: "#5b5b5b" }} />
            ) : (
              <PlusSquareOutlined style={{ color: "#5b5b5b" }} />
            )}
          </div>
        )
      ) : (
        <div className="table-expanded-loading">
          <LoadingOutlined />
        </div>
      )}
    </>
  );
};

export default ExpandIcon;
