import { FC } from "react";

import {
  prepareGroupsData,
  generateListKeys,
} from "../../../../helpers/utils/reports";
import { groupingsData } from "../../constants/reportsAD";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ModalSettingsAD from "./ModalSettingsAD";

let unsortedGroupings = prepareGroupsData(groupingsData);
// const data = unsortedGroupings.filter((obj:{name?:string}) =>
//     obj?.name !== "Реклама" && obj?.name !== "Вебинары"
// )
export const listKeysGroupings = generateListKeys(groupingsData);
export const ads_keys = [
  "ads_date",
  "ads_date_w",
  "ads_date_dw",
  "ads_date_m",
  "ads_date_q",
  "ads_date_y",
  "ads_source",
  "ads_cabinet",
  "ads_campaign",
  "ads_adset",
  "ads_adv",
  "ads_campaign_id",
  "ads_adset_id",
  "ads_adv_id",
  "ads_utm_source",
  "ads_utm_medium",
  "ads_utm_campaign",
  "ads_utm_content",
  "ads_utm_term",
  "ads_utm_group",
  "ads_tag",
];

const GroupingsAD: FC<any> = ({ isDisable, hidePersonalDataGroups }) => {
  const { groupings } = useTypedSelector((state) => state.table);
  const {
    setGroupings,
    setMetaTableFetching,
    setMetaChartFetching,
    setMetaCompareChartFetching,
    setMetaCompareTableFetching,
  } = useActions();

  const actionSet = (selected: any) => {
    setGroupings({ data: selected });

    setTimeout(() => {
      setMetaTableFetching({ value: true });
      setMetaChartFetching({ value: true });
      setMetaCompareChartFetching({ value: true });
      setMetaCompareTableFetching({ value: true });
    }, 50);
  };

  return (
    <>
      <ModalSettingsAD
        hidePersonalDataGroups={hidePersonalDataGroups}
        title="Группировки"
        dataItems={unsortedGroupings}
        listKeys={listKeysGroupings}
        defSelected={groupings}
        defChecked={groupings.map((item) => item.key)}
        isDisable={isDisable}
        actionSet={actionSet}
      />
    </>
  );
};

export default GroupingsAD;
