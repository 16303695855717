// Группировки в графике
export const groups = [
  {
    groupName: "reg",
    metrics: [
      { name: "regs_count", label: "Регистрации" },
      { name: "users_count", label: "Пользователи" },
    ],
  },
  {
    groupName: "conv",
    metrics: [
      {
        name: "orders_to_forders_converse",
        label: "CR заказа в завершенный заказ",
      },
      {
        name: "creator_to_buyer_converse",
        label: "CR из созд. заказа в покупателя",
      },
    ],
  },
  {
    groupName: "orders",
    metrics: [
      { name: "orders_count", label: "Заказы" },
      { name: "forders_count", label: "Заказы [Завершён]" },
      { name: "payments_count", label: "Платежи" },
      { name: "orders_creators_count", label: "Создатели заказов" },
      { name: "buyers_count", label: "Покупатели" },
    ],
  },
  {
    groupName: "sum",
    metrics: [
      { name: "payments_sum", label: "Сумма платежей" },
      { name: "orders_sum", label: "Сумма заказов" },
      { name: "forders_sum", label: "Сумма заказов [Завершён]" },
    ],
  },
  {
    groupName: "avg",
    metrics: [
      { name: "orders_sum_avg", label: "Ср. сумма заказа" },
      { name: "payments_sum_avg", label: "Средний чек платежа" },
    ],
  },
];

/**
 Для добавления в группировку информационной иконки требуется добавить
 в объект группировки парметр description
 @param {string} `description`
 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Дата", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */

export const groupingsData = [
  {
    name: "Сессии",
    description: "",
    children: [
      {
        name: "Дата сессии",
        description: "",
        children: [
          {
            name: "День сессии",
            description: "",
            dataKey: "session_date_6",
          },
          {
            name: "Неделя сессии",
            description: "",
            dataKey: "session_date_w_6",
          },
          {
            name: "День недели сессии",
            description: "",
            dataKey: "session_date_dw_6",
          },
          {
            name: "Месяц сессии",
            description: "",
            dataKey: "session_date_m_6",
          },
          {
            name: "Квартал сессии",
            description: "",
            dataKey: "session_date_q_6",
          },
          {
            name: "Год сессии",
            description: "",
            dataKey: "session_date_y_6",
          },
        ],
      },
      {
        name: "URL адрес сессии",
        description: "",
        dataKey: "first_hit_url_6",
      },
      {
        name: "UTM-метка сессии",
        description: "",
        children: [
          {
            name: "utm_source сессии",
            description: "",
            dataKey: "session_utm_source_6",
          },
          {
            name: "utm_medium сессии",
            description: "",
            dataKey: "session_utm_medium_6",
          },
          {
            name: "utm_campaign сессии",
            description: "",
            dataKey: "session_utm_campaign_6",
          },
          {
            name: "utm_content сессии",
            description: "",
            dataKey: "session_utm_content_6",
          },
          {
            name: "utm_term сессии",
            description: "",
            dataKey: "session_utm_term_6",
          },
          {
            name: "utm_group сессии",
            description: "",
            dataKey: "session_utm_group_6",
          },
        ],
      },
      {
        name: "URL адрес хита",
        description: "",
        dataKey: "hit_url_6",
      },
      {
        name: "UTM-метка хита",
        description: "",
        children: [
          {
            name: "utm_source хита",
            description: "",
            dataKey: "hit_utm_source_6",
          },
          {
            name: "utm_medium хита",
            description: "",
            dataKey: "hit_utm_medium_6",
          },
          {
            name: "utm_campaign хита",
            description: "",
            dataKey: "hit_utm_campaign_6",
          },
          {
            name: "utm_content хита",
            description: "",
            dataKey: "hit_utm_content_6",
          },
          {
            name: "utm_term хита",
            description: "",
            dataKey: "hit_utm_term_6",
          },
          {
            name: "utm_group хита",
            description: "",
            dataKey: "hit_utm_group_6",
          },
        ],
      },
      {
        name: "Технология сессии",
        description: "",
        children: [
          {
            name: "Тип устройства сессии",
            description: "",
            dataKey: "session_device_type_6",
          },
          {
            name: "Модель устройства сессии",
            description: "",
            dataKey: "session_device_model_6",
          },
          {
            name: "Операционная система сессии",
            description: "",
            dataKey: "session_device_os_6",
          },
          {
            name: "Версия операционной системы сессии",
            description: "",
            dataKey: "session_device_os_ver_6",
          },
          {
            name: "Браузер сессии",
            description: "",
            dataKey: "session_browser_6",
          },
          {
            name: "Версия браузера сессии",
            description: "",
            dataKey: "session_browser_ver_6",
          },
          {
            name: "Язык браузера сессии",
            description: "",
            dataKey: "session_browser_lang_6",
          },
          {
            name: "Геолокация сессии",
            description: "",
            children: [
              {
                name: "Страна сессии",
                description: "",
                dataKey: "session_geo_country_6",
              },
              {
                name: "Регион сессии",
                description: "",
                dataKey: "session_geo_region_6",
              },
              {
                name: "Город сессии",
                description: "",
                dataKey: "session_geo_city_6",
              },
              {
                name: "Часовой пояс сессии",
                description: "",
                dataKey: "session_geo_tz_6",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Регистрации",
    description: "",
    children: [
      {
        name: "UTM-метка регистрации",
        description: "",
        children: [
          {
            name: "utm_source регистрации",
            description: "",
            dataKey: "reg_utm_source_6",
          },
          {
            name: "utm_medium регистрации",
            description: "",
            dataKey: "reg_utm_medium_6",
          },
          {
            name: "utm_campaign регистрации",
            description: "",
            dataKey: "reg_utm_campaign_6",
          },
          {
            name: "utm_content регистрации",
            description: "",
            dataKey: "reg_utm_content_6",
          },
          {
            name: "utm_term регистрации",
            description: "",
            dataKey: "reg_utm_term_6",
          },
          {
            name: "utm_group регистрации",
            description: "",
            dataKey: "reg_utm_group_6",
          },
        ],
      },
      {
        name: "Доп. поля регистрации",
        description: "Группировка доп. полей регистрации",
        children: [],
        checkable: false,
      },
      {
        name: "Номер регистрации",
        description: "",
        dataKey: "reg_number_6",
      },
      {
        name: "Название регистрации",
        description: "",
        dataKey: "reg_title_6",
      },
    ],
  },
  {
    name: "Пользователи",
    description: "",
    children: [
      { name: "Email", dataKey: "user_email_6" },
      { name: "id пользователя", dataKey: "user_id_6" },
      {
        name: "Доп. поля пользователя",
        description: "Группировка доп. полей пользователя",
        children: [],
        checkable: false,
      },
    ],
  },
  {
    name: "Заказы",
    description: "",
    children: [
      {
        name: "Дата заказа",
        description: "",
        children: [
          {
            name: "День заказа",
            description: "",
            dataKey: "order_date_6",
          },
          {
            name: "Неделя заказа",
            description: "",
            dataKey: "order_date_w_6",
          },
          {
            name: "День недели заказа",
            description: "",
            dataKey: "order_date_dw_6",
          },
          {
            name: "Месяц заказа",
            description: "",
            dataKey: "order_date_m_6",
          },
          {
            name: "Квартал заказа",
            description: "",
            dataKey: "order_date_q_6",
          },
          {
            name: "Год заказа",
            description: "",
            dataKey: "order_date_y_6",
          },
        ],
      },
      {
        name: "Доп. поля заказа",
        description: "Группировка доп. полей заказа",
        children: [],
        checkable: false,
      },
      {
        name: "Предложения",
        description: "",
        dataKey: "order_offers_6",
      },
      {
        name: "Номер заказа",
        description: "",
        dataKey: "order_number_6",
      },
      {
        name: "UTM-метка заказа",
        description: "",
        children: [
          {
            name: "utm_source заказа",
            description: "",
            dataKey: "order_utm_source_6",
          },
          {
            name: "utm_medium заказа",
            description: "",
            dataKey: "order_utm_medium_6",
          },
          {
            name: "utm_campaign заказа",
            description: "",
            dataKey: "order_utm_campaign_6",
          },
          {
            name: "utm_content заказа",
            description: "",
            dataKey: "order_utm_content_6",
          },
          {
            name: "utm_term заказа",
            description: "",
            dataKey: "order_utm_term_6",
          },
          {
            name: "utm_group заказа",
            description: "",
            dataKey: "order_utm_group_6",
          },
        ],
      },
    ],
  },
  {
    name: "Платежи",
    description: "",
    children: [{ name: "id платежа", dataKey: "payment_id_6" }],
  },
];

/**
 Для добавления в метрику информационной иконки требуется добавить
 в объект метрики парметр description
 @param {string} `description`

 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Пользователи", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */
export const metrics = [
  {
    name: "Регистрации",
    description: "",
    children: [
      {
        name: "Регистрации",
        description: "Кол-во регистраций за выбранный период.",
        dataKey: "regs_count_6",
      },
    ],
  },
  {
    name: "Посещения",
    description: "",
    children: [
      { name: "Посетители", dataKey: "visitors_count_6" },
      { name: "Количество просмотренных страниц", dataKey: "hits_count_6" },
    ],
  },
  {
    name: "Заказы",
    description: "",
    children: [{ name: "Заказы", dataKey: "orders_count_6" }],
  },
];
