import React, {  useState } from "react"

import ChartUniversal from "./chart/ChartUniversal"
import Loading from "./Loading"
import UniversalTable from "./table/UniversalTable"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import TypeIcon from "../../params/attribution/icons/TypeIcon"
import Params from "../../params/Params"
const CogApp = () => {
    const { filters } = useTypedSelector((state) => state.filter)
    const { attributes } = useTypedSelector((state) => state.attributes)
    const [ loadingChart, setLoadingChart ] = useState(false)
    const [ loadingTable, setLoadingTable ] = useState(false)
    return (
    <div>
      <Loading loading={loadingTable || loadingChart} hasTip={false}>
        <Params
            withCompare={false}
            withAttribution={true}
            detailsItems={[
                { name: "по дням", value: "d" },
                { name: "по неделям", value: "w" },
                { name: "по месяцам", value: "m" },
                { name: "по кварталам", value: "q" },
                { name: "по годам", value: "y" },
            ]}
            filtersFilter={[
                "Пользователи",
                "Регистрации",
                "Заявки",
                "Заказы",
                "Платежи",
                "Вебинары",
                "Реклама",
            ]}
            attributionItems={[
                {
                    id: "type_applications",
                    name: "Тип регистрации:",
                    selectValues: [
                        { name: "Нулевые заказы GC", id: "2", icon: null },
                        { name: "Регистрации GC", id: "0", icon: null },
                        { name: "SCAN", id: "1", icon: null },
                        //{ name: "Ручное добавление", id: "3", icon: null },
                    ],
                    description: `Выбор типа регистрации по которым нужно создать отчёт:
                                <br><br>
                                Типы регистраци:
                                <br><br>
                                - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                                - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                                - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                                <br><br>
                                <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                            `,
                    icon: <TypeIcon />,
                },
                /*{
                            id: "cohort",
                            name: "Когорта:",
                            selectValues: [
                                { name: "По дате регистрации", id: "1", icon: null },
                                { name: "По периоду", id: "2", icon: null },
                            ],
                            description: "",
                            icon: null
                        },*/
            /*{
                            id: "metric",
                            name: "Метрика:",
                            selectValues: [
                                { name: "Сумма платежей", id: "1", icon: null },
                                { name: "Заказы", id: "2", icon: null },
                                { name: "Конверсия в оплату", id: "3", icon: null },
                                { name: "Вебинары", id: "4", icon: null },
                            ],
                            description: "",
                            icon: null
                        },*/
            ]}
        />
      </Loading>

      {/*<ErrorBoundary>*/}
      <ChartUniversal loadingChart={loadingChart || loadingTable} />
      {/*</ErrorBoundary>*/}

      {/*<ErrorBoundary>*/}
      <UniversalTable
          filters={filters}
          filtersReq={attributes}
          loadingChart={loadingChart}
          loadingTable={loadingTable}
          setLoadingTable={setLoadingTable}
      />
      {/*</ErrorBoundary>*/}
    </div>
    )
}

export default CogApp
