import React, { FC, useEffect, useState } from "react";

import ModalSettingsPayment from "./ModalSettingsPayment";
import {
  generateListKeys,
  prepareData,
} from "../../../../helpers/utils/reports";
import { metrics as staticMetrics } from "../../constants/reportsPayment";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import usePermission from "../../hooks/usePermission";
import { listKeysJsonUniversal } from "../../constants/metrics";

/**
 Список ключей метрик, для которых не будет отображаться накопительный график
 */
export const declineCumulativeMetrics = [
  "web_30min_users_part",
  "web_30min_g_users_part",
  "web_180min_g_users_part",
  "web_240min_g_users_part",
  "payments_sum_avg",
  "creator_to_buyer_converse",
  "new_users_part",
  "paid_regs_part",
  "free_regs_part",
  "user_price",
  "web_user_to_no_app_converse",

  "user_to_no_step1_converse",
  "user_to_no_app_converse",
  "no_step1_to_no_step2_converse",
  "no_step2_to_no_step3_converse",
  "no_step3_to_no_step4_converse",
  "no_step4_to_no_step5_converse",
  "no_step5_to_no_app_converse",

  "orders_to_payments_converse",
  "orders_sum_avg",
  "orders_sum_avg_user",
  "user_to_creator_converse",
  "user_to_buyer_converse",
  "orders_to_forders_converse",

  "payments_sum_per_reg",

  "user_to_refunder_converse",
  "buyer_to_refunder_converse",

  "web_1min_users_part",
  "web_5min_users_part",
  "web_15min_users_part",
  "web_60min_users_part",
  "web_60min_g_users_part",
  "web_120min_g_users_part",
  "web_user_to_clicker_converse",
  "clicker_to_creator_converse",
  "user_to_web_user_converse",
  "web_user_to_creator_converse",

  "cpm",
  "cpc",
  "ctr",
  "ads_click_to_reg_converse",
  "profit_per_reg",
  "reg_price",
  "user_price",
  "orders_price",
  "orders_creators_price",
  "forders_price",
  "buyers_price",
  "payments_price",
  "web_user_price",
  "profit_per_user",
  "romi",
  "roas",
  "drr",
].map((el) => el + "_3");

let unsortedMetrics = prepareData(staticMetrics, "", [], false);
const data = unsortedMetrics.filter(
  (obj: { name?: string }) =>
    obj?.name !== "Заявки" &&
    obj?.name !== "Вебинары" &&
    obj?.name !== "Реклама"
);

export const listKeysMetrics = generateListKeys(data);
export const ads_metrics_keys = [
  "ads_clicks_count",
  "ads_spend",
  "profit",
  "profit_per_reg",
  "profit_per_user",
  "romi",
  "roas",
  "drr",
  "payments_price",
  "buyers_price",
  "web_user_price",
  "orders_price",
  "orders_creators_price",
  "reg_price",
  "user_price",
  "ads_impressions",
  "cpm",
  "cpc",
  "ctr",
  "ads_click_to_reg_converse",
];
const Metrics: FC<any> = ({
  isDisable,
  adsGroupsSelected,
  adsGroupsAvailable,
  setAdsGroupsAvailable,
  setAdsGroupsSelected,
}) => {
  const { metrics, createdMetrics } = useTypedSelector((state) => state.table);
  const checkPermission = usePermission();
  const {
    setMetricsChecked,
    setMetrics,
    setMetaTableFetching,
    setMetaChartFetching,
    setMetaCompareChartFetching,
    setMetaCompareTableFetching,
  } = useActions();
  const actionSet = (selected: any) => {
    setMetricsChecked({
      data: [{ name: selected[0].dataKey, label: selected[0].label }],
    });
    setMetrics({ data: selected });
    setTimeout(() => {
      setMetaTableFetching({ value: true });
      setMetaChartFetching({ value: true });
      setMetaCompareChartFetching({ value: true });
      setMetaCompareTableFetching({ value: true });
    }, 50);
  };
  const [data, setData] = useState(
    prepareData(
      [
        ...staticMetrics,
        ...(createdMetrics[0].children.length > 0
          ? structuredClone(createdMetrics)
          : []),
      ],
      "",
      [],
      false
    ).filter((el: any) => {
      return checkPermission(["superadmin"]) ? true : el.name !== "Посещения";
    })
  );
  useEffect(() => {
    setData(
      prepareData(
        [
          ...staticMetrics,
          ...(createdMetrics[0].children.length > 0
            ? structuredClone(createdMetrics)
            : []),
        ],
        "",
        [],
        false
      )
    );
  }, [createdMetrics]);
  const [updatedMetrics, setUpdatedMetrics] = useState<any[]>([]);
  useEffect(() => {
    let updMetrics: any[] = [];
    metrics.forEach((metric: any) => {
      data.forEach((item: any) => {
        item.children.forEach((child: any) => {
          if (metric.dataKey === child.dataKey) {
            const keyValue = child?.dataKey?.startsWith("_")
              ? item?.children.find(
                  (el: { dataKey: string; key: string }) =>
                    el.dataKey === child.dataKey
                )?.key
              : metric.key;
            Object.defineProperty(child, "key", { value: keyValue });
            updMetrics.push(child);
          }
        });
      });
    });
    setUpdatedMetrics(updMetrics);
  }, [metrics]);

  return (
    <>
      <ModalSettingsPayment
        title="Метрики"
        dataItems={data}
        listKeys={generateListKeys(data)}
        defSelected={updatedMetrics}
        defChecked={updatedMetrics.map((item) => item.key)}
        isDisable={isDisable}
        actionSet={actionSet}
        adsGroupsSelected={adsGroupsSelected}
        adsGroupsAvailable={adsGroupsAvailable}
        setAdsGroupsAvailable={setAdsGroupsAvailable}
        setAdsGroupsSelected={setAdsGroupsSelected}
      />
    </>
  );
};

export default Metrics;
