import { FormInstance, message, notification, Modal } from "antd"

export const displayLanguageByKey = (key: string) => {
    switch (key) {
        case "ru":
            return "Русский"

        default:
            return "-"
    }
}
export const measureTextDimensions = (text: string): { width: number, height: number } => {
    const span = document.createElement('span');
    span.style.fontWeight = "800";
    span.style.fontSize = "21px";
    span.style.visibility = 'hidden';
    span.style.whiteSpace = 'nowrap';
    span.style.width = '100%'
    span.textContent = text;
    document.body.appendChild(span);

    const screenWidth = window.innerWidth || document.documentElement.offsetWidth || document.body.offsetWidth;
    const dimensions = {
        width: screenWidth,
        height: span.offsetHeight,
    };

    document.body.removeChild(span);
    return dimensions;
};

export const convertToGridUnits = (widthPx: number, heightPx: number, colWidth: number, rowHeight: number): { w: number, h: number } => {
    const w = Math.ceil(widthPx / colWidth);
    const h = Math.ceil(heightPx / rowHeight);
    return { w, h };
};
export const calculateWidth = (numMetrics: number, maxCols: number): number => {
    const width = 260 + (120 * numMetrics);
    const cols = Math.ceil(width / 120) + 1
    return Math.min(cols, maxCols);
};

export const showErrorMessage = (text: string) => {
    const modal = Modal.error({
        title: "Ошибка!",
        content: text,
    })

    setTimeout(() => {
        modal.destroy()
    }, 5000)
}

export const showSuccessMessage = (text: string) => {
    message.open({
        content: text,
        type: "success",
    // style: { marginTop: 50 }
    })
}

export const showErrorNotification = (text: string, duration = 5) => {
    notification.open({
        type: "error",
        message: "Ошибка!",
        description: text,
        duration: duration,
    })
}

export const showSuccessNotification = (text: string, duration = 5) => {
    notification.open({
        type: "success",
        message: "Успех!",
        description: text,
        duration: duration,
    })
}
export const showInfoModal = (text:string) => {
    const modal = Modal.info({
        title: "Внимание!",
        content: text,
        centered:true
    })

    setTimeout(() => {
        modal.destroy()
    }, 5000)
}
export const showInfoNotification = (text: string, duration = 5) => {
    notification.open({
        type: "info",
        message: "Внимание!",
        description: text,
        duration: duration,
    })
}

export const showFormErrors = (
    form: FormInstance,
    errors: { [key: string]: string }
) => {
    Object.entries(errors).forEach(([ key, val ]) => {
        form.setFields([
            {
                name: key,
                errors: [ val ],
            },
        ])
    })
}
