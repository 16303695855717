import { unApi } from "../api/endpoints/reports/unApi";
import {
  convertToTreeFormat,
  updateChildren,
} from "../../../helpers/utils/functions";
import { showErrorMessage } from "../../../helpers/utils/ui";
import React from "react";

export const useLoadGroupingsData = (
  setData: React.Dispatch<React.SetStateAction<any>>,
  convertFn: any = convertToTreeFormat,
  setUpdate?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const loadAdditionalFields = async (key: string, parameter: number) => {
    try {
      const { data } = await unApi.getExtraFields(parameter);

      const fields = convertFn(data);

      setData((prev: any) => {
        return prev.map((el: any) => updateChildren(el, key, fields));
      });
      setUpdate && setUpdate((prev) => !prev);
    } catch (err) {
      showErrorMessage("Произошла ошибка во время получения доп полей.");
      console.log(err);
    }
  };

  const onLoadData = (data: any) =>
    new Promise<void>((resolve) => {
      const { name, children, key } = data;
      if (children?.length > 0) {
        resolve();
        return;
      }
      const params = {
        users: 1,
        regs: 2,
        orders: 3,
          app:2
      };

      const loadData = async () => {
        if (name === "Доп. поля регистрации") {
          await loadAdditionalFields(key, params.regs);
        } else if (name === "Доп. поля пользователя") {
          await loadAdditionalFields(key, params.users);
        } else if (name === "Доп. поля заказа") {
          await loadAdditionalFields(key, params.orders);
        }else if (name === "Доп. поля заявки") {
            await loadAdditionalFields(key, params.app);
        }
        resolve();
      };

      loadData().catch(() => {
        resolve();
      });
    });
  return {
    onLoadData,
  };
};
