// Группировки в графике
export const groups = [
  {
    groupName: "reg",
    metrics: [
      { name: "regs_count_2", label: "Регистрации" },
      { name: "users_count_2", label: "Пользователи" },
    ],
  },
  {
    groupName: "conv",
    metrics: [
      {
        name: "orders_to_forders_converse_2",
        label: "CR заказа в завершенный заказ",
      },
      {
        name: "creator_to_buyer_converse_2",
        label: "CR из созд. заказа в покупателя",
      },
    ],
  },
  {
    groupName: "orders",
    metrics: [
      { name: "orders_count_2", label: "Заказы" },
      { name: "forders_count_2", label: "Заказы [Завершён]" },
      { name: "payments_count_2", label: "Платежи" },
      { name: "orders_creators_count_2", label: "Создатели заказов" },
      { name: "buyers_count_2", label: "Покупатели" },
    ],
  },
  {
    groupName: "sum",
    metrics: [
      { name: "payments_sum_2", label: "Сумма платежей" },
      { name: "orders_sum_2", label: "Сумма заказов" },
      { name: "forders_sum_2", label: "Сумма заказов [Завершён]" },
    ],
  },
  {
    groupName: "avg",
    metrics: [
      { name: "orders_sum_avg_2", label: "Ср. сумма заказа" },
      { name: "payments_sum_avg_2", label: "Средний чек платежа" },
    ],
  },
];

/**
 Для добавления в группировку информационной иконки требуется добавить
 в объект группировки парметр description
 @param {string} `description`
 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Дата", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */

export const groupingsData = [
  {
    name: "Регистрации",
    description:
      "Группировка заказов по дате регистрации, совершенных до создания заказов.",
    children: [
      {
        name: "Дата регистрации",
        children: [
          {
            name: "Дни регистрации",
            description:
              "Группировка заказов по дню регистраций, совершенных до создания заказов.",
            dataKey: "reg_date_2",
          },
          {
            name: "Недели регистрации",
            description:
              "Группировка заказов по неделям регистраций, совершенных до создания заказов, по 7 дней.",
            dataKey: "reg_date_w_2",
          },
          {
            name: "Дни недели регистрации",
            description:
              "Группировка заказов по дням недели регистраций, совершенных до создания заказов (понедельник, вторник, среда, четверг, пятница, суббота, воскресенье).",
            dataKey: "reg_date_dw_2",
          },
          {
            name: "Месяцы регистрации",
            description:
              "Группировка заказов по месяцам регистрации, совершенных до создания заказов.",
            dataKey: "reg_date_m_2",
          },
          {
            name: "Кварталы регистрации",
            description:
              "Группировка заказов по кварталам регистраций, совершенных до создания заказов.",
            dataKey: "reg_date_q_2",
          },
          {
            name: "Года регистрации",
            description:
              "Группировка заказов по годам регистраций, совершенных до создания заказов.",
            dataKey: "reg_date_y_2",
          },
        ],
      },
      {
        name: "UTM-метка регистрации",
        description:
          "Группировка заказов по UTM-метке регистраций, совершённых до создания заказов.",
        children: [
          { name: "utm_source регистрации", dataKey: "reg_utm_source_2" },
          { name: "utm_medium регистрации", dataKey: "reg_utm_medium_2" },
          { name: "utm_campaign регистрации", dataKey: "reg_utm_campaign_2" },
          { name: "utm_content регистрации", dataKey: "reg_utm_content_2" },
          { name: "utm_term регистрации", dataKey: "reg_utm_term_2" },
          { name: "utm_group регистрации", dataKey: "reg_utm_group_2" },
        ],
      },
      {
        name: "Доп. поля регистрации",
        description: "Группировка доп. полей регистрации",
        children: [],
        checkable: false,
      },
      {
        name: "Номер регистрации",
        description: "Список номеров регистраций",
        dataKey: "reg_number_2",
      },
      {
        name: "Название регистрации",
        description:
          "Группировка заказов по названию регистраций. <br/>" +
          "Если выбран тип регистрации: SCAN, то название регистрации - название страницы, на которой была заполнена форма. <br/>" +
          "Если выбран тип регистрации: Нулевые заказы GС, то названием регистрации будет являться название предложения в нулевом заказе GetCourse.",
        dataKey: "reg_title_2",
      },
    ],
  },
  {
    name: "Пользователи",
    children: [
      {
        name: "E-mail",
        description: "Вывод Email пользователей, у кого есть заказ",
        dataKey: "user_email_2",
      },
      {
        name: "id пользователя",
        description: "Вывод id пользователей, у кого есть заказ",
        dataKey: "user_id_2",
      },
      {
        name: "id - Email - Имя - Телефон",
        description:
          "Вывод id - Email - Имя - Телефон пользователей, у кого есть заказ",
        dataKey: "user_id_ext_2",
      },
      {
        name: "Доп. поля пользователя",
        description: "Группировка доп. полей пользователя",
        children: [],
        checkable: false,
      },
    ],
  },
  {
    name: "Заказы",
    description: "",
    children: [
      {
        name: "Дата заказа",
        description: "",
        children: [
          {
            name: "Дни заказов",
            description: "Группировка заказов по дням создания заказов.",
            dataKey: "order_date_2",
          },
          {
            name: "Недели заказов",
            description: "Группировка заказов по неделям создания заказов.",
            dataKey: "order_date_w_2",
          },
          {
            name: "Дни недели заказов",
            description: "Группировка заказов по дням недели создания заказов.",
            dataKey: "order_date_dw_2",
          },
          {
            name: "Месяца заказов",
            description: "Группировка заказов по месяцам создания заказов.",
            dataKey: "order_date_m_2",
          },
          {
            name: "Кварталы заказов",
            description: "Группировка заказов по кварталам создания заказов.",
            dataKey: "order_date_q_2",
          },
          {
            name: "Года заказов",
            description: "Группировка заказов по годам создания заказов.",
            dataKey: "order_date_y_2",
          },
        ],
      },
      {
        name: "Период от регистрации до создания заказа",
        description:
          "Группировка заказов пользователей по периоду от регистрации до создания заказа. Сможете увидеть через сколько дней или недель после регистрации пользователь создаёт заказ",
        children: [
          {
            name: "Дней от регистрации до заказа",
            description:
              "Группировка заказов по количеству дней прошедших от регистрации до создания заказа. Узнаете на какой день после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней<br>" +
              "<br>" +
              "В период 1 день попадут все заказы, у которых дата заказа = дате регистрации. <br>" +
              "<br>" +
              "В период 2 день попадут заказы, созданные на следующий день после регистрации.<br>",
            dataKey: "order_after_reg_d_2",
          },
          {
            name: "Недель от регистрации до заказа",
            description:
              "Группировка заказов по количеству недель прошедших от регистрации до создания заказа. Узнаете в какую неделю после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель<br>" +
              "<br>" +
              "В период 1 неделя попадут все заказы, созданные в течение 7 дней после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 по 7 марта. <br>" +
              "<br>" +
              "В период 2 неделя попадут все заказы, созданные в период с 8 по 14 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 8 по 14 марта.<br>",
            dataKey: "order_after_reg_w_2",
          },
          {
            name: "Месяцев от регистрации до заказа",
            description:
              "Группировка заказов по количеству месяцев прошедших от регистрации до создания заказа. Узнаете в какой месяц после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев<br>" +
              "<br>" +
              "В период 1 месяц попадут все заказы, созданные в течение 30 дней после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 по 30 марта. <br>" +
              "<br>" +
              "В период 2 месяца попадут все заказы, созданные в период с 31 по 60 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 31 марта по 29 апреля.<br>" +
              "<br>" +
              "Условно 1 месяц = 30 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 30 = число округляем до большего значения.",
            dataKey: "order_after_reg_m_2",
          },
          {
            name: "Кварталов от регистрации до заказа",
            description:
              "Группировка заказов по количеству кварталов прошедших от регистрации до создания заказа. Узнаете в какой квартал после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов<br>" +
              "<br>" +
              "В период 1 квартал попадут все заказы, созданные в течение 91 дня после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 марта по 30 мая. <br>" +
              "<br>" +
              "В период 2 квартал попадут все заказы, созданные в период с 92 по 182 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 31 мая по 29 августа.<br>" +
              "<br>" +
              "Условно 1 квартал = 91 день.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 91 = число округляем до большего значения.",
            dataKey: "order_after_reg_q_2",
          },
          {
            name: "Лет от регистрации до заказа",
            description:
              "Группировка заказов по количеству лет прошедших от регистрации до создания заказа. Узнаете в какой год после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет<br>" +
              "<br>" +
              "В период 1 год попадут все заказы, созданные в течение 365 дней после регистрации. <br>" +
              "<br>" +
              "В период 2 год попадут все заказы, созданные в период с 366 по 730 день после регистрации.<br>" +
              "<br>" +
              "Условно 1 год = 365 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 365 = число округляем до большего значения.",
            dataKey: "order_after_reg_y_2",
          },
        ],
      },
      {
        name: "Период от создания заказа до его оплаты",
        description:
          "Группировка завершенных заказов пользователей по периоду от создания заказа до его полной оплаты. Сможете увидеть за какой период времени после создания заказа, заказ полностью оплачивается. <br>" +
          "<br>" +
          "Формула расчёта: Дата и время последнего платежа заказа со статусом завершён - Дата и время создания заказа.",
        children: [
          {
            name: "Минут от создания до оплаты заказа",
            description:
              "Группировка заказов по минутным периодом от создания заказа до его полной оплаты. Узнаете сколько проходит минут от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 мин.<br>" +
              "2 мин.<br>" +
              "3 мин.<br>" +
              "4 мин.<br>" +
              "5 мин.<br>" +
              "6 мин.<br>" +
              "7 мин.<br>" +
              "8 мин.<br>" +
              "9 мин.<br>" +
              "10 мин.<br>" +
              "11 мин.<br>" +
              "12 мин.<br>" +
              "13 мин.<br>" +
              "14 мин.<br>" +
              "15 мин.<br>" +
              "> 15 мин.",
            dataKey: "pay_after_order_m_2",
          },
          {
            name: "Декаминут от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в декаминутах (по десять минут) от создания заказа до его полной оплаты. Узнаете сколько проходит декаминут от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "10 мин.<br>" +
              "20 мин.<br>" +
              "30 мин.<br>" +
              "40 мин.<br>" +
              "50 мин.<br>" +
              "60 мин.<br>" +
              "> 1 час.",
            dataKey: "pay_after_order_dm_2",
          },
          {
            name: "Часов от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в часах от создания заказа до его полной оплаты. Узнаете сколько проходит часов от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 ч.<br>" +
              "2 ч.<br>" +
              "3 ч.<br>" +
              "4 ч.<br>" +
              "5 ч.<br>" +
              "6 ч.<br>" +
              "7 ч.<br>" +
              "8 ч.<br>" +
              "9 ч.<br>" +
              "10 ч.<br>" +
              "11 ч.<br>" +
              "12 ч.<br>" +
              "13 ч.<br>" +
              "14 ч.<br>" +
              "15 ч.<br>" +
              "16 ч.<br>" +
              "17 ч.<br>" +
              "18 ч.<br>" +
              "19 ч.<br>" +
              "20 ч.<br>" +
              "21 ч.<br>" +
              "22 ч.<br>" +
              "23 ч.<br>" +
              "24 ч.<br>" +
              "> 24 ч.",
            dataKey: "pay_after_order_h_2",
          },
          {
            name: "Дней от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в днях от создания заказа до его полной оплаты. Узнаете сколько проходит дней от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней.",
            dataKey: "pay_after_order_d_2",
          },
          {
            name: "Недель от создания заказа до оплаты",
            description:
              "Группировка заказов по недельным периодам от создания заказа до его полной оплаты. Узнаете сколько проходит недель от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель.",
            dataKey: "pay_after_order_w_2",
          },
        ],
      },
      {
        name: "Предложения",
        description: "Группировка заказов по предложениям.",
        dataKey: "order_offers_2",
      },
      {
        name: "Номер заказа",
        description: "Вывод номеров заказов.",
        dataKey: "order_number_2",
      },
      {
        name: "Тег предложения",
        description: "Группировка заказов по тегам предложения",
        dataKey: "order_tag_2",
      },
      {
        name: "Тег заказа",
        description:
          "Группировка заказов по тегам заказов, которые пользователи создали после регистрации.<br>" +
          "<br>" +
          "Теги заказов получаем автоматически из выгрузки заказов.<br>" +
          "<br>" +
          "Эта группировка позволяет увидеть заказы каждого тега заказа. <br>" +
          "<br>" +
          "Заказы, у которых нет тега заказа, будут сгруппированы в группу (Без тега).<br>",
        dataKey: "order_tag_3_2",
      },
      {
        name: "Менеджер заказа",
        description: "Группировка заказов по менеджерам заказов",
        dataKey: "order_manager_2",
      },
      {
        name: "Статус заказа",
        description: "Группировка заказов по статусам заказов.",
        dataKey: "order_status_2",
      },
      {
        name: "UTM-метка заказа",
        description: "Группировка заказов по UTM - меткам заказов.",
        children: [
          { name: "utm_source заказа", dataKey: "order_utm_source_2" },
          { name: "utm_medium заказа", dataKey: "order_utm_medium_2" },
          { name: "utm_campaign заказа", dataKey: "order_utm_campaign_2" },
          { name: "utm_content заказа", dataKey: "order_utm_content_2" },
          { name: "utm_term заказа", dataKey: "order_utm_term_2" },
          { name: "utm_group заказа", dataKey: "order_utm_group_2" },
        ],
      },
      {
        name: "Партнер заказа",
        description: "",
        children: [
          {
            name: "Есть партнёр",
            dataKey: "order_has_partner_2",
            description: "Группировка заказов по наличию партнёра у заказа.",
          },
          {
            name: "id партнера заказа",
            dataKey: "order_partner_id_2",
            description: "Группировка заказов по id партнёра.",
          },
          {
            name: "Email партнера заказа",
            dataKey: "order_partner_email_2",
            description: "Группировка заказов по email партнёра",
          },
          {
            name: "Имя партнера заказа",
            dataKey: "order_partner_name_2",
            description: "Группировка заказов по имени партнёра",
          },
        ],
      },
      {
        name: "Доп. поля заказа",
        description: "Группировка доп. полей заказа",
        children: [],
        checkable: false,
      },
    ],
  },
  {
    name: "Платежи",
    description: "",
    children: [
      {
        name: "Есть платеж",
        description:
          "Группировка заказов на 2 группы: Есть платёж и Нет платежа. В группу Есть платёж попадут заказы, у которых есть платёж. В группу Нет платежа, соответственно, попадут заказы у которых нет платежа.",
        dataKey: "has_payments_2",
      },

      {
        name: "Дата платежа",
        description: "Группировка заказов по дате получения платежа.",
        children: [
          {
            name: "День платежа",
            description: "Группировка заказов по дням получения платежей.",
            dataKey: "payment_date_2",
          },
          {
            name: "Неделя платежа",
            description: "Группировка заказов по неделям получения платежей.",
            dataKey: "payment_date_w_2",
          },
          {
            name: "День недели платежа",
            description:
              "Группировка заказов по дням недели получения платежей.",
            dataKey: "payment_date_dw_2",
          },
          {
            name: "Месяц платежа",
            description: "Группировка заказов по месяцам получения платежей.",
            dataKey: "payment_date_m_2",
          },
          {
            name: "Квартал платежа",
            description: "Группировка заказов по кварталам получения платежей.",
            dataKey: "payment_date_q_2",
          },
          {
            name: "Год платежа",
            description: "Группировка заказов по годам получения платежей.",
            dataKey: "payment_date_y_2",
          },
        ],
      },
      {
        name: "id платежа",
        description: "Вывод ID платежей.",
        dataKey: "payment_id_2",
      },
      {
        name: "Период от регистрации до платежа",
        description:
          "Группировка платежей пользователей по периоду от регистрации до платежа. Сможете увидеть в какой день или неделю после регистрации происходит больше всего оплат",
        children: [
          {
            name: "Дней от регистрации до платежа",
            description:
              "Группировка платежей по количеству дней прошедших от регистрации до совершения платежа. Узнаете на какой день после регистрации больше всего совершается оплат.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней<br>" +
              "<br>" +
              "В период 1 день попадут все платежи, у которых дата платежа = дате регистрации.<br>" +
              "<br>" +
              "В период 2 день попадут платежи, совершённые на следующий день после регистрации.<br>",
            dataKey: "pay_after_reg_d_2",
          },
          {
            name: "Недель от регистрации до платежа",
            description:
              "Группировка платежей по количеству недель прошедших от регистрации до совершения платежа. Узнаете в какую неделю после регистрации больше всего совершается оплат.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель<br>" +
              "<br>" +
              "В период 1 неделя попадут все платежи, совершенные в течение 7 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 7 марта.<br>" +
              "<br>" +
              "В период 2 неделя попадут все платежи, совершенные в период с 8 по 14 день после регистрации. Если регистрация 1 марта, то попадут платежи созданные с 8 по 14 марта.<br>",
            dataKey: "pay_after_reg_w_2",
          },
          {
            name: "Месяцев от регистрации до платежа",
            description:
              "Группировка платежи по количеству месяцев прошедших от регистрации до совершения платежа. Узнаете в какой месяц после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев<br>" +
              "<br>" +
              "В период 1 месяц попадут все платежи, совершенные в течение 30 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 30 марта.<br>" +
              "<br>" +
              "В период 2 месяца попадут все платежи, совершенные в период с 31 по 60 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 марта по 29 апреля.<br>" +
              "<br>" +
              "Условно 1 месяц = 30 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 30 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_m_2",
          },
          {
            name: "Кварталов от регистрации до платежа",
            description:
              "Группировка платежей по количеству кварталов прошедших от регистрации до совершения платежа. Узнаете в какой квартал после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов<br>" +
              "<br>" +
              "В период 1 квартал попадут все платежи, совершенные в течение 91 дня после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 марта по 30 мая.<br>" +
              "<br>" +
              "В период 2 квартал попадут все платежи, совершенные в период с 92 по 182 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 мая по 29 августа.<br>" +
              "<br>" +
              "Условно 1 квартал = 91 день.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 91 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_q_2",
          },
          {
            name: "Лет от регистрации до платежа",
            description:
              "Группировка платежей по количеству лет прошедших от регистрации до совершения платежа. Узнаете в какой год после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет<br>" +
              "<br>" +
              "В период 1 год попадут все платежи, совершенные в течение 365 дней после регистрации.<br>" +
              "<br>" +
              "В период 2 год попадут все платежи, совершенные в период с 366 по 730 день после регистрации.<br>" +
              "<br>" +
              "Условно 1 год = 365 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 365 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_y_2",
          },
        ],
      },
      {
        name: "Тип платежа",
        description: "Группировка платежей по типу платежной системы.",
        dataKey: "payment_type_2",
      },
      {
        name: "Статус платежа",
        description: "Группировка данных по статусу платежа.",
        dataKey: "payment_status_2",
      },
    ],
  },
];

/**
 Для добавления в метрику информационной иконки требуется добавить
 в объект метрики парметр description
 @param {string} `description`

 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Пользователи", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */
export const metrics = [
  {
    name: "Заказы",
    children: [
      {
        name: "Заказы",
        description:
          'Общее количество всех созданных заказов в отчётный период, кроме заказов со статусом “ЛОЖНЫЙ".',
        dataKey: "orders_count_2",
      },
      {
        name: "Сумма заказов",
        description:
          "Сумма всех созданных заказов в отчётный период, кроме заказов со статусом “ЛОЖНЫЙ”.",
        dataKey: "orders_sum_2",
      },
      {
        name: "Создатели заказов",
        description:
          'Количество людей у кого есть созданные заказы в отчётный период, кроме заказов со статусом “ЛОЖНЫЙ"',
        dataKey: "orders_creators_count_2",
      },
      {
        name: "Ср. сумма заказа",
        description:
          'Средняя сумма созданных заказов в отчётный период, кроме заказов со статусом “ЛОЖНЫЙ"' +
          "<br/> <br/>" +
          "<b>Формула расчёта:</b>" +
          "<br/>Сумма заказов / Заказы",
        dataKey: "orders_sum_avg_2",
      },
      {
        name: "Заказы [Завершён]",
        description:
          'Общее количество заказов созданных в отчётный период со статусом “Завершен"',
        dataKey: "forders_count_2",
      },
      {
        name: "CR заказа в завершенный заказ",
        description:
          "Конверсия в полную оплату заказов, созданных в отчётный период" +
          "<br/> <br/> <b>Формула расчёта:</b>" +
          "<br/>Заказы [Завершён] / Заказы * 100",
        dataKey: "orders_to_forders_converse_2",
      },
      {
        name: "Сумма заказов [Завершён]",
        description:
          "Сумма заказов созданных в отчётный период со статусом “Завершен”.",
        dataKey: "forders_sum_2",
      },
      {
        name: "Заказы [Частично оплачен]",
        description:
          "Общее количество всех заказов со статусом: Частично оплачен”",
        dataKey: "porders_count_2",
      },
      {
        name: "Сумма заказов [Частично оплачен]",
        description:
          "Сумма заказов со статусом “Частично оплачен”, созданных после регистрации по рекламе.",
        dataKey: "porders_sum_2",
      },
      {
        name: "Заказы [Ожидаем оплаты]",
        description:
          "Общее количество всех заказов со статусом “Ожидаем оплаты”, созданных в день участия на вебинаре или после него.",
        dataKey: "worders_count_2",
      },
      {
        name: "Сумма заказов [Ожидаем оплаты]",
        description:
          "Сумма заказов со статусом “Ожидаем оплаты”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "worders_sum_2",
      },
      {
        name: "Актуальные заказы",
        description:
          "Общее количество всех заказов со статусами: Завершён, Частично оплачен и Ожидаем оплаты. созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций",
        dataKey: "aorders_count_2",
      },
      {
        name: "Сумма актуальных заказов",
        description:
          "Сумма заказов со статусами:  Завершён, Частично оплачен и Ожидаем оплаты. Созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "aorders_sum_2",
      },
    ],
  },
  {
    name: "Платежи",
    children: [
      {
        name: "Платежи",
        description:
          "Количество платежей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) по заказам созданным в отчётный период",
        dataKey: "payments_count_2",
      },
      {
        name: "Сумма платежей",
        description:
          "Сумма платежей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) по заказам созданным в отчётный период" +
          "<br/> </br> <b>Формула расчёта:</b>" +
          "</br>Сумма платежей с комиссией платёжных систем - Комиссия платёжных систем",
        dataKey: "payments_sum_2",
      },
      {
        name: "Сумма платежей с комиссией платёжных систем",
        description:
          "Сумма платежей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) БЕЗ вычета комиссии платёжных систем по заказам созданным в отчётный период",
        dataKey: "payments_sum_with_commission_2",
      },
      {
        name: "Комиссия платёжных систем",
        description:
          "Сумма комиссий платёжных систем у платежей по заказам созданным в отчётный период",
        dataKey: "payments_commission_sum_2",
      },
      {
        name: "Покупатели",
        description:
          "Количество людей у кого есть хоть один платёж по заказу созданным в отчётный период",
        dataKey: "buyers_count_2",
      },
      {
        name: "Средний чек платежа",
        description:
          "Средняя сумма платежей по заказам созданным в отчётный период." +
          "</br> </br> Формула расчёта:" +
          "</br><b>Сумма платежей / платежи</b>",
        dataKey: "payments_sum_avg_2",
      },
      {
        name: "CR из созд. заказа в покупателя",
        description:
          "Конверсия из создателя заказа в покупателя по заказам созданным в отчётный период" +
          "</br> </br> <b>Формула расчёта:</b>" +
          "<br/>Создатели заказов / Покупатели * 100",
        dataKey: "creator_to_buyer_converse_2",
      },
      {
        name: "CR из суммы заказов в сумму платежей",
        description:
          "Конверсия из суммы заказов в сумму платежей по заказам созданным в отчётный период" +
          "</br> </br> <b>Формула расчёта:</b>" +
          "<br/>Сумма заказов / Сумма платежей * 100",
        dataKey: "orders_to_payments_converse_2",
      },
      {
        name: "Ожидаемая сумма платежей",
        description:
          "Сумма оставшихся платежей по заказам со статусами: Частично оплачен и Ожидаем оплаты.\n" +
          "\n" +
          "<b>Формула:</b> Сумма заказов со статусом Ожидаем оплаты + сумма заказов со статусом частично оплачен - Сумма платежей с комиссией платёжных систем по частично оплаченным заказам",
        dataKey: "payments_sum_partial_remain_2",
      },
      {
        name: "Возвраты",
        description:
          "Количество платежей со статусами (ВОВЗРАЩЕН) по заказам созданным в отчётный период ",
        dataKey: "refunds_count_2",
      },
      {
        name: "Сумма возвратов",
        description:
          "Сумма платежей со статусами (ВОЗВРАЩЕН) по заказам созданным в отчётный период",
        dataKey: "refunds_sum_2",
      },
      {
        name: "Оформившие возврат",
        description:
          "Кол-во людей, у кого есть платёж со статусом ВОЗВРАЩЕН по заказам созданным в отчётный период ",
        dataKey: "buyers_with_refund_count_2",
      },
      {
        name: "CR из покупателя в оформившего возврат",
        description:
          "Конверсия из людей кто сделал платёж в оформивших возврат по заказам созданным в отчётный период" +
          "</br> </br> <b>Формула расчёта:</b>" +
          "<br/>Оформившие возврат / Покупатели * 100",
        dataKey: "buyer_to_refunder_converse_2",
      },
    ],
  },
];
