import React, { FC, useEffect, useMemo, useState } from "react";

import {
  DownOutlined,
  CloseOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Tree, Input, Modal, Row, Col, Button, Tooltip, Space } from "antd";
import { ReactSortable } from "react-sortablejs";

import CreateMetricsModal from "./CreateMetricsModal";
import EditMetricsModal from "./EditMetricsModal";
import { ads_keys } from "./GroupingsOrder";
import MetricItem from "./MetricItem";
import { ads_metrics_keys } from "./MetricsOrder";
import {
  findObjectById,
  getLineHeightForModalSettings,
} from "../../../../helpers/utils/functions";
import {
  filterGroupsDisabledData,
  getChildrenKey,
  loopSearch,
  getMetricsParentKey,
} from "../../../../helpers/utils/reports";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../helpers/utils/ui";
import { unApi } from "../../api/endpoints/reports/unApi";
import { useActions } from "../../hooks/useActions";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CreateFromExistingMetricModal from "./CreateFromExistingMetricModal";
import { useLoadGroupingsData } from "../../hooks/useLoadGroupingsData";

function ClearOutlined() {
  return null;
}

const ModalSettingsOrder: FC<any> = ({
  title,
  dataItems,
  listKeys,
  defSelected,
  defChecked,
  actionSet,
  isDisable,
  hidePersonalDataGroups = false,
}) => {
  const forceUpdate = useForceUpdate();

  const { isCreateMetricsVisible } = useTypedSelector((state) => state.reports);

  const [creatingMetricState, setCreatingMetricState] = useState({
    visible: false,
    id: null,
  });

  const [editMetricsState, setEditMetricsState] = useState<{
    visible: boolean;
    id: null | number;
    type: string | null;
  }>({
    visible: false,
    id: null,
    type: null,
  });

  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const { groupings, metrics, metrics_filters_update, createdMetrics } =
    useTypedSelector((state) => state.table);
  const {
    setMetricsFiltersUpdate,
    setIsNotParamsFilterVisible,
    getCreatedMetrics,
    setIsCreateMetricsVisible,
  } = useActions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState(dataItems);
  const [selected, setSelected] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const userRole = useTypedSelector((state) => state.currentUser.role);
  const { onLoadData } = useLoadGroupingsData(setData);
  useEffect(() => {
    setIsNotParamsFilterVisible(isCreateMetricsVisible);
  }, [isCreateMetricsVisible]);

  useEffect(() => {
    setIsNotParamsFilterVisible(editMetricsState.visible);
  }, [editMetricsState.visible]);

  useEffect(() => {
    setTimeout(() => {
      if (metrics_filters_update) {
        if (title === "Группировки") {
          let _gr = groupings.map((item) => {
            let _key = getChildrenKey(item.dataKey, data);

            if (_key) {
              return {
                ...item,
                key: _key,
              };
            } else {
              return item;
            }
          });

          setSelected(_gr);
          setCheckedKeys(_gr.map((item) => item.key));
        } else if (title === "Метрики") {
          let _mr = metrics.map((item) => {
            let _key = getChildrenKey(item.dataKey, data);

            if (_key) {
              return {
                ...item,
                key: _key,
              };
            } else {
              return item;
            }
          });

          setSelected(_mr);
          setCheckedKeys(_mr.map((item) => item.key));
        }

        setMetricsFiltersUpdate(false);
      }
    }, 100);
  }, [metrics_filters_update]);

  useEffect(() => {
    if (title === "Группировки") {
      if (selected.length > 0) {
        const _keys = title === "Группировки" ? ads_keys : ads_metrics_keys;
        selected.forEach((item: any, index: number) => {
          if (_keys.includes(item.dataKey)) {
            return;
          }
        });
      } else {
        let _data = data;
        setData(_data);
      }

      forceUpdate();
    }
  }, [selected]);

  useEffect(() => {
    if (title === "Метрики") {
      let _data = data;
      setData(_data);
      forceUpdate();
    }
  }, []);

  useEffect(() => {
    if (title === "Группировки") {
      let _data = data;
      setData(_data);
      forceUpdate();
    }
  }, []);

  useEffect(() => {
    if (hidePersonalDataGroups) {
      setData(
        filterGroupsDisabledData(title === "Метрики" ? dataItems : data, [
          "user_id_2",
          "user_email_2",
          "user_id_ext_2",
        ])
      );
    } else {
      setData(
        filterGroupsDisabledData(title === "Метрики" ? dataItems : data, [""])
      );
    }
  }, [hidePersonalDataGroups, data, dataItems]);

  if (userRole === "user") {
    hidePersonalDataGroups = true;
  }
  useEffect(() => {
    setSelected(defSelected);
  }, [defSelected]);

  useEffect(() => {
    setCheckedKeys(defChecked);
  }, [defChecked]);
  useEffect(() => {
    if (title === "Метрики") {
      checkedKeys.length !== selected.length &&
        setCheckedKeys(() => selected.map((el: { key: string }) => el.key));
    }
  }, [checkedKeys]);
  const removeSelect = (key: string) => {
    setSelected((prev: any) => {
      return [...prev].filter((item: any) => item.key !== key);
    });

    setCheckedKeys((prev: any) => {
      return [...prev].filter((item) => item !== key);
    });
  };

  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (_checkedKeys: any, e: any) => {
    if (
      e.nativeEvent.srcElement.nodeName === "svg" ||
      e.nativeEvent.srcElement.innerText === "Да" ||
      e.nativeEvent.srcElement.innerText === "Нет" ||
      e.nativeEvent?.srcElement?.className === "settings" ||
      e.nativeEvent.srcElement.nodeName === "path"
    ) {
      return;
    }
    setCheckedKeys(_checkedKeys);
    setSelected((prev: any) => {
      let newData: any = [...prev];
      if (e.checked) {
        newData.push({ ...e.node });
      } else {
        newData = newData.filter((item: any) => item.key !== e.node.key);
      }
      return newData;
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value !== "") {
      const reg = new RegExp(value, "i");

      let _expandedKeys: any = listKeys
        .map((item: any) => {
          if (item.name.search(reg) > -1) {
            return getMetricsParentKey(item.key, dataItems);
          }
          return null;
        })
        .filter(
          (item: any, i: number, self: any) => item && self.indexOf(item) === i
        );

      setExpandedKeys(_expandedKeys);
      setSearchValue(value);
      setAutoExpandParent(true);
    } else {
      setExpandedKeys([]);
      setSearchValue("");
      setAutoExpandParent(false);
    }
  };

  const handleOk = () => {
    actionSet(selected);
    setIsModalVisible(false);
    setExpandedKeys([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const clearCheckboxes = () => {
    setSelected([]);
    setCheckedKeys([]);
    forceUpdate();
  };
  const confirmCreatedMetricsRemoving = async (nodeData: any) => {
    const newCreatedMetrics = structuredClone(createdMetrics);
    newCreatedMetrics[0].children = newCreatedMetrics[0].children.filter(
      (el: any) => el.name !== nodeData.name
    );
    try {
      await unApi.removeCustomMetric(nodeData.id, location.pathname);
      const removeMetric = (prev: any) => {
        const updatedData = [...prev];
        const newCreatedMetrics = updatedData[updatedData.length - 1].children;
        updatedData[updatedData.length - 1].children = newCreatedMetrics.filter(
          (el: any) => el.dataKey !== nodeData.dataKey
        );
        return updatedData;
      };
      setData(removeMetric);
      setSelected((prev: any) => {
        return prev.filter((el: any) => el.dataKey !== nodeData.dataKey);
      });
      if (selectedSchool?.id) {
        setTimeout(() => getCreatedMetrics(selectedSchool.id), 150);
      }
      setEditMetricsState({ id: null, visible: false, type: null });
      showSuccessMessage("Метрика успешно удалена.");
    } catch (err) {
      showErrorMessage("Произошла ошибка во время удаления метрики.");
      console.log(err);
    }
  };
  const handleEditMetric = (nodeData: any) => {
    setEditMetricsState({ visible: true, id: nodeData.id, type: null });
  };

  const handleCreateMetricFromExisting = (nodeData: any) => {
    setCreatingMetricState({ visible: true, id: nodeData.id });
  };

  useEffect(() => {
    try {
      if (selected.length > 1) {
        const newSelected = selected.map((el: any) =>
          Object.hasOwn(el, "id") ? findObjectById(dataItems, el.id)! : el
        );
        setSelected(newSelected);
        setCheckedKeys(newSelected.map((el: { key: string }) => el.key));
      }
    } catch (err) {
      console.log(err);
    }
  }, [dataItems]);

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto scroll";
    }

    return () => {
      document.body.style.overflow = "auto scroll";
    };
  }, [isModalVisible]);

  const listHeight = useMemo(
    () => getLineHeightForModalSettings(selected?.length || 0),
    [selected?.length]
  );

  return (
    <>
      <Button
        type="default"
        disabled={isDisable}
        onClick={() => setIsModalVisible(true)}
      >
        {title}
      </Button>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        okText="Применить"
        onCancel={handleCancel}
        cancelText="Отмена"
        centered
        width={"60%"}
        className="modal-settings"
        destroyOnClose
      >
        <Row gutter={12}>
          <Col span={13}>
            <Input
              style={{ marginBottom: 8 }}
              placeholder={"Поиск " + title.toLowerCase()}
              onChange={onChange}
              allowClear
            />
            <div style={{ height: listHeight, overflow: "auto" }}>
              <Tree.DirectoryTree
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                blockNode
                checkable
                switcherIcon={<DownOutlined />}
                showIcon={false}
                selectable={false}
                loadData={onLoadData}
                treeData={loopSearch(data, searchValue)}
                titleRender={(nodeData: any) => {
                  return (
                    <>
                      {nodeData.description ? (
                        <>
                          {nodeData.disableCheckbox ? (
                            <Tooltip
                              placement="top"
                              title={
                                <div className="page-title-tooltip-text">
                                  Контактные данные пользователей не доступны.
                                  Обратитесь к администратору, чтобы получить к
                                  ним доступ.
                                </div>
                              }
                              color="#fff"
                              zIndex={9999}
                              overlayClassName="page-title-tooltip"
                            >
                              <span className="group-item-icon-wrapper">
                                {nodeData.title}
                                <span
                                  style={{ marginRight: 10, marginLeft: 5 }}
                                >
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      <div
                                        className="page-title-tooltip-text"
                                        dangerouslySetInnerHTML={{
                                          __html: nodeData.description,
                                        }}
                                      ></div>
                                    }
                                    color="#fff"
                                    zIndex={9999}
                                    overlayClassName="page-title-tooltip"
                                  >
                                    <QuestionCircleOutlined
                                      style={{ color: "#ccc", fontSize: 16 }}
                                    />
                                  </Tooltip>
                                </span>
                              </span>
                            </Tooltip>
                          ) : (
                            <MetricItem
                              handleCreateMetricFromExisting={
                                handleCreateMetricFromExisting
                              }
                              nodeData={nodeData}
                              handleEditMetric={handleEditMetric}
                              confirmCreatedMetricsRemoving={
                                confirmCreatedMetricsRemoving
                              }
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {nodeData.disableCheckbox ? (
                            <Tooltip
                              placement="top"
                              title={
                                <div className="page-title-tooltip-text">
                                  Контактные данные пользователей не доступны.
                                  Обратитесь к администратору, чтобы получить к
                                  ним доступ.
                                </div>
                              }
                              color="#fff"
                              zIndex={9999}
                              overlayClassName="page-title-tooltip"
                            >
                              <span>{nodeData.title}</span>
                            </Tooltip>
                          ) : (
                            <MetricItem
                              handleCreateMetricFromExisting={
                                handleCreateMetricFromExisting
                              }
                              nodeData={nodeData}
                              handleEditMetric={handleEditMetric}
                              confirmCreatedMetricsRemoving={
                                confirmCreatedMetricsRemoving
                              }
                            />
                          )}
                        </>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <Space style={{ marginTop: "20px" }}>
              {title === "Метрики" && (
                <Button
                  type="primary"
                  icon={<ClearOutlined />}
                  onClick={() => setIsCreateMetricsVisible(true)}
                >
                  Создать метрику
                </Button>
              )}
              <div>
                <Button
                  type="default"
                  icon={<ClearOutlined />}
                  onClick={() => clearCheckboxes()}
                >
                  Очистить выбор
                </Button>
              </div>
            </Space>
          </Col>

          <Col span={11}>
            <ReactSortable
              list={selected.map((item: any) => ({ ...item, chosen: true }))}
              className="modalSortable__container"
              setList={setSelected}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
            >
              {selected.map((item: any) => (
                <div className="drag-item" key={item.key}>
                  <span className="drag-handler">
                    <EllipsisOutlined rotate={90} />
                    <span
                      style={{
                        backgroundColor: item?.background
                          ? item.background
                          : "unset",
                        width: "100%",
                      }}
                      className="drag-label"
                    >
                      {item.label || item.name}
                    </span>
                  </span>
                  <div
                    onClick={() => removeSelect(item.key)}
                    className="drag-remove"
                  >
                    <CloseOutlined />
                  </div>
                </div>
              ))}
            </ReactSortable>
          </Col>
        </Row>

        {editMetricsState.visible && (
          <EditMetricsModal
            confirmCreatedMetricsRemoving={confirmCreatedMetricsRemoving}
            id={editMetricsState.id}
            visible={editMetricsState.visible}
            setEditState={setEditMetricsState}
          />
        )}
        {creatingMetricState.visible && (
          <CreateFromExistingMetricModal
            id={creatingMetricState.id}
            visible={creatingMetricState.visible}
            setCreateState={setCreatingMetricState}
          />
        )}
      </Modal>
    </>
  );
};

export default ModalSettingsOrder;
