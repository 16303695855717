import React, { FC, useEffect } from "react"

import { defaultGroupings, defaultMetrics } from "../../app/providers/redux/slices/reports/table/tableSlice"
import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import CreateMetricsModal from "../../shared/common/components/reports/CreateMetricsModal"
import { listKeysGroupings } from "../../shared/common/components/reports/Groupings"
import { listKeysMetrics } from "../../shared/common/components/reports/Metrics"
import FirstRegIcon from "../../shared/common/components/reports/params/attribution/icons/FirstRegIcon"
import LastRegIcon from "../../shared/common/components/reports/params/attribution/icons/LastRegIcon"
import TypeIcon from "../../shared/common/components/reports/params/attribution/icons/TypeIcon"
import Params from "../../shared/common/components/reports/params/Params"
import Chart from "../../shared/common/components/reports/payment-chart/Chart"
import Table from "../../shared/common/components/reports/payment-table/Table"
import UniversalReportTitle from "../../shared/common/components/reports/un-title/UniversalReportTitle"
import { chartDetails } from "../../shared/common/constants/details"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"
import { ToggleChart } from "../../widgets/ToggleChart"
import {isAccessEnded} from "../../helpers/utils/functions";

const defaultMetricsOrderNotPrepare = [ // Дефолтные метрики
    "payments_count_3",
    "buyers_count_3",
    "payments_sum_3",
    "payments_sum_avg_3",
    "payments_commission_sum_3",
    "payments_sum_with_commission_3",

].map((dataKey) =>
    listKeysMetrics.find((item: any) => item.dataKey + "_3" === dataKey))
export const defaultMetricsPayment = defaultMetricsOrderNotPrepare.map((item) => {
    return { ...item, dataKey: item.dataKey + "_3" }
})
const defaultGroupingsOrderNotPrepare = [ // Дефолтные группировки
    "reg_utm_source_3",
    "reg_utm_medium_3",
    "reg_utm_campaign_3",
].map((dataKey) => listKeysGroupings.find((item: any) => item.dataKey + "_3" === dataKey))
export const defaultGroupingsPayment = defaultGroupingsOrderNotPrepare.map((item) => {
    return { ...item, dataKey: item.dataKey + "_3" }
})
const PaymentReports: FC = () => {
    const { isChartVisible } = useTypedSelector((state)=>state.chart)
    useEffect(() => {
        document.body.style.overflow = "scroll"
        document.body.style.overflowX = "auto"
    },[])
    const { isChartLoading, isTableLoading } = useTypedSelector(
        (state) => state.meta
    )

    const { isCreateMetricsVisible } = useTypedSelector((state) => state.reports)

    const {
        setFilters,
        setFiltersTable,
        setCurrentReport,
        setMetrics,
        setCurrentSegment,
        setSortTable,
        setMetaCompare,
        setGroupings,
        setMetaChartFetching,
        setMetricsChecked,
        setCurrentFunnel,
        setIsCreateMetricsVisible,
    } = useActions()

    const attrItems = [
        {
            id: "type_applications",
            name: "Тип регистрации:",
            selectValues: [
                { name: "Нулевые заказы GC", id: "2", icon: null },
                { name: "Прямая продажа", id: "4", icon: null },
                { name: "Регистрации GC", id: "0", icon: null },
                { name: "SCAN", id: "1", icon: null },
                //{ name: "Ручное добавление", id: "3", icon: null },
            ],
            description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Прямая продажа - эта регистрация создаётся автоматически в SCANе при создании платного заказа. Название регистрации будет являться название платного предложения;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
            icon: <TypeIcon />,
        },
        {
            id: "attribution",
            name: "Атрибуция:",
            selectValues: [
                { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
                { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
            ],
            description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
            icon: null,
        },
    ]


    useEffect(() => {
        setMetaCompare({ value:false })
        setMetrics({ data:defaultMetricsPayment })
        setGroupings({ data:defaultGroupingsPayment })
        //setFilters([])
        setFiltersTable([])
        setCurrentReport({})
        setSortTable({})
        setCurrentSegment(null)
        setTimeout(()=>{
            setMetaChartFetching({ value:true })
        },700)
        setMetricsChecked({ data:[ { label:defaultMetricsPayment[0].label,name:defaultMetricsPayment[0].dataKey } ] })
        return () => {
            setMetaCompare({ value:false })
            setMetrics({ data:defaultMetrics })
            setGroupings({ data:defaultGroupings })
            setFilters([])
            setFiltersTable([])
            setCurrentReport({})
            setSortTable({})
            setCurrentFunnel([])
            setCurrentSegment(null)
        }
    }, [])

    return (
        <>
            <MainLayout pageTitle="Отчёт по платежам">
                <div className="page-wrapper">
                    <PageHead
                        title="Отчёт по платежам"
                        description={""}
                        reportInfo={true}
                    />
                    {isAccessEnded() ? (
                        <iframe
                            style={{border: "none", width: "990px", float: "left", minHeight: "100vh", marginTop: "2vh"}}
                            src={`https://help.scananalytics.ru/dostup-k-otchyotam-po-platezham?timestamp=${new Date().getTime()}`}
                            width="100%"
                            height="100%"
                        />
                    ) : (
                        <div className="page-content">
                            <Loader loading={isTableLoading || isChartLoading}>
                                <UniversalReportTitle/>

                                <Params
                                    attributionItems={attrItems}
                                    detailsItems={chartDetails}
                                    filtersFilter={[
                                        "Пользователи",
                                        "Регистрации",
                                        "Заказы",
                                        "Платежи",
                                    ]}
                                />
                            </Loader>
                            <Loader loading={isTableLoading || isChartLoading}>
                                {!isChartVisible && <ToggleChart/>}
                            </Loader>
                            {isChartVisible && <Chart/>}
                            <Table/>
                        </div>
                    )}
                </div>
            </MainLayout>

            {isCreateMetricsVisible && (<CreateMetricsModal title="Создание метрики в отчете по платежам" />)}
        </>
    )
}

export default PaymentReports
