import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import FilterItem from "../../shared/common/components/reports/params/filter/FilterItem";
import IconFilter from "../../shared/common/components/reports/params/filter/IconFilter";
import {
  averageFormatGroup,
  covertFormatGroup,
  moneyFormatGroup,
  nativeFormatGroup,
  percentFormatGroup,
  summaryFormatGroup,
} from "../../shared/common/constants/groups";
import { groups } from "../../shared/common/constants/reports";
import { IChartData } from "../../app/providers/redux/slices/reports/chart/types";
import { IFilter } from "../../app/providers/redux/slices/reports/params/filter/types";
import { IPeriodState } from "../../app/providers/redux/slices/reports/params/period/types";
const recurseChildrenFilter = (rows: any, filters: any) => {
  return rows
    .filter((item: any) => {
      return Object.entries(filters).every(([key, val]: any) => {
        let check;
        switch (val.action) {
          case "less":
            check = item[key] < val.value;
            break;
          case "equal":
            check = item[key] === val.value;
            break;
          case "over":
            check = item[key] > val.value;
            break;

          default:
            check = true;
        }
        return check;
      });
    })
    .map((row: any) => {
      if (row.children && row.children.length > 0) {
        row.children = recurseChildrenFilter(row.children, filters);
      }
      return row;
    });
};

const recurseChildrenSort = (rows: any, funSort: any) => {
  rows.sort(funSort);
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    if (row.children && row.children.length > 0) {
      recurseChildrenSort(row.children, funSort);
    }
  }
  return rows;
};

const recurseChildrenPercent = (rows: any, percentages: any, totalRow: any) => {
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];

    percentages.forEach((percKey: any) => {
      if (
        typeof row[percKey] === "number" &&
        typeof totalRow[percKey] === "number" &&
        totalRow[percKey] > 0
      ) {
        row[percKey] =
          parseFloat(String((row[percKey] / totalRow[percKey]) * 100)).toFixed(
            2
          ) + " %";
      } else if (
        typeof row[percKey] === "string" &&
        row[percKey].includes(".")
      ) {
        const parsedVal = parseFloat(row[percKey]);
        const parsedTotalVal = parseFloat(totalRow[percKey]);

        row[percKey] =
          parseFloat(String((parsedVal / parsedTotalVal) * 100)).toFixed(2) +
          " %";
      } else {
        row[percKey] = "0.00 %";
      }
    });

    if (row.children && row.children.length > 0) {
      recurseChildrenPercent(row.children, percentages, totalRow);
    }
  }

  return rows;
};
export const recurseChildrenEmpty = (rows: any) => {
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    if (row.children && row.children.length > 0) {
      recurseChildrenEmpty(row.children);
    }
    if (row.children && row.children.length === 0) {
      row.children.push({
        group: "empty",
        pathStr: row.pathStr + "-empty",
        parentId: row.id,
      });
    }
  }
  return rows;
};

export const finishRows = ({
  rows = [],
  filters = {},
  sort = {},
  percentages = [],
  totalRow,
  totalChoosenRow,
}: any) => {
  // копирую многомерный массив
  let data = JSON.parse(JSON.stringify(rows));
  const totalChoosenRowData = {
    group: "Итого и среднее выбранных",
    pathStr: "total_choosen",
    ...totalChoosenRow,
  };

  const totalRowData = {
    group: `Итого и среднее <span style="color: gray;padding-left: 8px;">${rows?.length} строк</span>`,
    pathStr: "total",
    ...totalRow,
  };

  // фильтрация
  if (Object.keys(filters).length > 0) {
    data = recurseChildrenFilter(data, filters);
  }

  // сортировка
  if (Object.keys(sort).length > 0) {
    const sortItem = Object.entries(sort)[0];
    if (sortItem[0] === "group") {
      if (sortItem[1] === "ASC") {
        data = recurseChildrenSort(data, (a: any, b: any) => {
          if (a.group < b.group) return -1;
          if (a.group > b.group) return 1;
          return 0;
        });
      } else {
        data = recurseChildrenSort(data, (a: any, b: any) => {
          if (a.group < b.group) return 1;
          if (a.group > b.group) return -1;
          return 0;
        });
      }
    } else {
      if (sortItem[1] === "ASC") {
        data = recurseChildrenSort(
          data,
          (a: any, b: any) => a[sortItem[0]] - b[sortItem[0]]
        );
        if (
          data.length > 0 &&
          data[0] !== undefined &&
          typeof data[0][sortItem[0]] === "string"
        ) {
          const sortItemValue = data[0][sortItem[0]];

          if (
            sortItemValue.includes &&
            (sortItemValue.includes(" +") || sortItemValue.includes(" -"))
          ) {
            data = recurseChildrenSort(data, (a: any, b: any) => {
              const numA = parseFloat(a[sortItem[0]].match(/\d+\.?\d*/)[0]);
              const numB = parseFloat(b[sortItem[0]].match(/\d+\.?\d*/)[0]);
              return numA - numB;
            });
          }
        }
      } else {
        data = recurseChildrenSort(
          data,
          (a: any, b: any) => b[sortItem[0]] - a[sortItem[0]]
        );
        if (
          data.length > 0 &&
          data[0] !== undefined &&
          typeof data[0][sortItem[0]] === "string"
        ) {
          const sortItemValue = data[0][sortItem[0]];

          if (
            sortItemValue.includes &&
            (sortItemValue.includes(" +") || sortItemValue.includes(" -"))
          ) {
            data = recurseChildrenSort(data, (a: any, b: any) => {
              const numA = parseFloat(a[sortItem[0]].match(/\d+\.?\d*/)[0]);
              const numB = parseFloat(b[sortItem[0]].match(/\d+\.?\d*/)[0]);
              return numB - numA;
            });
          }
        }
      }
    }
  }

  // проценты
  if (percentages.length > 0) {
    data = recurseChildrenPercent(data, percentages, totalRow);
  }

  // добавляю empty когда пусто в результатах группы
  data = recurseChildrenEmpty(data);
  totalChoosenRow ? data.unshift(totalChoosenRowData) : null;
  data.unshift(totalRowData);
  return data;
};

export const loopSearch = (data: any, searchValue: string) => {
  try {
    const result = data.map((item: any) => {
      const reg = new RegExp(searchValue, "i");
      const index = item.label.search(reg);
      const beforeStr = item.label.substr(0, index);
      const searchStr = item.label.substr(index, searchValue.length);

      const alignString =
        typeof item.name === "object"
          ? item.name.props.children[0].substr(index + searchValue.length)
            ? "inherit"
            : "space-between"
          : "inherit";
      const afterStr =
        typeof item.name === "object" ? (
          <span
            className="group-item-icon-wrapper"
            style={{ width: alignString === "inherit" ? "100%" : "auto" }}
          >
            <span
              style={{
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: 190,
              }}
            >
              {item.name.props.children[0]?.substr(index + searchValue.length)}
            </span>
            {item.name.props.children[1]}
          </span>
        ) : (
          item.name?.substr(index + searchValue.length)
        );

      let title = item.name;

      if (index > -1 && searchValue) {
        title = (
          <span
            style={{
              display: "flex",
              justifyContent: alignString,
              flexDirection: "row",
              flexWrap: "nowrap",
              backgroundColor: "#fec047",
            }}
          >
            {beforeStr}
            {/*<span className="tree-search-value">*/}
            {searchStr}
            {/*</span>*/}
            {afterStr}
          </span>
        );
      }

      if (item.children) {
        return {
          ...item,
          title,
          children: loopSearch(item.children, searchValue),
        };
      }

      return {
        ...item,
        title: title,
        isLeaf: true,
      };
    });
    return result;
  } catch (err) {
    console.log(err);
  }
  return [];
};

export const getChildrenKey = (dataKey: string, data: any) => {
  let res = false;

  data.forEach((item: any) => {
    if (item.children) {
      res = getChildrenKey(dataKey, item.children);
    } else {
      if (item.dataKey === dataKey) {
        res = item.key;
      }
    }
  });

  return res;
};

export const filterGroupsDisabledData = (data: any, hiddenKeys: string[]) => {
  let _data = data;

  const loop = (loopData: any) => {
    for (let i = 0; i < loopData.length; i++) {
      loopData[i] = {
        ...loopData[i],
        disableCheckbox: hiddenKeys.includes(loopData[i].dataKey),
      };
      if (loopData[i].children) {
        loop(loopData[i].children);
      }
    }

    return loopData;
  };

  _data = loop(_data);

  return _data;
};

export const disabledMinutes = () => {
  let result = [];

  for (let i = 0; i < 60; i++) {
    if (i % 5 !== 0) result.push(i);
  }

  return result;
};

export const updateFilterItemInList = (
  filters: IFilter[],
  index: number,
  payload: any
) => {
  console.log(1, filters);
  return filters.map((item, indexArr) => {
    return index === indexArr ? payload : item;
  });
};

export const getParentKey = (id: string, tree: any): string => {
  let parentKey;

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.children) {
      if (node.children.some((item: any) => item.id === id)) {
        parentKey = node.key;
      } else if (getParentKey(id, node.children)) {
        parentKey = getParentKey(id, node.children);
      }
    }
  }

  return parentKey;
};

export const getMetricsParentKey = (id: string, tree: any): string => {
  let parentKey;

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.children) {
      if (node.children.some((item: any) => item.key === id)) {
        parentKey = node.key;
      } else if (getParentKey(id, node.children)) {
        parentKey = getParentKey(id, node.children);
      }
    }
  }

  return parentKey;
};

export const generateListKeys = (data: any) => {
  let dataList: any = [];

  function getTemp(data: any) {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      dataList.push({ ...node });

      if (node.children) {
        getTemp(node.children);
      }
    }
  }

  getTemp(data);

  return dataList;
};

export const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

const TitleRoot = ({ color, icon, searchName, description }: any) => {
  return (
    <strong
      style={{
        color,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span>
        <IconFilter icon={icon} />
        &nbsp;{searchName}
      </span>

      {description ? (
        <span
          style={{
            float: "right",
            marginRight: 20,
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            placement="bottom"
            title={
              <div
                className="page-title-tooltip-text"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            }
            color="#fff"
            zIndex={9999}
            overlayClassName="page-title-tooltip"
          >
            <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 16 }} />
          </Tooltip>
        </span>
      ) : null}
    </strong>
  );
};

export const prepareGroupsData = (data: any, _preKey?: any) => {
  const preKey = _preKey || "0";

  for (let i = 0; i < data.length; i++) {
    // добавляю ключ
    const key = `${preKey}-${i}`;
    data[i].key = key;
    data[i].label = data[i].name;
    // выключаю check в родительских элементах
    if (data[i].children) {
      data[i].checkable = false;
      prepareGroupsData(data[i].children, key);
    }
  }

  return data;
};

export const prepareData = (
  data: any,
  searchValue: string,
  filters: { [key: string]: any }[],
  disableAttr: boolean,
  _fullName?: string,
  _color?: string,
  _icon?: any,
  _preKey?: string,
  _namePath?: string[],
  _description?: string,
  filterParent?: any
) => {
  try {
    const preKey = _preKey || "0";
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      const color = _color || item.color;
      const icon = _icon || item.icon;
      const fullName = item?.full_name || _fullName;
      const namePath = _namePath ? [..._namePath, item.name] : [item.name];
      const key = `${preKey}-${i}`;
      const description = item.description || "";
      try {
        item.full_name = fullName;
      } catch (err) {
        console.log(err);
      }

      // поиск
      if (searchValue) {
        const reg = new RegExp(searchValue, "i");

        const index = item.name.search(reg);
        const beforeStr = item.name.substr(0, index);
        const searchStr = item.name.substr(index, searchValue.length);
        const afterStr = item.name.substr(index + searchValue.length);

        item.searchName =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="tree-search-value">{searchStr}</span>
              {afterStr}
            </span>
          ) : (
            <span className="tree-search-hide">{item.name}</span>
          );
      } else {
        item.searchName = <span>{item.name}</span>;
      }

      const countSelected = filters.filter((f) => f.path?.includes(item.id));
      const countEl =
        countSelected.length > 0 ? (
          <div className="picker-counter-element" />
        ) : null;
      const activeParent = filterParent?.some(
        (el: any) => el.id === item.id
      ) ? (
        <div className="picker-counter-element" />
      ) : null;
      const countElement = filters.find(
        (el) => el?.namePath[el?.namePath?.length - 2] === item.name
      ) ? (
        <div className="picker-counter-element" />
      ) : null;
      const firstLevel = filters.find((el) => el.namePath[1] === item.name) ? (
        <div className="picker-counter-element" />
      ) : null;
      if (item.root) {
        item.title = () => (
          <div>
            <TitleRoot {...item} /> {countEl || firstLevel}
          </div>
        );
      } else if (item.children) {
        item.title = () => (
          <span>
            {item.searchName} {countEl || activeParent || countElement}
          </span>
        );
      } else
        item.title = () => {
          return item.description ? (
            <FilterItem
              index={i}
              {...item}
              extra={
                <span
                  style={{
                    float: "right",
                    marginRight: 20,
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title={
                      <div
                        className="page-title-tooltip-text"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    }
                    color="#fff"
                    zIndex={9999}
                    overlayClassName="page-title-tooltip"
                  >
                    <QuestionCircleOutlined
                      style={{ color: "#ccc", fontSize: 16 }}
                    />
                  </Tooltip>
                </span>
              }
            />
          ) : (
            <FilterItem index={i} {...item} />
          );
        };

      item.label = item.name;
      item.color = color;
      item.icon = icon;
      item.key = key;
      item.namePath = namePath;
      item.description = description;
      if (item.children) {
        item.checkable = false;
        prepareData(
          item.children,
          searchValue,
          filters,
          disableAttr,
          fullName,
          color,
          icon,
          key,
          namePath,
          description,
          filterParent
        );
      }
    }

    if (disableAttr) {
      data.forEach((item: any, index: number) => {
        if (
          item.id === "webinar_reg_days_end" ||
          item.id === "webinar_reg_days"
        ) {
          data.splice(index, 1);
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
  return data;
};

export const checkMetricsGroups = (
  metric: any,
  label: string,
  subLabel: string,
  metricsChecked: any
) => {
  let groupName = {
    name: label,
    label: label,
    show: true,
    group: "",
  };

  groups.forEach((group) => {
    group.metrics.forEach((metricItem) => {
      if (
        metric.name.includes(metricItem.name) &&
        metricsChecked.find((metricChecked: any) =>
          metricChecked.includes(metricItem.name)
        )
      ) {
        groupName.name = `${group.metrics[0].label} (${subLabel})`;
        groupName.group = group.groupName;
      }
    });

    if (metric.name === group.metrics[0].name) {
      groupName.name = label;
    }
  });

  return groupName;
};

export const filterAxisShow = (axisList: any) => {
  let finAxisList = axisList;

  groups.forEach((group) => {
    let groupAxisIndexes: any = [];

    finAxisList.forEach((axis: any, index: number) => {
      if (axis.group === group.groupName) {
        finAxisList[index].show = false;
        groupAxisIndexes.push(index);
      }
    });

    finAxisList.forEach((item: any) => {
      if (item.group === group.groupName) {
        item.seriesName = finAxisList[groupAxisIndexes[0]].seriesName;
      }
    });

    if (groupAxisIndexes.length > 0) {
      finAxisList[groupAxisIndexes[0]].show = true;
    }
  });

  finAxisList.forEach((item1: any) => {
    const itemName1 = item1.seriesName.substring(
      0,
      item1.seriesName.indexOf("(") - 1
    );

    finAxisList.forEach((item2: any) => {
      const itemName2 = item2.seriesName.substring(
        0,
        item2.seriesName.indexOf("(") - 1
      );
      if (itemName1 === itemName2 && item1.seriesName !== item2.seriesName) {
        item2.seriesName = item1.seriesName;
        item2.show = false;
      }
    });
  });
  return finAxisList.map((axis: any) => {
    return {
      seriesName: axis.seriesName,
      show: axis.show,
      opposite: true,
      isCustomMetric: axis.isCustomMetric,
      dataKey: axis.dataKey,
    };
  });
};

const formatDetailsKeyInNumber = (detailsKey: string) => {
  switch (detailsKey) {
    case "d":
      return 1;
    case "w":
      return 7;
    case "d-w":
      return 1;
    case "m":
      return 30;
    case "q":
      return 90;
    case "y":
      return 365;
    default:
      return 1;
  }
};

export const predictDotsCount = (
  period: any,
  groupsCount: number,
  metricsCount: number,
  detailsKey: string
) => {
  const details = formatDetailsKeyInNumber(detailsKey);
  const daysCount = moment(period.end).diff(moment(period.start), "days");

  return (
    (daysCount * (groupsCount === 1 ? metricsCount : groupsCount)) / details
  );
};

export const appendDetails = (detailsKey: string) => {
  let detailsArray = ["d", "w", "m", "q", "y"];

  if (detailsKey === "d-w") {
    return "m";
  }

  let newDetail = detailsKey;

  detailsArray.forEach((item, index) => {
    if (item === detailsKey) {
      if (index === 4) {
        newDetail = detailsArray[index];
      } else {
        newDetail = detailsArray[index + 1];
      }
    }
  });

  return newDetail;
};

export const findRowRecurse = (rows: any, pathStr: string): any => {
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    if (row.pathStr === pathStr) {
      return row;
    }
    if (row.children && row.children.length > 0) {
      return findRowRecurse(row.children, pathStr);
    }
  }
};

const numberWithSpaces = (x: string | number) => {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};
export const findMaxY = (series: IChartData[]) => {
  const maxY = series.reduce((max, item) => {
    const itemMax = Math.max(...item.data.map((el: any) => Number(el.y)));
    return Math.max(max, itemMax);
  }, 0);
  return maxY + 20;
};
export const formatCellData = (cellData: number | string, dataKey?: string) => {
  if (cellData === 0) return "0";
  if (!cellData) return "-";

  if (typeof cellData === "number") {
    return numberWithSpaces(cellData);
  }

  if (
    (cellData.includes("-") || cellData.includes("+")) &&
    cellData.includes("%")
  ) {
    return formatCompareData(`${cellData}`, dataKey);
  }
  if (cellData.includes("%") || cellData.includes("=")) {
    return `${cellData}`;
  }
  const floatData = parseFloat(cellData);
  if (floatData && !cellData.includes("-")) {
    return numberWithSpaces(floatData);
  }
  if (cellData.includes("-")) {
    const formattedCell = cellData.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return formattedCell;
  }
  return cellData;
};

const formatCompareData = (cellData: string, dataKey?: string) => {
  const metricsMap = [
    "old_users_count",
    "refunds_count",
    "refunds_sum",
    "buyers_with_refund_count",
    "user_to_refunder_converse",
    "buyer_to_refunder_converse",
    "web_1min_users_count",
    "web_5min_users_count",
    "web_15min_users_count",
    "web_60min_users_count",
    "web_1min_users_part",
    "web_5min_users_part",
    "web_15min_users_part",
    "web_60min_users_part",
    "cpm",
    "cpc",
    "reg_price",
    "user_price",
    "orders_price",
    "orders_creators_price",
    "forders_price",
    "buyers_price",
    "payments_price",
    "web_user_price",
    "drr",
  ];
  if (location.pathname.includes("order-reports")) {
    metricsMap.forEach((metric, index) => {
      metricsMap[index] = metric + "_2";
    });
  }
  if (location.pathname.includes("payment-reports")) {
    metricsMap.forEach((metric, index) => {
      metricsMap[index] = metric + "_3";
    });
  }
  if (location.pathname.includes("webinar-reports")) {
    metricsMap.forEach((metric, index) => {
      metricsMap[index] = metric + "_4";
    });
  }
  const formattedCell = cellData.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const regex = /(-?\d{1,3}(?: \d{3})*)(?:\.(\d+))?(%)?/g;
  const values = formattedCell.match(regex);
  let color;
  if (values) {
    if (dataKey) {
      if (metricsMap.includes(dataKey)) {
        color = values[1].includes("-") ? "green" : "red";
      } else {
        color = values[1].includes("-") ? "red" : "green";
      }
    }

    const style = {
      color,
      fontSize: 10,
      marginRight: 5,
      marginLeft: 5,
    };
    return (
      <span className={`column-span ${dataKey}`}>
        {values[0]} <span style={style}>{values[1]}</span>
        {values[2]}
      </span>
    );
  }
};
export const formatSummaryPayments = (string: string, currency: string) => {
  return `${currency} ${string.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
};
export const formatMoneyData = (
  cellData: string | number,
  currency: string,
  dataKey?: string
) => {
  if (typeof cellData === "string" && cellData.includes("%")) {
    return <>{formatCellData(cellData, dataKey)}</>;
  }
  if (cellData === 0 || cellData === "0") return "0";
  if (!cellData || cellData === "-") return "-";
  if (
    typeof cellData === "string" &&
    (cellData.includes(" -") || cellData.includes(" +"))
  ) {
    return (
      <>
        {currency} {formatCellData(cellData, dataKey)}
      </>
    );
  }
  return (
    <>
      {currency}{" "}
      {formatCellData(
        parseFloat(cellData + "")
          .toFixed(2)
          .toString(),
        dataKey
      )}
    </>
  );
};

export const formatConvertData = (
  cellData: string | number,
  dataKey?: string
) => {
  if (!cellData && cellData !== 0) return "-";

  if (typeof cellData === "string") {
    if (
      (cellData.includes("-") || cellData.includes("+")) &&
      cellData.includes("%")
    ) {
      return formatCompareData(`${cellData}`, dataKey);
    }

    if (cellData.includes("%") || cellData.includes("=")) {
      return `${cellData}`;
    }
  }

  return `${cellData}%`;
};

export const calculateMaxMinBubbleChart = (data: any) => {
  let minX = 0;
  let maxX = 0;
  let minY = 0;
  let maxY = 0;

  data.forEach((dataItem: any) => {
    const itemX = dataItem.data[0][0];
    const itemY = dataItem.data[0][1];

    if (itemX > maxX) maxX = itemX;
    if (itemX < minX) minX = itemX;

    if (itemY > maxY) maxY = itemY;
    if (itemY < minY) minY = itemY;
  });

  return {
    maxX: maxX + 30,
    minX: minX - 30,
    maxY: maxY + 30,
    minY: minY - 30,
  };
};

export const buildSeriesForBubbleChart = (
  groupings: any,
  totalGroupings: any,
  chartType: string,
  tableData: any,
  checkedMetrics: any
) => {
  if (chartType !== "bubble") {
    return [];
  }

  let _series = [];

  if (totalGroupings.length > 0) {
    if (checkedMetrics.length >= 3) {
      if (tableData.total) {
        let x, y, z;

        try {
          x = tableData.total[checkedMetrics[0].name] || 0;
          y = tableData.total[checkedMetrics[1].name] || 0;
          z = tableData.total[checkedMetrics[2].name] || 0;
        } catch (e) {
          x = 0;
          y = 0;
          z = 0;
        }

        const totalFinalSeriesData = [[parseInt(x), parseInt(y), parseInt(z)]];

        _series.push({ name: "Всего", data: totalFinalSeriesData });
      }
    }
  }

  if (groupings.length > 0) {
    groupings.forEach((groupItem: any) => {
      if (checkedMetrics.length >= 3) {
        let groupData;

        if (groupItem.label.includes("→")) {
          let grops = groupItem.label.split("→");
          let parentGroup = tableData.data.find((item: any) =>
            grops[0].includes(item.group)
          );

          const recurrentSearch = (
            grGroup: any,
            searchValues: string,
            index: number
          ) => {
            if (grGroup.children) {
              let result;
              grGroup.children.forEach((item: any) => {
                if (searchValues[index].includes(item.group)) {
                  if (
                    searchValues[index] ===
                    searchValues[searchValues.length - 1]
                  ) {
                    result = item;
                  } else {
                    result = recurrentSearch(item, searchValues, index + 1);
                  }
                }
              });

              return result;
            } else {
              return grGroup;
            }
          };

          groupData = recurrentSearch(parentGroup, grops, 1);
        } else {
          groupData = tableData.data.find(
            (item: any) => item.group === groupItem.label
          );
        }

        if (groupData) {
          let x, y, z;

          try {
            x = groupData[checkedMetrics[0].name] || 0;
            y = groupData[checkedMetrics[1].name] || 0;
            z = groupData[checkedMetrics[2].name] || 0;
          } catch (e) {
            x = 0;
            y = 0;
            z = 0;
          }

          const finalSeriesData = [[parseInt(x), parseInt(y), parseInt(z)]];

          _series.push({ name: groupItem.label, data: finalSeriesData });
        }
      }
    });
  }

  return _series;
};

export const checkConvertDataType = (dataIndex: string) => {
  let result = false;
  if (location.pathname.includes("order-reports")) {
    const updatedCovertFormatGroup = covertFormatGroup.map(
      (group) => group + "_2"
    );
    result = updatedCovertFormatGroup.includes(dataIndex);
  } else if (location.pathname.includes("payment-reports")) {
    const updatedCovertFormatGroup = covertFormatGroup.map(
      (group) => group + "_3"
    );
    result = updatedCovertFormatGroup.includes(dataIndex);
  } else if (location.pathname.includes("webinar-reports")) {
    const updatedCovertFormatGroup = covertFormatGroup.map(
      (group) => group + "_4"
    );
    result = updatedCovertFormatGroup.includes(dataIndex);
  } else {
    covertFormatGroup.forEach((group) => {
      if (dataIndex === group) {
        result = true;
      }
    });
  }

  return result;
};

export const checkMoneyDataType = (dataIndex: string) => {
  let result = false;
  if (location.pathname.includes("order-reports")) {
    const updatedMoneyFormatGroup = moneyFormatGroup.map(
      (group) => group + "_2"
    );
    result = updatedMoneyFormatGroup.includes(dataIndex);
  } else if (location.pathname.includes("payment-reports")) {
    const updatedMoneyFormatGroup = moneyFormatGroup.map(
      (group) => group + "_3"
    );
    result = updatedMoneyFormatGroup.includes(dataIndex);
  } else if (location.pathname.includes("webinar-reports")) {
    const updatedMoneyFormatGroup = moneyFormatGroup.map(
      (group) => group + "_4"
    );
    result = updatedMoneyFormatGroup.includes(dataIndex);
  } else {
    moneyFormatGroup.forEach((group) => {
      if (dataIndex === group) {
        result = true;
      }
    });
  }
  return result;
};
export const checkSummaryDataType = (dataIndex: string) => {
  let result = false;
  if (location.pathname.includes("order-reports")) {
    const updatedSummary = summaryFormatGroup.map((group) => group + "_2");
    result = updatedSummary.includes(dataIndex);
  } else if (location.pathname.includes("payment-reports")) {
    const updatedSummary = summaryFormatGroup.map((group) => group + "_3");
    result = updatedSummary.includes(dataIndex);
  } else if (location.pathname.includes("webinar-reports")) {
    const updatedSummary = summaryFormatGroup.map((group) => group + "_4");
    result = updatedSummary.includes(dataIndex);
  } else {
    summaryFormatGroup.forEach((group) => {
      if (dataIndex === group) {
        result = true;
      }
    });
  }
  return result;
};

export const checkPercentDataType = (dataIndex: string) => {
  let result = false;
  if (location.pathname.includes("order-reports")) {
    const updatedPercent = percentFormatGroup.map((group) => group + "_2");
    result = updatedPercent.includes(dataIndex);
  } else if (location.pathname.includes("payment-reports")) {
    const updatedPercent = percentFormatGroup.map((group) => group + "_3");
    result = updatedPercent.includes(dataIndex);
  } else if (location.pathname.includes("webinar-reports")) {
    const updatedPercent = percentFormatGroup.map((group) => group + "_4");
    result = updatedPercent.includes(dataIndex);
  } else {
    percentFormatGroup.forEach((group) => {
      if (dataIndex === group) {
        result = true;
      }
    });
  }
  return result;
};

export const checkNativeDataType = (dataIndex: string) => {
  let result = false;

  nativeFormatGroup.forEach((group) => {
    if (dataIndex === group) {
      result = true;
    }
  });

  return result;
};
export const checkAverageDataType = (dataIndex: string) => {
  let result = false;
  if (location.pathname.includes("order-reports")) {
    const updatedAverage = averageFormatGroup.map((group) => group + "_2");
    result = updatedAverage.includes(dataIndex);
  } else if (location.pathname.includes("payment-reports")) {
    const updatedAverage = averageFormatGroup.map((group) => group + "_3");
    result = updatedAverage.includes(dataIndex);
  } else if (location.pathname.includes("webinar-reports")) {
    const updatedAverage = averageFormatGroup.map((group) => group + "_4");
    result = updatedAverage.includes(dataIndex);
  } else {
    averageFormatGroup.forEach((group) => {
      if (dataIndex === group) {
        result = true;
      }
    });
  }

  return result;
};
const removeNbsp = (value: string) => {
  return value.replace(/&nbsp;/g, " ").replace(/<[^>]*>/g, "");
};
export const prepareRows = ({ rows, parent, groupings }: any) => {
  return rows.map((item: any) => {
    location.pathname === "/users/payment-reports"
      ? (item.group = removeNbsp(item.group))
      : "";
    const path = parent ? [...parent.clientData.path] : [];
    const pathLabel = parent ? [...parent.clientData.pathLabel] : [];
    path.push(item.id);
    pathLabel.push(item.group);

    const expandable = groupings.length > path.length;
    return {
      ...item,
      pathStr: path.join("-"),
      pathStr2: uuidv4(),
      children: expandable ? [] : null,
      clientData: { path, pathLabel, loading: false, downloaded: false },
    };
  });
};

export const getCountDaysFromPeriod = (period: any) => {
  return moment(period.end).diff(moment(period.start), "days");
};

export const parseTableData = (data: any[]) => {
  return data.map((dataItem) => {
    const _dataItem = { ...dataItem };

    if (typeof _dataItem.name === "object") {
      _dataItem.name = (
        <span className="group-item-icon-wrapper">
          {dataItem.label}
          <span style={{ marginRight: 10, marginLeft: 5 }}>
            <Tooltip
              placement="bottom"
              title={
                <div
                  className="page-title-tooltip-text"
                  dangerouslySetInnerHTML={{ __html: dataItem.description }}
                />
              }
              color="#fff"
              zIndex={9999}
            >
              <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 16 }} />
            </Tooltip>
          </span>
        </span>
      );
    }

    return _dataItem;
  });
};

export const getGroupDataForBarChart = (
  isCompare: boolean,
  tableData: any,
  percentages: any,
  metric: any,
  group: any
) => {
  if (isCompare) {
    let findRow = findRowRecurse(tableData.data, group.pathStr);
    if (group.pathStr === "total") {
      findRow = tableData.total;
    }

    if (!findRow) return 0;
    if (percentages.includes(metric.name)) {
      return parseFloat(
        String((findRow[metric.name] / tableData.total[metric.name]) * 100)
      ).toFixed(0);
    }

    let _findRow = findRow[metric.name];
    let resRow;

    if (metric.name.includes("_2")) {
      const splittedGroupRowData = findRow[metric.name.split("_2")[0]];
      if (splittedGroupRowData) {
        if (typeof splittedGroupRowData === "number") {
          resRow = splittedGroupRowData;
        } else {
          const splittedRowData = splittedGroupRowData.includes("%")
            ? splittedGroupRowData.split("% ")[1]
            : splittedGroupRowData.includes("=")
            ? splittedGroupRowData.split("= ")[1]
            : splittedGroupRowData.includes("+")
            ? splittedGroupRowData.split("+ ")[1]
            : splittedGroupRowData.split("- ")[1];

          resRow = parseInt(splittedRowData);
        }
      } else {
        resRow = 0;
      }
    } else {
      if (_findRow) {
        if (typeof _findRow === "number") {
          resRow = _findRow;
        } else {
          const splittedRowData = _findRow.split(" -")[0];
          resRow = parseInt(splittedRowData);
        }
      } else {
        resRow = 0;
      }
    }

    return resRow;
  } else {
    let findRow = findRowRecurse(tableData.data, group.pathStr);
    if (group.pathStr === "total") {
      findRow = tableData.total;
    }

    if (!findRow) return 0;
    if (percentages.includes(metric.name)) {
      return parseFloat(
        String((findRow[metric.name] / tableData.total[metric.name]) * 100)
      ).toFixed(0);
    }

    return findRow[metric.name];
  }
};

export const numberFormat = (n: string) => {
  return ((parseInt(n) || 0) + "").replace(
    /(\d)(?=(\d\d\d)+([^\d]|$))/g,
    "$1 "
  );
};

export const toolFormat = (a: any, b: any) => {
  let num = ((parseInt(a) || 0) + "").replace(
    /(\d)(?=(\d\d\d)+([^\d]|$))/g,
    "$1 "
  );
  if ([9].includes(b)) {
    return num + " %";
  }
  return num;
};

export const formatBannerData = (
  bannerTitle: string,
  data: any,
  formatterFn: any
) => {
  switch (bannerTitle) {
    case "payments_sum":
      data = formatterFn(data);
      break;
    case "pay_per_user":
      data = formatterFn(data);
      break;
    case "commentators_part":
      data = numberFormat(data);
      break;
    case "commentators_count":
      data = numberFormat(data);
      break;
    case "webinar_users_count_desktop":
      data = numberFormat(data);
      break;
    case "webinar_users_count_mobile":
      data = numberFormat(data);
      break;
    case "webinar_users_count_tablet":
      data = numberFormat(data);
      break;
    case "comments_count":
      data = numberFormat(data);
      break;
    case "orders_creators_count":
      data = numberFormat(data);
      break;
    case "orders_count":
      data = numberFormat(data);
      break;
    case "complete_orders_count":
      data = numberFormat(data);
      break;
    case "clickers_count":
      data = numberFormat(data);
      break;
    case "clicks_count":
      data = numberFormat(data);
      break;
    default:
  }

  return data;
};

export const moneyFormat = (float: string) => {
  return parseFloat(float)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")
    .replace(".", ",");
};

export const webinarPredictDotsCount = (filters: any[]) => {
  let hoursCount = 3;

  const hoursFilter = filters.find((item) => item.id === "time_webinar");

  if (hoursFilter) {
    const { start, end } = hoursFilter;

    hoursCount = moment(end, [moment.ISO_8601, "HH:mm"]).diff(
      moment(start, [moment.ISO_8601, "HH:mm"]),
      "hours"
    );
  }

  if (hoursCount > 2) {
    return 2;
  } else {
    return 1;
  }
};

export const groupArrayBy = function (xs: any[], key: string) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const observeFiltersForWebinarLength = (filters: any[]) => {
  let hoursCount = 3;

  const hoursFilter = filters.find((item) => item.id === "time_webinar");

  if (hoursFilter) {
    const { start, end } = hoursFilter;

    hoursCount = moment(end, [moment.ISO_8601, "HH:mm"]).diff(
      moment(start, [moment.ISO_8601, "HH:mm"]),
      "hours"
    );
  }

  if (hoursCount > 2) {
    return 2;
  } else {
    return 1;
  }
};

export const searchFilter = (item: any, searchValue: string) => {
  if (item.name) {
    return removeNbsp(item.name)
      .toLowerCase()
      .includes(searchValue.toLowerCase());
  }
  return false;
};

export const handleReportPeriod = (period: IPeriodState): IPeriodState => {
  let _period = _.cloneDeep(period);

  // if (_period.interval !== "") {
  //     const startTime = { hour: 0, minute: 0, second: 0 }
  //     const endTime = { hour: 23, minute: 59, second: 59 }
  //
  //     let startEnd: {
  //         [key: string]: () => {
  //             start: moment.Moment;
  //             end: moment.Moment;
  //             interval?: string;
  //         };
  //     } = {
  //         today: () => {
  //             const start = moment().set(startTime)
  //             const end = moment().set(endTime)
  //             return { start, end, interval:"today" }
  //         },
  //         yesterday: () => {
  //             const start = moment().subtract(1, "days").set(startTime)
  //             const end = moment().subtract(1, "days").set(endTime)
  //             return { start, end , interval:"yesterday"}
  //         },
  //         week: () => {
  //             const start = moment().subtract(1, "weeks").set(startTime)
  //             const end = moment().set(endTime)
  //             return { start, end }
  //         },
  //         month: () => {
  //             const start = moment().subtract(1, "months").set(startTime)
  //             const end = moment().set(endTime)
  //             return { start, end }
  //         },
  //     }
  //     console.log(startEnd)
  //     const payload = startEnd[_period.interval]()
  //     console.log(payload)
  //
  //     _period.start = payload.start
  //     _period.end = payload.end
  // } else {
  //     _period.interval = ""
  // }

  return {
    start: _period.start,
    end: _period.end,
    interval: _period.interval || _period.select || "",
    select: _period.select || "",
  };
};
