import React, { useEffect, useState } from "react"

import { CloseOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Input, Popover, Tree, Typography } from "antd"
import { useLocation } from "react-router-dom"

import FilterItemSelected from "./FilterItemSelected"
import { getStartEndType } from "./pickers/DateTimeRangePicker"
import RefreshButton from "./RefreshButton"
import RequestButton from "./RequestButton"
import { PagesPathnames } from "../../../../../../app/types/ad"
import { findObjectById } from "../../../../../../helpers/utils/functions"
import {
  generateListKeys,
  getParentKey,
  prepareData,
} from "../../../../../../helpers/utils/reports"
import Segments from "../../../../../../pages/dashboards/components/Params/filter/segments"
import {
  calendarTypes,
  data,
  updateFilters,
} from "../../../../constants/filters"
import { useActions } from "../../../../hooks/useActions"
import { useForceUpdate } from "../../../../hooks/useForceUpdate"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

interface IUniversalFilterProps {
  isCompare: boolean;
  withSegment: boolean;
  disabled?: boolean;
  disableAttr?: boolean;
  filtersFilter?: string[];
  withLabel?: boolean;
  withRefresh?: boolean;
  isFixed?: boolean;
}

export const listKeys = generateListKeys(data)

const DashboardFilter = ({
  isCompare,
  withSegment,
  filtersFilter,
  disabled = false,
  disableAttr = false,
  withLabel = true,
  withRefresh = false,
  isFixed = false,
}:IUniversalFilterProps) => {
  const forceUpdate = useForceUpdate()
  const { isOpenFixedHeader, isFixedHeader } = useTypedSelector(
    (state) => state.dashboard
  )
  const { filters, filterParent, filterSelectorVisible } = useTypedSelector(
    (state) => state.filter
  )
  const locationState = useLocation()
  const isDashboard = locationState.pathname.includes("dashboards")
  const {
    setMetaCompare,
    setFilterParent,
    setFilters,
    setCurrentSegment,
    setAllDataFetching,
    setCurrentFunnel,
  } = useActions()
  const [ expandedKeys, setExpandedKeys ] = useState<string[]>([])
  const [ searchValue, setSearchValue ] = useState("")
  const [ autoExpandParent, setAutoExpandParent ] = useState(true)
  const [ treeData, setTreeData ] = useState<any>([])
  const [ btnDisabled, setBtnDisabled ] = useState(true)

  useEffect(() => {
    let data_ = data
    if (location.pathname === "/users/ad-reports") {
      data_ = updateFilters()
    }
    const filteredData = filtersFilter
      ? data_.filter((item: any) => filtersFilter.includes(item.name))
      : data_
    const _data = prepareData(
      filteredData,
      searchValue,
      filters,
      disableAttr,
      "",
      "",
      "",
      "",
      [ "" ],
      "",
      filterParent
    )
    const url = new URL(window.location.href)
    const currentParams = url.searchParams.get("params")
    const paramsObject = currentParams ? JSON.parse(currentParams) : {}
    if (paramsObject?.filters) {
      let filtersFromParams: any[] = []
      paramsObject.filters.forEach(
        (filt: { id: string; calendar: { type: string } }) => {
          let extraFields = {}
          if (calendarTypes.includes(filt?.calendar?.type)) {
            const { start, end } = getStartEndType({
              calendar: { type: filt?.calendar?.type },
            })
            extraFields = { ...extraFields, start, end }
          }
          const filterFromParam = {
            ...findObjectById(_data, filt.id),
            ...filt,
            ...extraFields,
          }
          //if(filterFromParam.)
          filtersFromParams.push(filterFromParam)
        }
      )
      setFilters(filtersFromParams)
      console.log(_data, filtersFromParams)
    }
  }, [])
  useEffect(() => {
    let data_ = data
    if (location.pathname === "/users/ad-reports") {
      data_ = updateFilters()
    }
    const filteredData = filtersFilter
      ? data_.filter((item: any) => filtersFilter.includes(item.name))
      : data_
    const _data = prepareData(
      filteredData,
      searchValue,
      filters,
      disableAttr,
      "",
      "",
      "",
      "",
      [ "" ],
      "",
      filterParent
    )

    if (_data.length === 1) {
      setAutoExpandParent(true)
      setExpandedKeys([ "0-0" ])
    }

    setTreeData(_data)
    forceUpdate()
  }, [
    data,
    searchValue,
    filters,
    filters.length,
    isCompare,
    filterParent,
    location.pathname,
    filtersFilter,
  ])

  useEffect(() => {
    if (!searchValue) {
      let data_ = data
      if (location.pathname === "/users/ad-reports") {
        data_ = updateFilters()
      }
      setExpandedKeys([])
      setAutoExpandParent(false)
      const filteredData = filtersFilter
        ? data_.filter((item: any) => filtersFilter.includes(item.name))
        : data_
      const _data = prepareData(
        filteredData,
        searchValue,
        filters,
        disableAttr,
        "",
        "",
        "",
        "",
        [ "" ],
        "",
        filterParent
      )

      setTreeData(_data)
    }

    forceUpdate()
  }, [ searchValue ])

  useEffect(() => {
    forceUpdate()
  }, [ treeData ])

  useEffect(() => {
    if (!isCompare) {
      setMetaCompare({ value: false })
    }
  }, [ isCompare ])

  const onExpand = (_expandedKeys: any) => {
    setExpandedKeys(_expandedKeys)
    setAutoExpandParent(false)
  }

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (value !== "") {
      const reg = new RegExp(value, "i")
      const _treeData = treeData

      let _expandedKeys: any = listKeys
        .map((item: any) => {
          if (item.name.search(reg) > -1) {
            return getParentKey(item.id, _treeData)
          }

          return null
        })
        .filter(
          (item: any, i: number, self: any) => item && self.indexOf(item) === i
        )

      setExpandedKeys(_expandedKeys)
      setSearchValue(value)
      setAutoExpandParent(true)
    } else {
      setExpandedKeys([])
      setSearchValue("")
      setAutoExpandParent(false)
    }
  }
  const onClearFilters = () => {
    setCurrentSegment(null)
    setCurrentFunnel([])
    setFilters([])
    setFilterParent([])
    setBtnDisabled(true)
    setAllDataFetching({ value: true })
  }

  const isAutoRKTab =
    locationState.pathname === PagesPathnames.autoPlacement &&
    (Number(new URLSearchParams(location.search).get("tab")) === 1 ||
      !new URLSearchParams(location.search).get("tab"))

  const isAutoAdTab =
    locationState.pathname === PagesPathnames.autoPlacement &&
    Number(new URLSearchParams(location.search).get("tab")) === 2

  const isManualAdTab =
    locationState.pathname === PagesPathnames.manualPlacement &&
    Number(new URLSearchParams(location.search).get("tab")) === 1

  return (
    <div
      className={`params__filter ${isAutoRKTab ? "filter-auto-rk-tab" : ""}${
        isAutoAdTab ? "filter-auto-ad-tab" : ""
      }${isManualAdTab ? "filter-manual-ad-tab" : ""}`}
    >
      <div>
        {withSegment ? (
          <Segments disabled={disabled} setBtnDisabled={setBtnDisabled} />
        ) : null}
      </div>
      {withRefresh && <RefreshButton />}
      {filters.length > 0 && (
        <Button
          style={{ marginRight: 5 }}
          onClick={() => onClearFilters()}
          icon={<CloseOutlined />}
          title="Очистить"
        />
      )}
      <div
        style={{
          display: "inline",
          marginLeft: withSegment ? 10 : 0,
          height: 34,
          ...(isDashboard && { width: "89%" }),
        }}
      >
        {withLabel && (
          <Typography.Text type="secondary">
            Условия фильтра:&nbsp;
          </Typography.Text>
        )}
        {filters.length === 0 || (isFixed && !isOpenFixedHeader)
          ? null
          : filters.map((item: any, index: number) => {
              if (item.type === "datetimerange") {
                if (!item.start && !item.end) {
                  return null
                }
              }

              let prefix = "и"
              let secondPrefix: null | string = null
              const prevIndex = index === 0 ? 0 : index - 1
              if (item.id === filters[prevIndex].id) {
                prefix = "или"
              }

              if (item.id.includes("not_")) {
                secondPrefix = "не"
              }

              return (
                <React.Fragment key={index}>
                  {index !== 0 && (
                    <Typography.Text type="secondary">
                      &nbsp;{prefix}&nbsp;
                    </Typography.Text>
                  )}
                  {secondPrefix ? (
                    <b style={{ fontWeight: 600, color: "#ff0000" }}>
                      {secondPrefix}&nbsp;
                    </b>
                  ) : (
                    ""
                  )}
                  {
                    <FilterItemSelected
                      arrayIndex={index}
                      id={item.id}
                      itemData={item}
                      full_name={item.full_name}
                      {...item}
                    />
                  }
                </React.Fragment>
              )
            })}
        &nbsp;
        <Popover
          content={
            <Tree.DirectoryTree
              blockNode
              switcherIcon={<DownOutlined />}
              showIcon={false}
              selectable={false}
              treeData={
                disableAttr
                  ? treeData.filter(
                      (item: any) =>
                        item.name !== "Реклама" && item.name !== "Регистрации"
                    )
                  : treeData
              }
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              key={"name"}
            />
          }
          title={
            <Input
              onChange={(e) => onSearch(e)}
              placeholder="Найти условие"
              allowClear
            />
          }
          trigger="click"
          placement="bottom"
          overlayClassName="filter-popover"
        >
          <Button icon={<PlusOutlined />} type="primary" shape="circle" />
        </Popover>
        {
          <RequestButton
            btnDisabled={btnDisabled}
            setBtnDisabled={setBtnDisabled}
          />
        }
      </div>
    </div>
  )
}

export default DashboardFilter
