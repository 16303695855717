import React, { Dispatch, FC, useEffect, useMemo, useState } from "react";

import {
  CloseCircleFilled,
  CloseOutlined,
  DownOutlined,
  EditFilled,
  FilterFilled,
  LinkOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Tooltip,
} from "antd";
import { useTypedSelector } from "../../../../../../shared/common/hooks/useTypedSelector";
import usePermission from "../../../../../../shared/common/hooks/usePermission";
import { useActions } from "../../../../../../shared/common/hooks/useActions";
import { ISegment } from "../../../../../../app/providers/redux/slices/reports/params/filter/types";
import { unApi } from "../../../../../../shared/common/api/endpoints/reports/unApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../helpers/utils/ui";
import { TAppDispatch } from "../../../../../../app/providers/redux/store";
import {
  funnelDescription,
  segmentDescription,
} from "../../../../../../shared/common/constants/tooltips";
import { declOfNum } from "../../../../../../helpers/utils/reports";

interface ISegmentsProps {
  disabled: boolean;
  setBtnDisabled: (params: boolean) => void;
}

const Segments: FC<ISegmentsProps> = ({ disabled, setBtnDisabled }) => {
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const { segments, currentSegment, filters, funnels, currentFunnel } =
    useTypedSelector((state) => state.filter);
  const { isFixedHeader, isOpenFixedHeader } = useTypedSelector(
    (state) => state.dashboard
  );
  const { currentReport } = useTypedSelector((state) => state.reports);
  const checkPermission = usePermission();
  const {
    setCurrentSegment,
    setFilters,
    deleteSegment,
    createSegment,
    updateSegment,
    updateFunnel,
    deleteFunnel,
    getSegments,
    getFunnels,
    createFunnel,
    setAllDataFetching,
    setMetaFilterUpdate,
    setCurrentFunnel,
    setFilterParent,
  } = useActions();

  const filtersLength = Object.keys(filters).length;

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isPopoverFunnelVisible, setIsPopoverFunnelVisible] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [segmentName, setSegmentName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFunnelModalVisible, setIsFunnelModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isEditFunnelModalVisible, setIsEditFunnelModalVisible] =
    useState(false);
  const [editSegmentId, setEditSegmentId] = useState("");
  const [saveDisable, setSaveDisable] = useState(true);
  const [checkedFunnelsIds, setCheckedFunnelsIds] = useState([]);

  useEffect(() => {
    if (selectedSchool?.id) {
      getSegments(selectedSchool.id);
      getFunnels(selectedSchool.id);
    }
  }, []);

  useEffect(() => {
    if (!disabled) {
      setSaveDisable(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (currentSegment) {
      activateSegment(currentSegment.id);
    }
  }, [currentSegment]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (Array.isArray(currentFunnel) && currentFunnel.length > 0) {
        let filters: any[] = [];
        currentFunnel.forEach((el) => {
          filters.push(...el.values.filters);
        });
        setFilters(filters);
      }
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentFunnel]);

  useEffect(() => {
    const segmentParam = new URLSearchParams(location.search).get("filter_id");

    if (segmentParam) {
      setSegmentFromUrl(segmentParam);
    }
  }, []);

  const compareStar = useMemo(() => {
    if (!filters || !currentSegment) return false;
    if (Object.keys(currentSegment).length === 0) return false;
    if (currentSegment.id === undefined) return false;
    if (currentSegment.values.filters.length === 0) return false;
    if (filters.length !== currentSegment.values.filters.length) return true;

    return filters.some(
      (value) =>
        JSON.stringify(
          currentSegment.values.filters.find((f: any) => f.id === value.id)
        ) !== JSON.stringify(value)
    );
  }, [currentSegment, filters]);

  const activateSegment = (id: string) => {
    const segment = segments.find((seg) => seg.id === id);
    if (segment) {
      setCurrentSegment(segment);
      const filtersFromReport = Array.isArray(currentReport?.values?.filters)
        ? currentReport?.values?.filters
        : [];
      const filtersValue = currentReport?.id
        ? [...currentReport.values.segment.values.filters, ...filtersFromReport]
        : segment.values.filters;
      setFilters(filtersValue);

      setIsPopoverVisible(false);
      setAllDataFetching({ value: true });
    }
  };
  const activateFunnel = (id: string, checked: boolean) => {
    const funnel = funnels.find((seg) => seg.id === id);
    if (funnel) {
      if (checked) {
        setCurrentFunnel([...(currentFunnel ?? []), funnel]);
        currentFunnel?.length === 0
          ? setFilters([...funnel.values.filters])
          : setFilters([...(filters ?? []), ...funnel.values.filters]);
      } else {
        const serializedFunnelFilters = new Set(
          funnel.values.filters.map((filter: any) => JSON.stringify(filter))
        );
        const updatedFilters = filters.filter(
          (filter) => !serializedFunnelFilters.has(JSON.stringify(filter))
        );
        const updatedCurrentFunnel = currentFunnel?.filter(
          (seg) => seg.id !== funnel.id
        );
        setFilters(updatedFilters);
        setCurrentFunnel(updatedCurrentFunnel || []);
      }

      setIsPopoverFunnelVisible(false);
      setAllDataFetching({ value: true });
    }
  };

  const manualActivateSegment = (segment: ISegment) => {
    setCurrentSegment(segment);
    setFilters(segment.values.filters);
    setAllDataFetching({ value: true });
  };

  const setSegmentFromUrl = (segmentHash: string) => {
    unApi
      .getSegmentDataFromHash(segmentHash, location.pathname)
      .then((response) => {
        if (response.status === 200) {
          const segmentData = response.data[0];

          if (segmentData) {
            const segment = {
              id: segmentData.id,
              hash: segmentData.hash,
              values: segmentData.values,
            };

            manualActivateSegment(segment);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        showErrorMessage("Ошибка при получении сегмента.");
      });
  };

  const save = () => {
    if (currentSegment && selectedSchool?.id) {
      const values = { ...currentSegment.values, filters };
      const segment = {
        id: currentSegment.id,
        hash: currentSegment.hash,
        values,
      };

      updateSegment(
        selectedSchool.id,
        segment.id,
        segment.hash,
        segment.values
      );
    }
  };
  const saveFunnel = () => {
    if (currentFunnel && selectedSchool?.id) {
      const values = { ...currentFunnel[0].values, filters };
      const segment = {
        id: currentFunnel[0].id,
        hash: currentFunnel[0].hash,
        values,
      };

      updateFunnel(selectedSchool.id, segment.id, segment.hash, segment.values);
    }
  };

  const saveAs = (
    createFn: (
      schoolId: string | number,
      values: any
    ) => (dispatch: TAppDispatch) => void,
    visibilityFn: Dispatch<boolean>
  ) => {
    if (selectedSchool?.id) {
      const values = {
        name: segmentName,
        filters,
      };
      createFn(selectedSchool.id, values);
      visibilityFn(false);
      setSegmentName("");
    }
  };

  const edit = () => {
    let data = segments.find((seg) => seg.id === editSegmentId);

    if (data && selectedSchool?.id) {
      const newData = {
        ...data,
        values: { ...data.values, name: segmentName },
      };
      updateSegment(selectedSchool.id, data.id, data.hash, newData.values);
      setIsModalEditVisible(false);
      setSegmentName("");
    }
  };
  const editFunnel = () => {
    let data = funnels.find((seg) => seg.id === editSegmentId);

    if (data && selectedSchool?.id) {
      const newData = {
        ...data,
        values: { ...data.values, name: segmentName },
      };
      updateFunnel(selectedSchool.id, data.id, data.hash, newData.values);
      setIsEditFunnelModalVisible(false);
      setSegmentName("");
    }
  };

  const onEditSegment = (id: string) => {
    const segment = segments.find((seg) => seg.id === id);

    if (segment) {
      setSegmentName(segment.values.name);
      setEditSegmentId(id);
      setIsModalEditVisible(true);
      setIsPopoverVisible(false);
    }
  };
  const onEditFunnel = (id: string) => {
    const funnel = funnels.find((seg) => seg.id === id);

    if (funnel) {
      setSegmentName(funnel.values.name);
      setEditSegmentId(id);
      setIsEditFunnelModalVisible(true);
      setIsPopoverFunnelVisible(false);
    }
  };
  const onClearFilters = (id: string) => {
    setCurrentSegment(null);
    setIsPopoverVisible(false);
    setFilters([]);
    setFilterParent([]);
    setBtnDisabled(true);
    setAllDataFetching({ value: true });
    if (currentSegment?.id !== undefined) {
      if (`${currentSegment.id}` === `${id}`) {
        setFilters([]);
        setFilterParent([]);
      }
    }
  };
  const onRemoveSegment = (id: string) => {
    deleteSegment(id);
    setCurrentSegment(null);
    setIsPopoverVisible(false);

    if (currentSegment?.id !== undefined) {
      if (`${currentSegment.id}` === `${id}`) {
        setFilters([]);
        setFilterParent([]);
      }
    }
  };
  const onRemoveFunnel = (id: string) => {
    deleteFunnel(id);
    setCurrentFunnel([]);
    setIsPopoverFunnelVisible(false);

    if (currentSegment?.id !== undefined) {
      if (`${currentSegment.id}` === `${id}`) {
        setFilters([]);
        setFilterParent([]);
      }
    }
  };

  const copySegment = (hash: string) => {
    const segmentLink = `${process.env.REACT_APP_BASE_URL}${location.pathname}?filter_id=${hash}`;
    navigator.clipboard
      .writeText(segmentLink)
      .then(() => {
        showSuccessMessage("Ссылка скопирована");
      })
      .catch(() => {
        showErrorMessage("Ошибка при копировании ссылки сегмента");
      });
  };

  const listSegment = () => (
    <div style={{ width: 300 }}>
      <Input
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
        placeholder="Найти сегмент"
        allowClear
      />
      <div
        style={{
          maxHeight: 300,
          overflowY: "auto",
          marginTop: 7,
          marginLeft: "-16px",
          marginRight: "-16px",
        }}
      >
        {segments
          .filter((seg) =>
            seg.values.name.toLowerCase().includes(searchVal.toLowerCase())
          )
          .map((seg) => (
            <div className="segment-list-item" key={seg.id}>
              <div
                onClick={() => activateSegment(seg.id)}
                className="segment-list-name"
                style={{ width: "100%" }}
              >
                {seg.values.name}
              </div>
              <div
                className="segment-list-item-controls"
                style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
              >
                <Button
                  onClick={() => copySegment(seg.hash)}
                  icon={<LinkOutlined />}
                  size="small"
                  title="Копировать"
                  type="link"
                  style={{ color: "#9a9a9a" }}
                />
                <Button
                  onClick={() => onEditSegment(seg.id)}
                  icon={<EditFilled />}
                  size="small"
                  title="Редактировать сегмент"
                  type="text"
                  style={{ color: "#9a9a9a" }}
                />
                <Popconfirm
                  title="Подтвердите удаление"
                  onConfirm={() => onRemoveSegment(seg.id)}
                  okText="Удалить"
                  cancelText="Отмена"
                >
                  <Button
                    icon={<CloseCircleFilled />}
                    size="small"
                    title="Удалить сегмент"
                    type="text"
                    style={{ color: "#9a9a9a" }}
                  />
                </Popconfirm>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
  const listfunnel = () => (
    <div style={{ width: 300 }}>
      <Input
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
        placeholder="Найти воронку продаж"
        allowClear
      />
      <div
        style={{
          maxHeight: 300,
          overflowY: "auto",
          marginTop: 7,
          marginLeft: "-16px",
          marginRight: "-16px",
        }}
      >
        {funnels
          .filter((seg) =>
            seg.values.name.toLowerCase().includes(searchVal.toLowerCase())
          )
          .map((seg) => (
            <div className="segment-list-item" key={seg.id}>
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={
                    Array.isArray(currentFunnel) &&
                    Boolean(currentFunnel.find((el) => el.id === seg.id))
                  }
                  onChange={(e) => activateFunnel(seg.id, e.target.checked)}
                  style={{ marginRight: "8px" }}
                >
                  {seg.values.name}
                </Checkbox>
                {checkPermission(["admin", "user:settings"]) && (
                  <div
                    className="segment-list-item-controls"
                    style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
                  >
                    <Button
                      onClick={() => onEditFunnel(seg.id)}
                      icon={<EditFilled />}
                      size="small"
                      title="Редактировать воронку"
                      type="text"
                      style={{ color: "#9a9a9a" }}
                    />
                    <Popconfirm
                      title="Подтвердите удаление"
                      onConfirm={() => onRemoveFunnel(seg.id)}
                      okText="Удалить"
                      cancelText="Отмена"
                    >
                      <Button
                        icon={<CloseCircleFilled />}
                        size="small"
                        title="Удалить воронку"
                        type="text"
                        style={{ color: "#9a9a9a" }}
                      />
                    </Popconfirm>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
  const getCurrentFunnelsTitles = () => {
    if (!Array.isArray(currentFunnel)) {
      return "";
    }
    let titles = currentFunnel
      .map((el) => {
        return el.values.name;
      })
      .join(" | ");
    console.log(titles.length);
    if (titles.length >= 20 && isFixedHeader) {
      titles = titles.substring(0, 17) + "...";
    }
    return titles;
  };
  const allowedPathnames = [
    "/users/universal",
    "/users/order-reports",
    "/users/ad-reports",
    "/users/payment-reports",
    "/users/webinar-reports",
    "/users/cohort",
    "/dashboards",
  ];
  return (
    <div className="segment" style={{ display: "flex", alignItems: "center" }}>
      {allowedPathnames.includes(location.pathname) && (
        <>
          <Popover
            content={listfunnel}
            open={isPopoverFunnelVisible}
            onOpenChange={(value) => {
              if (funnels.length > 0) {
                setIsPopoverFunnelVisible(value);
              } else {
                setIsPopoverFunnelVisible(false);
              }
            }}
            trigger="click"
            placement="bottomLeft"
            showArrow={false}
            overlayStyle={{ paddingTop: 0 }}
          >
            <Button icon={<FilterFilled />} disabled={disabled}>
              Воронка продаж:&nbsp;
              {Object.keys(currentFunnel ? currentFunnel : {}).length === 0 ? (
                <span>Не выбрана</span>
              ) : (
                <span>
                  {getCurrentFunnelsTitles() || "-"} ({filtersLength}){" "}
                  {compareStar && <b>*</b>}
                </span>
              )}
              &nbsp;
              {funnels.length > 0 && <DownOutlined />}
            </Button>
          </Popover>
          {filtersLength > 0 && checkPermission(["admin", "user:settings"]) && (
            <span style={{ marginLeft: "2px", marginTop: "1px" }}>
              {Array.isArray(currentFunnel) && currentFunnel.length === 1 ? (
                <Dropdown
                  overlay={
                    <Menu>
                      {saveDisable ? null : (
                        <Menu.Item onClick={saveFunnel}>Сохранить</Menu.Item>
                      )}
                      <Menu.Item onClick={() => setIsFunnelModalVisible(true)}>
                        Сохранить как
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button
                    style={{ marginTop: "-1px" }}
                    icon={<SaveOutlined />}
                  />
                </Dropdown>
              ) : (
                <Button
                  style={{ marginTop: "-1px" }}
                  onClick={() => setIsFunnelModalVisible(true)}
                  icon={<SaveOutlined />}
                />
              )}
            </span>
          )}
          {!isFixedHeader && (
            <span style={{ marginLeft: 6, marginRight: 10, marginTop: 3 }}>
              <Tooltip
                placement="bottom"
                title={
                  <div
                    className="page-title-tooltip-text"
                    dangerouslySetInnerHTML={{ __html: funnelDescription }}
                  ></div>
                }
                color="#fff"
                zIndex={9999}
                trigger="click"
                overlayClassName="page-title-tooltip"
              >
                <QuestionCircleOutlined
                  style={{ color: "#ccc", fontSize: 14 }}
                />
              </Tooltip>
            </span>
          )}
        </>
      )}
      {(!isFixedHeader || isOpenFixedHeader) && (
        <Popover
          content={listSegment}
          open={isPopoverVisible}
          onOpenChange={(value) => {
            if (segments.length > 0) {
              setIsPopoverVisible(value);
            } else {
              setIsPopoverVisible(false);
            }
          }}
          trigger="click"
          placement="bottomLeft"
          showArrow={false}
          overlayStyle={{ paddingTop: 0 }}
        >
          <Button icon={<PieChartOutlined />} disabled={disabled}>
            Сегмент:&nbsp;
            {Object.keys(currentSegment ? currentSegment : {}).length === 0 ? (
              <span>
                {filtersLength}{" "}
                {declOfNum(filtersLength, ["условие", "условия", "условий"])}
              </span>
            ) : (
              <span>
                {currentSegment?.values.name || "-"} ({filtersLength}){" "}
                {compareStar && <b>*</b>}
              </span>
            )}
            &nbsp;
            {segments.length > 0 && <DownOutlined />}
          </Button>
        </Popover>
      )}

      {filtersLength > 0 && (!isFixedHeader || isOpenFixedHeader) && (
        <span style={{ marginLeft: "2px", marginTop: 1 }}>
          {currentSegment && Object.keys(currentSegment).length > 0 ? (
            <Dropdown
              overlay={
                <Menu>
                  {saveDisable ? null : (
                    <Menu.Item onClick={save}>Сохранить</Menu.Item>
                  )}
                  <Menu.Item onClick={() => setIsModalVisible(true)}>
                    Сохранить как
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button style={{ marginTop: "-1px" }} icon={<SaveOutlined />} />
            </Dropdown>
          ) : (
            <Button
              style={{ marginTop: "-1px" }}
              onClick={() => setIsModalVisible(true)}
              icon={<SaveOutlined />}
            />
          )}
        </span>
      )}
      {(!isFixedHeader || isOpenFixedHeader) && (
        <span style={{ marginLeft: 6, marginRight: 10, marginTop: 3 }}>
          <Tooltip
            placement="bottom"
            title={
              <div
                className="page-title-tooltip-text"
                dangerouslySetInnerHTML={{ __html: segmentDescription }}
              ></div>
            }
            color="#fff"
            zIndex={9999}
            trigger="click"
            overlayClassName="page-title-tooltip"
          >
            <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
          </Tooltip>
        </span>
      )}

      <Modal
        title="Сохранить сегмент как"
        open={isModalVisible}
        onOk={() =>
          segmentName
            ? saveAs(createSegment, setIsModalVisible)
            : showErrorMessage("Введите название сегмента")
        }
        onCancel={() => setIsModalVisible(false)}
        okText="Сохранить"
      >
        <Input
          value={segmentName}
          onChange={(e) => setSegmentName(e.target.value)}
          size="large"
          placeholder="Введите название сегмента"
          allowClear
        />
      </Modal>
      <Modal
        title="Сохранить воронку продаж, как..."
        open={isFunnelModalVisible}
        onOk={() =>
          segmentName
            ? saveAs(createFunnel, setIsFunnelModalVisible)
            : showErrorMessage("Введите название воронки продажи")
        }
        onCancel={() => setIsFunnelModalVisible(false)}
        okText="Сохранить"
      >
        <Input
          value={segmentName}
          onChange={(e) => setSegmentName(e.target.value)}
          size="large"
          placeholder="Введите название воронки"
          allowClear
        />
      </Modal>

      <Modal
        title="Редактировать название сегмента"
        open={isModalEditVisible}
        onOk={edit}
        onCancel={() => setIsModalEditVisible(false)}
        okText="Сохранить"
      >
        <Input
          value={segmentName}
          onChange={(e) => setSegmentName(e.target.value)}
          size="large"
          placeholder="Введите название сегмента"
          allowClear
        />
      </Modal>
      <Modal
        title="Редактировать название воронки продаж"
        open={isEditFunnelModalVisible}
        onOk={editFunnel}
        onCancel={() => setIsEditFunnelModalVisible(false)}
        okText="Сохранить"
      >
        <Input
          value={segmentName}
          onChange={(e) => setSegmentName(e.target.value)}
          size="large"
          placeholder="Введите название воронки продаж"
          allowClear
        />
      </Modal>
    </div>
  );
};

export default Segments;
