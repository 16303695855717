// Группировки в графике
export const groups = [
  {
    groupName: "reg",
    metrics: [
      { name: "regs_count", label: "Регистрации" },
      { name: "users_count", label: "Пользователи" },
    ],
  },
  {
    groupName: "conv",
    metrics: [
      {
        name: "orders_to_forders_converse",
        label: "CR заказа в завершенный заказ",
      },
      {
        name: "creator_to_buyer_converse",
        label: "CR из созд. заказа в покупателя",
      },
    ],
  },
  {
    groupName: "orders",
    metrics: [
      { name: "orders_count", label: "Заказы" },
      { name: "forders_count", label: "Заказы [Завершён]" },
      { name: "payments_count", label: "Платежи" },
      { name: "orders_creators_count", label: "Создатели заказов" },
      { name: "buyers_count", label: "Покупатели" },
    ],
  },
  {
    groupName: "sum",
    metrics: [
      { name: "payments_sum", label: "Сумма платежей" },
      { name: "orders_sum", label: "Сумма заказов" },
      { name: "forders_sum", label: "Сумма заказов [Завершён]" },
    ],
  },
  {
    groupName: "avg",
    metrics: [
      { name: "orders_sum_avg", label: "Ср. сумма заказа" },
      { name: "payments_sum_avg", label: "Средний чек платежа" },
    ],
  },
];

/**
 Для добавления в группировку информационной иконки требуется добавить
 в объект группировки парметр description
 @param {string} `description`
 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Дата", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */

export const groupingsData = [
  {
    name: "Реклама",
    description: "Группировка регистраций по рекламным расходам.",
    children: [
      {
        name: "Дата рекламы",
        children: [
          { name: "День рекламы", dataKey: "ads_date" },
          { name: "Неделя рекламы", dataKey: "ads_date_w" },
          { name: "День недели рекламы", dataKey: "ads_date_dw" },
          { name: "Месяц рекламы", dataKey: "ads_date_m" },
          { name: "Квартал рекламы", dataKey: "ads_date_q" },
          { name: "Год рекламы", dataKey: "ads_date_y" },
        ],
      },
      { name: "Источник рекламы", dataKey: "ads_source" },
      { name: "Рекламные кабинеты", dataKey: "ads_cabinet" },
      { name: "Название кампаний", dataKey: "ads_campaign" },
      { name: "Название группы объявлений", dataKey: "ads_adset" },
      { name: "Название объявлений", dataKey: "ads_adv" },
      { name: "ID кампаний", dataKey: "ads_campaign_id" },
      { name: "ID группы объявлений", dataKey: "ads_adset_id" },
      { name: "ID объявления", dataKey: "ads_adv_id" },
      { name: "URL ссылка", dataKey: "url_link" },
      { name: "Preview", dataKey: "preview" },
      {
        name: "UTM-метки link",
        description: "Группировка рекламных ссылок в объявлении по UTM меткам.",
        children: [
          { name: "utm_source link", dataKey: "ads_utm_source" },
          { name: "utm_medium link", dataKey: "ads_utm_medium" },
          { name: "utm_campaign link", dataKey: "ads_utm_campaign" },
          { name: "utm_content link", dataKey: "ads_utm_content" },
          { name: "utm_term link", dataKey: "ads_utm_term" },
          { name: "utm_group link", dataKey: "ads_utm_group" },
        ],
      },
      {
        name: "Теги объявлений",
        description:
          "Группировка объявлений по словам из названия рекламного объявления.",
        dataKey: "ads_tag",
      },
    ],
  },
  {
    name: "Регистрации",
    description:
      "Группировка данных в таблице по дате регистрации. Можно выбрать разную детализацию.",
    children: [
      {
        name: "Дата регистрации",
        children: [
          {
            name: "Дни регистрации",
            description:
              "Регистрации сгруппированы по каждому дню регистрации.",
            dataKey: "reg_date",
          },
          {
            name: "Недели регистрации",
            description: "Регистрации сгруппированы по неделям, по 7 дней.",
            dataKey: "reg_date_w",
          },
          {
            name: "Дни недели регистрации",
            description:
              "Регистрации сгруппированы по дням недели (понедельник, вторник, среда, четверг, пятница, суббота, воскресенье).",
            dataKey: "reg_date_dw",
          },
          {
            name: "Месяца регистрации",
            description: "Регистрации сгруппированы по месяцам.",
            dataKey: "reg_date_m",
          },
          {
            name: "Кварталы регистрации",
            description: "Регистрации сгруппированы по кварталам, по 3 месяца.",
            dataKey: "reg_date_q",
          },
          {
            name: "Года регистрации",
            description: "Регистрации сгруппированы по годам.",
            dataKey: "reg_date_y",
          },
        ],
      },
      {
        name: "UTM-метка регистрации",
        description: "Группировка данных по UTM-метке регистрации.",
        children: [
          { name: "utm_source регистрации", dataKey: "reg_utm_source" },
          { name: "utm_medium регистрации", dataKey: "reg_utm_medium" },
          { name: "utm_campaign регистрации", dataKey: "reg_utm_campaign" },
          { name: "utm_content регистрации", dataKey: "reg_utm_content" },
          { name: "utm_term регистрации", dataKey: "reg_utm_term" },
          { name: "utm_group регистрации", dataKey: "reg_utm_group" },
        ],
      },
      {
        name: "Доп. поля регистрации",
        description: "Группировка доп. полей регистрации",
        children: [],
        checkable: false,
      },
      {
        name: "URL-адрес регистрации",
        description:
          "Группировка данных по URL адресу регистрации. Данная группировка работает только с типом регистрации: SCAN. Будет сгруппировано заполнение формы по URL адресу.",
        dataKey: "reg_url",
      },
      {
        name: "Домен регистрации",
        description:
          "Группировка данных по домену, где были заполнены формы регистрации. Данная группировка работает только с типом регистрации: SCAN. ",
        dataKey: "reg_domain",
      },
      {
        name: "Название регистрации",
        description:
          "Группировка регистраций по названию регистраций. <br/>" +
          "Если выбран тип регистрации: SCAN, то название регистрации - название страницы, на которой была заполнена форма. <br/>" +
          "Если выбран тип регистрации: Нулевые заказы GС, то названием регистрации будет являться название предложения в нулевом заказе GetCourse.",
        dataKey: "reg_title",
      },
      { name: "Тег регистрации", description: "", dataKey: "reg_tag" },
      {
        name: "Номер регистрации",
        description:
          "Для типа регистрации: нулевые заказы GC это вывод номера нулевого заказа.",
        dataKey: "reg_number",
      },
      {
        name: "Технология регистрации",
        description:
          "Группировка данных по характеристикам устройств, с которых были сделаны регистрации. Данная группировка работает только с типом регистрации: SCAN. ",
        children: [
          {
            name: "Тип устройства регистрации",
            description:
              "Группировка данных по типу устройств, с которых были сделаны регистрации (компьютер, планшет или телефон). Данная группировка работает только с типом регистрации: SCAN.",
            dataKey: "reg_device_type",
          },
          {
            name: "Модель устройства регистрации",
            description:
              "Группировка регистрации по модели устройств, с которых были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_device_model",
          },
          {
            name: "Операционная система регистрации",
            description:
              "Группировка регистрации по операционной системе устройств, с которых были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_device_os",
          },
          {
            name: "Версия операционной системы регистрации",
            description:
              "Группировка регистрации по версии операционных устройств, с которых были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_device_os_ver",
          },
          {
            name: "Браузер регистрации",
            description:
              "Группировка регистрации по названию браузера, через который были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_browser",
          },
          {
            name: "Версия браузера регистрации",
            description:
              "Группировка регистрации по версии браузера, через который были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_browser_ver",
          },
          { name: "Язык браузера регистрации", dataKey: "reg_browser_lang" },
        ],
      },
      {
        name: "Геолокация регистрации",
        description:
          "Группировка регистраций по геолокации на основе определяемого IP-адреса. Группировка работает только при типе регистрации: SCAN.",
        children: [
          {
            name: "Страна регистрации",
            description:
              "Группировка регистраций по странам, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_country",
          },
          {
            name: "Регион регистрации",
            description:
              "Группировка регистраций по регионам страны, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_region",
          },
          {
            name: "Город регистрации",
            description:
              "Группировка регистраций по городам, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_city",
          },
          {
            name: "Часовой пояс регистрации",
            description:
              "Группировка регистраций по часовым поясам, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_tz",
          },
        ],
      },
    ],
  },
  {
    name: "Пользователи",
    children: [
      {
        name: "E-mail",
        description:
          "Вывод данных по Email пользователю. Ссылка Email адреса пользователя будет активная, при переходе откроется карточка пользователя в GetCourse.",
        dataKey: "user_email",
      },
      {
        name: "id пользователя",
        description:
          "Вывод данных по ID пользователя. Ссылка ID пользователя будет активная, при переходе откроется карточка пользователя в GetCourse.",
        dataKey: "user_id",
      },
      {
        name: "id - Email - Имя - Телефон",
        description: "",
        dataKey: "user_id_ext",
      },
      {
        name: "Старые/новые",
        description:
          "Группировка пользователей, чтобы увидеть статистику по новым пользователям, у которых дата регистрации = дате попадания в базу школы. И по старым пользователям, у которых дата регистрации позже даты попадания в базу.",
        dataKey: "user_is_new",
      },
      {
        name: "Пол",
        description:
          "Группировка регистраций по полу. Данные собираются через дополнительные формы.",
        dataKey: "user_gender",
      },
      {
        name: "Возраст",
        description:
          "Группировка регистраций по возрасту. Данные собираются через дополнительные формы.",
        dataKey: "user_age",
      },
      {
        name: "Доп. поля пользователя",
        description: "Группировка доп. полей пользователя",
        children: [],
        checkable: false,
      },
    ],
  },
  {
    name: "Заказы",
    description:
      "Группировка регистраций пользователей по заказам. Данные группировки покажут сколько и какие заказы были созданы пользователями в разрезе по регистрациям.",
    children: [
      {
        name: "Дата заказа",
        description:
          "Группировка заказов пользователей по дате создания заказов.",
        children: [
          {
            name: "Дни заказов",
            description: "Группировка заказов по дням создания заказов.",
            dataKey: "order_date",
          },
          {
            name: "Недели заказов",
            description: "Группировка заказов по неделям создания заказов.",
            dataKey: "order_date_w",
          },
          {
            name: "Дни недели заказов",
            description: "Группировка заказов по дням недели создания заказов.",
            dataKey: "order_date_dw",
          },
          {
            name: "Месяца заказов",
            description: "Группировка заказов по месяцам создания заказов.",
            dataKey: "order_date_m",
          },
          {
            name: "Кварталы заказов",
            description: "Группировка заказов по кварталам создания заказов.",
            dataKey: "order_date_q",
          },
          {
            name: "Года заказов",
            description: "Группировка заказов по годам создания заказов.",
            dataKey: "order_date_y",
          },
        ],
      },
      {
        name: "Период от регистрации до создания заказа",
        description:
          "Группировка заказов пользователей по периоду от регистрации до создания заказа. Сможете увидеть через сколько дней или недель после регистрации пользователь создаёт заказ",
        children: [
          {
            name: "Дней от регистрации до заказа",
            description:
              "Группировка заказов по количеству дней прошедших от регистрации до создания заказа. Узнаете на какой день после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней<br>" +
              "<br>" +
              "В период 1 день попадут все заказы, у которых дата заказа = дате регистрации. <br>" +
              "<br>" +
              "В период 2 день попадут заказы, созданные на следующий день после регистрации.<br>",
            dataKey: "order_after_reg_d",
          },
          {
            name: "Недель от регистрации до заказа",
            description:
              "Группировка заказов по количеству недель прошедших от регистрации до создания заказа. Узнаете в какую неделю после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель<br>" +
              "<br>" +
              "В период 1 неделя попадут все заказы, созданные в течение 7 дней после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 по 7 марта. <br>" +
              "<br>" +
              "В период 2 неделя попадут все заказы, созданные в период с 8 по 14 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 8 по 14 марта.<br>",
            dataKey: "order_after_reg_w",
          },
          {
            name: "Месяцев от регистрации до заказа",
            description:
              "Группировка заказов по количеству месяцев прошедших от регистрации до создания заказа. Узнаете в какой месяц после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев<br>" +
              "<br>" +
              "В период 1 месяц попадут все заказы, созданные в течение 30 дней после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 по 30 марта. <br>" +
              "<br>" +
              "В период 2 месяца попадут все заказы, созданные в период с 31 по 60 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 31 марта по 29 апреля.<br>" +
              "<br>" +
              "Условно 1 месяц = 30 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 30 = число округляем до большего значения.",
            dataKey: "order_after_reg_m",
          },
          {
            name: "Кварталов от регистрации до заказа",
            description:
              "Группировка заказов по количеству кварталов прошедших от регистрации до создания заказа. Узнаете в какой квартал после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов<br>" +
              "<br>" +
              "В период 1 квартал попадут все заказы, созданные в течение 91 дня после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 марта по 30 мая. <br>" +
              "<br>" +
              "В период 2 квартал попадут все заказы, созданные в период с 92 по 182 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 31 мая по 29 августа.<br>" +
              "<br>" +
              "Условно 1 квартал = 91 день.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 91 = число округляем до большего значения.",
            dataKey: "order_after_reg_q",
          },
          {
            name: "Лет от регистрации до заказа",
            description:
              "Группировка заказов по количеству лет прошедших от регистрации до создания заказа. Узнаете в какой год после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет<br>" +
              "<br>" +
              "В период 1 год попадут все заказы, созданные в течение 365 дней после регистрации. <br>" +
              "<br>" +
              "В период 2 год попадут все заказы, созданные в период с 366 по 730 день после регистрации.<br>" +
              "<br>" +
              "Условно 1 год = 365 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 365 = число округляем до большего значения.",
            dataKey: "order_after_reg_y",
          },
        ],
      },
      {
        name: "Период от создания заказа до его оплаты",
        description:
          "Группировка завершенных заказов пользователей по периоду от создания заказа до его полной оплаты. Сможете увидеть за какой период времени после создания заказа, заказ полностью оплачивается. <br>" +
          "<br>" +
          "Формула расчёта: Дата и время последнего платежа заказа со статусом завершён - Дата и время создания заказа.",
        children: [
          {
            name: "Минут от создания до оплаты заказа",
            description:
              "Группировка заказов по минутным периодом от создания заказа до его полной оплаты. Узнаете сколько проходит минут от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 мин.<br>" +
              "2 мин.<br>" +
              "3 мин.<br>" +
              "4 мин.<br>" +
              "5 мин.<br>" +
              "6 мин.<br>" +
              "7 мин.<br>" +
              "8 мин.<br>" +
              "9 мин.<br>" +
              "10 мин.<br>" +
              "11 мин.<br>" +
              "12 мин.<br>" +
              "13 мин.<br>" +
              "14 мин.<br>" +
              "15 мин.<br>" +
              "> 15 мин.",
            dataKey: "pay_after_order_m",
          },
          {
            name: "Декаминут от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в декаминутах (по десять минут) от создания заказа до его полной оплаты. Узнаете сколько проходит декаминут от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "10 мин.<br>" +
              "20 мин.<br>" +
              "30 мин.<br>" +
              "40 мин.<br>" +
              "50 мин.<br>" +
              "60 мин.<br>" +
              "> 1 час.",
            dataKey: "pay_after_order_dm",
          },
          {
            name: "Часов от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в часах от создания заказа до его полной оплаты. Узнаете сколько проходит часов от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 ч.<br>" +
              "2 ч.<br>" +
              "3 ч.<br>" +
              "4 ч.<br>" +
              "5 ч.<br>" +
              "6 ч.<br>" +
              "7 ч.<br>" +
              "8 ч.<br>" +
              "9 ч.<br>" +
              "10 ч.<br>" +
              "11 ч.<br>" +
              "12 ч.<br>" +
              "13 ч.<br>" +
              "14 ч.<br>" +
              "15 ч.<br>" +
              "16 ч.<br>" +
              "17 ч.<br>" +
              "18 ч.<br>" +
              "19 ч.<br>" +
              "20 ч.<br>" +
              "21 ч.<br>" +
              "22 ч.<br>" +
              "23 ч.<br>" +
              "24 ч.<br>" +
              "> 24 ч.",
            dataKey: "pay_after_order_h",
          },
          {
            name: "Дней от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в днях от создания заказа до его полной оплаты. Узнаете сколько проходит дней от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней.",
            dataKey: "pay_after_order_d",
          },
          {
            name: "Недель от создания заказа до оплаты",
            description:
              "Группировка заказов по недельным периодам от создания заказа до его полной оплаты. Узнаете сколько проходит недель от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель.",
            dataKey: "pay_after_order_w",
          },
        ],
      },
      {
        name: "Доп. поля заказа",
        description: "Группировка доп. полей заказа",
        children: [],
        checkable: false,
      },
      {
        name: "Предложения",
        description:
          "Группировка данных по предложениям заказов, которые пользователи создали после регистрации.<br/>" +
          "<br/>" +
          "Эта группировка позволяет увидеть, на какие предложения, сколько заказов и оплат было создано людьми в разрезе по определённым регистрациям.",
        dataKey: "order_offers",
      },
      {
        name: "Номер заказа",
        description:
          "Данной группировкой можно вывести список номеров заказов GetCourse пользователями, которые сделали заказ.",
        dataKey: "order_number",
      },
      {
        name: "Тег предложения",
        description:
          "Группировка данных по тегам предложений заказов, которые пользователи создали после регистрации.<br>" +
          "<br>" +
          "Теги предложение получаем автоматически из выгрузки заказов.<br>" +
          "<br>" +
          "Эта группировка позволяет увидеть заказы по каждому тегу предложения.<br>" +
          "<br>" +
          "Заказы, у предложений которых не будет тегов предложений, будут сгруппированы в группу (Без тегов)<br>",
        dataKey: "order_tag",
      },
      {
        name: "Тег заказа",
        description:
          "Группировка заказов по тегам заказов, которые пользователи создали после регистрации.<br>" +
          "<br>" +
          "Теги заказов получаем автоматически из выгрузки заказов.<br>" +
          "<br>" +
          "Эта группировка позволяет увидеть заказы каждого тега заказа. <br>" +
          "<br>" +
          "Заказы, у которых нет тега заказа, будут сгруппированы в группу (Без тега).<br>",
        dataKey: "order_tag_3",
      },
      {
        name: "Есть заказ",
        description:
          "Группировка регистраций на 2 группы. В первую группу (Есть заказ) попадут регистрации, после которых пользователей создал заказа. Во вторую группу (Нет заказа) попадут регистрации, после которых пользователь НЕ сделал заказ. В фильтре можно выбрать предложение заказа, по которому нужна группировка.",
        dataKey: "has_orders",
      },
      {
        name: "Количество заказов",
        description:
          "Группировка регистраций пользователей на 9 групп по количеству созданных заказов одним пользователем после регистрации (0; 1; 2; 3; 4; 5; 6-10; 11-20; >20). То есть, в группу 6-10 попадут пользователи кто сделал от 6 до 10 заказов после регистрации.",
        dataKey: "has_orders_count",
      },
      {
        name: "Менеджер заказа",
        description:
          "Группировка данных по менеджерам заказов, которые пользователи создали после регистрации. Данные берутся из выгрузки GetCourse.<br/>" +
          "<br/>" +
          "Эта группировка позволяет увидеть статистику менеджеров в разрезе по определенным регистрациям.",
        dataKey: "order_manager",
      },
      {
        name: "Статус заказа",
        description:
          "Группировка данных по статусам заказов, которые пользователи создали после регистрации.<br/>" +
          "<br/>" +
          "Эта группировка позволяет увидеть, в каких статусах находятся заказы, созданные людьми в разрезе по определенным регистрациям.",
        dataKey: "order_status",
      },
      {
        name: "UTM-метка заказа",
        description:
          "Группировка заказов по UTM - меткам заказов. Данные берутся из выгрузки GetCourse.",
        children: [
          { name: "utm_source заказа", dataKey: "order_utm_source" },
          { name: "utm_medium заказа", dataKey: "order_utm_medium" },
          { name: "utm_campaign заказа", dataKey: "order_utm_campaign" },
          { name: "utm_content заказа", dataKey: "order_utm_content" },
          { name: "utm_term заказа", dataKey: "order_utm_term" },
          { name: "utm_group заказа", dataKey: "order_utm_group" },
        ],
      },
      {
        name: "Партнер заказа",
        description: "",
        children: [
          { name: "id партнера заказа", dataKey: "order_partner_id" },
          { name: "Email партнера заказа", dataKey: "order_partner_email" },
          { name: "Имя партнера заказа", dataKey: "order_partner_name" },
        ],
      },
    ],
  },
  {
    name: "Платежи",
    description: "Группировка платежей по разным параметрам.",
    children: [
      {
        name: "Дата платежа",
        description: "Группировка платежей по дате получения платежа.",
        children: [
          {
            name: "День платежа",
            description: "Группировка платежей по дням получения платежей.",
            dataKey: "payment_date",
          },
          {
            name: "Неделя платежа",
            description: "Группировка платежей по неделям получения платежей.",
            dataKey: "payment_date_w",
          },
          {
            name: "День недели платежа",
            description:
              "Группировка платежей по дням недели получения платежей.",
            dataKey: "payment_date_dw",
          },
          {
            name: "Месяц платежа",
            description: "Группировка платежей по месяцам получения платежей.",
            dataKey: "payment_date_m",
          },
          {
            name: "Квартал платежа",
            description:
              "Группировка платежей по кварталам получения платежей.",
            dataKey: "payment_date_q",
          },
          {
            name: "Год платежа",
            description: "Группировка платежей по годам получения платежей.",
            dataKey: "payment_date_y",
          },
        ],
      },
      {
        name: "id платежа",
        description: "Группировка платежей по ID платежа.",
        dataKey: "payment_id",
      },
      {
        name: "Период от регистрации до платежа",
        description:
          "Группировка платежей пользователей по периоду от регистрации до платежа. Сможете увидеть в какой день или неделю после регистрации происходит больше всего оплат",
        children: [
          {
            name: "Дней от регистрации до платежа",
            description:
              "Группировка платежей по количеству дней прошедших от регистрации до совершения платежа. Узнаете на какой день после регистрации больше всего совершается оплат.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней<br>" +
              "<br>" +
              "В период 1 день попадут все платежи, у которых дата платежа = дате регистрации.<br>" +
              "<br>" +
              "В период 2 день попадут платежи, совершённые на следующий день после регистрации.<br>",
            dataKey: "pay_after_reg_d",
          },
          {
            name: "Недель от регистрации до платежа",
            description:
              "Группировка платежей по количеству недель прошедших от регистрации до совершения платежа. Узнаете в какую неделю после регистрации больше всего совершается оплат.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель<br>" +
              "<br>" +
              "В период 1 неделя попадут все платежи, совершенные в течение 7 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 7 марта.<br>" +
              "<br>" +
              "В период 2 неделя попадут все платежи, совершенные в период с 8 по 14 день после регистрации. Если регистрация 1 марта, то попадут платежи созданные с 8 по 14 марта.<br>",
            dataKey: "pay_after_reg_w",
          },
          {
            name: "Месяцев от регистрации до платежа",
            description:
              "Группировка платежи по количеству месяцев прошедших от регистрации до совершения платежа. Узнаете в какой месяц после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев<br>" +
              "<br>" +
              "В период 1 месяц попадут все платежи, совершенные в течение 30 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 30 марта.<br>" +
              "<br>" +
              "В период 2 месяца попадут все платежи, совершенные в период с 31 по 60 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 марта по 29 апреля.<br>" +
              "<br>" +
              "Условно 1 месяц = 30 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 30 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_m",
          },
          {
            name: "Кварталов от регистрации до платежа",
            description:
              "Группировка платежей по количеству кварталов прошедших от регистрации до совершения платежа. Узнаете в какой квартал после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов<br>" +
              "<br>" +
              "В период 1 квартал попадут все платежи, совершенные в течение 91 дня после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 марта по 30 мая.<br>" +
              "<br>" +
              "В период 2 квартал попадут все платежи, совершенные в период с 92 по 182 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 мая по 29 августа.<br>" +
              "<br>" +
              "Условно 1 квартал = 91 день.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 91 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_q",
          },
          {
            name: "Лет от регистрации до платежа",
            description:
              "Группировка платежей по количеству лет прошедших от регистрации до совершения платежа. Узнаете в какой год после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет<br>" +
              "<br>" +
              "В период 1 год попадут все платежи, совершенные в течение 365 дней после регистрации.<br>" +
              "<br>" +
              "В период 2 год попадут все платежи, совершенные в период с 366 по 730 день после регистрации.<br>" +
              "<br>" +
              "Условно 1 год = 365 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 365 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_y",
          },
        ],
      },
      {
        name: "Тип платежа",
        description: "Группировка данных по типу платежной системы.",
        dataKey: "payment_type",
      },
      {
        name: "Статус платежа",
        description: "Группировка данных по статусу платежа.",
        dataKey: "payment_status",
      },
      {
        name: "Есть платеж",
        description:
          "Группировка регистраций на 2 группы: Есть платёж и Нет платежа. В группу Есть платёж попадут пользователи, которые сделали платёж после регистрации. В группу Нет платежа, соответственно, попадут пользователи без платежей после регистрации.<br/>" +
          "<br/>" +
          "Данной группировкой можно посмотреть, какой процент базы делает платёж после входа в ту или иную воронку.",
        dataKey: "has_payments",
      },
      {
        name: "Количество платежей",
        description:
          "Группировка пользователей на 9 групп по количеству платежей, сделанные одним пользователем после регистрации (0; 1; 2; 3; 4; 5; 6-10; 11-20; >20). То есть, в группу 6-10 попадут пользователи кто сделал от 6 до 10 платежей после регистрации.",
        dataKey: "has_payments_count",
      },
      {
        name: "Сумма всех платежей",
        description:
          "Группировка пользователей по общей сумме платежей, сделанные после регистрации. Разбивка групп по 100 руб, 1 000 руб., 10 000 руб., 100 000 руб.",
        children: [
          {
            name: "Интервал по 100 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 100 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 100 руб.<br/>" +
              "101 - 200 руб.<br/>" +
              "201 - 300 руб.<br/>" +
              "301 - 400 руб.<br/>" +
              "401 - 500 руб.<br/>" +
              "501 - 600 руб.<br/>" +
              "601 - 700 руб.<br/>" +
              "701 - 800 руб.<br/>" +
              "801 - 900 руб.<br/>" +
              "901 - 1 000 руб.<br/>" +
              "Больше 1 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 301 - 400 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 301 до 400 рублей.",
            dataKey: "has_payments_sum_100",
          },
          {
            name: "Интервал по 1 000 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 1 000 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 1 000 руб.<br/>" +
              "1 001 - 2 000 руб.<br/>" +
              "2 001 - 3 000 руб.<br/>" +
              "3 001 - 4 000 руб.<br/>" +
              "4 001 - 5 000 руб.<br/>" +
              "5 001 - 6 000 руб.<br/>" +
              "6 001 - 7 000 руб.<br/>" +
              "7 001 - 8 000 руб.<br/>" +
              "8 001 - 9 000 руб.<br/>" +
              "9 001 - 10 000 руб.<br/>" +
              "Больше 10 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 3 001 - 4 000 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 3 001 до 4 000 рублей.",
            dataKey: "has_payments_sum_1000",
          },
          {
            name: "Интервал по 10 000 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 10 000 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 10 000 руб.<br/>" +
              "10 001 - 20 000 руб.<br/>" +
              "20 001 - 30 000 руб.<br/>" +
              "30 001 - 40 000 руб.<br/>" +
              "40 001 - 50 000 руб.<br/>" +
              "50 001 - 60 000 руб.<br/>" +
              "60 001 - 70 000 руб.<br/>" +
              "70 001 - 80 000 руб.<br/>" +
              "80 001 - 90 000 руб.<br/>" +
              "90 001 - 100 000 руб.<br/>" +
              "Больше 100 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 30 001 - 40 000 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 30 001 до 40 000 рублей.",
            dataKey: "has_payments_sum_10000",
          },
          {
            name: "Интервал по 100 000 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 100 000 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 100 000 руб.<br/>" +
              "100 001 - 200 000 руб.<br/>" +
              "200 001 - 300 000 руб.<br/>" +
              "300 001 - 400 000 руб.<br/>" +
              "400 001 - 500 000 руб.<br/>" +
              "500 001 - 600 000 руб.<br/>" +
              "600 001 - 700 000 руб.<br/>" +
              "700 001 - 800 000 руб.<br/>" +
              "800 001 - 900 000 руб.<br/>" +
              "900 001 - 1 000 000 руб.<br/>" +
              "Больше 1 000 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 300 001 - 400 000 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 300 001 до 400 000 рублей.",
            dataKey: "has_payments_sum_100000",
          },
        ],
      },
    ],
  },
  {
    name: "Вебинары",
    description: "",
    children: [
      {
        name: "Дата вебинара",
        description:
          "Группировка пользователей по дате посещению вебинаров, в которых они участвовали после регистрации.",
        children: [
          {
            name: "День вебинара",
            description: "Группировка участников вебинаров по дням.",
            dataKey: "web_date",
          },
          {
            name: "Неделя вебинара",
            description: "Группировка участников вебинаров по неделям.",
            dataKey: "web_date_w",
          },
          {
            name: "Дни недели вебинара",
            description: "Группировка участников вебинаров по дням недели.",
            dataKey: "web_date_dw",
          },
          {
            name: "Месяц вебинара",
            description: "Группировка участников вебинаров по месяцам.",
            dataKey: "web_date_m",
          },
          {
            name: "Квартал вебинара",
            description: "Группировка участников вебинаров по кварталам.",
            dataKey: "web_date_q",
          },
          {
            name: "Год вебинара",
            description: "Группировка участников вебинаров по годам.",
            dataKey: "web_date_y",
          },
        ],
      },
      {
        name: "Период от регистрации до участия в вебинаре",
        description:
          "Группировка пользователей по периодам от регистрации до посещения вебинаров, в которых они участвовали после регистрации.",
        children: [
          {
            name: "Дней от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по количеству дней прошедших от регистрации до участия в вебинаре. Узнаете сколько людей на какой день после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней",
            dataKey: "web_after_reg_d",
          },
          {
            name: "Недель от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим неделям от регистрации до участия в вебинаре. Узнаете сколько людей на какую неделю после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель",
            dataKey: "web_after_reg_w",
          },
          {
            name: "Месяцев от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим месяцам от регистрации до участия в вебинаре. Узнаете сколько людей на какой месяц после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев",
            dataKey: "web_after_reg_m",
          },
          {
            name: "Кварталов от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим кварталам от регистрации до участия в вебинаре. Узнаете сколько людей на какой квартал после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов",
            dataKey: "web_after_reg_q",
          },
          {
            name: "Лет от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим годам от регистрации до участия в вебинаре. Узнаете сколько людей на какой год после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет",
            dataKey: "web_after_reg_y",
          },
        ],
      },
      {
        name: "Название вебинара",
        description:
          "Группировка пользователей по названию вебинаров, в которых они участвовали после регистрации.",
        dataKey: "web_name",
      },
      {
        name: "Коментировал на вебинаре",
        description:
          "Группировка пользователей на 2 группы: Комментировал на вебинаре и Не комментировал на вебинаре. Учитываются вебинары на которых пользователей участвовал после регистрации.",
        dataKey: "web_commentator",
      },
      {
        name: "Длительность участия на вебинаре",
        description:
          "Группировка пользователей на 9 групп по длительности участия на вебинаре, на которых они присутствовали после регистрации.<br/>" +
          "<br/>" +
          "Не участвовал<br/>" +
          "До 1 минуты<br/>" +
          "От 1 до 10 минут<br/>" +
          "От 10 до 30 минут<br/>" +
          "От 30 до 60 минут<br/>" +
          "От 1 часа до 2 часов<br/>" +
          "От 2 часов до 3 часов<br/>" +
          "От 3 часов до 4 часов<br/>" +
          "Больше 4 часов",
        dataKey: "web_duration",
      },
    ],
  },
];
export const groupingsData2 = [
  {
    name: "Реклама",
    description: "Группировка регистраций по рекламным расходам.",
    children: [
      {
        name: "Дата рекламы",
        children: [
          { name: "День рекламы", dataKey: "ads_date" },
          { name: "Неделя рекламы", dataKey: "ads_date_w" },
          { name: "День недели рекламы", dataKey: "ads_date_dw" },
          { name: "Месяц рекламы", dataKey: "ads_date_m" },
          { name: "Квартал рекламы", dataKey: "ads_date_q" },
          { name: "Год рекламы", dataKey: "ads_date_y" },
        ],
      },
      { name: "Источник рекламы", dataKey: "ads_source" },
      { name: "Рекламные кабинеты", dataKey: "ads_cabinet" },
      { name: "Название кампаний", dataKey: "ads_campaign" },
      { name: "Название группы объявлений", dataKey: "ads_adset" },
      { name: "Название объявлений", dataKey: "ads_adv" },
      { name: "ID кампаний", dataKey: "ads_campaign_id" },
      { name: "ID группы объявлений", dataKey: "ads_adset_id" },
      { name: "ID объявления", dataKey: "ads_adv_id" },
      { name: "URL ссылка", dataKey: "url_link" },
      { name: "Preview", dataKey: "preview" },
      {
        name: "UTM-метки link",
        description: "Группировка рекламных ссылок в объявлении по UTM меткам.",
        children: [
          { name: "utm_source link", dataKey: "ads_utm_source" },
          { name: "utm_medium link", dataKey: "ads_utm_medium" },
          { name: "utm_campaign link", dataKey: "ads_utm_campaign" },
          { name: "utm_content link", dataKey: "ads_utm_content" },
          { name: "utm_term link", dataKey: "ads_utm_term" },
          { name: "utm_group link", dataKey: "ads_utm_group" },
        ],
      },
      {
        name: "Теги объявлений",
        description:
          "Группировка объявлений по словам из названия рекламного объявления.",
        dataKey: "ads_tag",
      },
    ],
  },
  {
    name: "Регистрации",
    description:
      "Группировка данных в таблице по дате регистрации. Можно выбрать разную детализацию.",
    children: [
      {
        name: "Дата регистрации",
        children: [
          {
            name: "Дни регистрации",
            description:
              "Регистрации сгруппированы по каждому дню регистрации.",
            dataKey: "reg_date",
          },
          {
            name: "Недели регистрации",
            description: "Регистрации сгруппированы по неделям, по 7 дней.",
            dataKey: "reg_date_w",
          },
          {
            name: "Дни недели регистрации",
            description:
              "Регистрации сгруппированы по дням недели (понедельник, вторник, среда, четверг, пятница, суббота, воскресенье).",
            dataKey: "reg_date_dw",
          },
          {
            name: "Месяца регистрации",
            description: "Регистрации сгруппированы по месяцам.",
            dataKey: "reg_date_m",
          },
          {
            name: "Кварталы регистрации",
            description: "Регистрации сгруппированы по кварталам, по 3 месяца.",
            dataKey: "reg_date_q",
          },
          {
            name: "Года регистрации",
            description: "Регистрации сгруппированы по годам.",
            dataKey: "reg_date_y",
          },
        ],
      },
      {
        name: "UTM-метка регистрации",
        description: "Группировка данных по UTM-метке регистрации.",
        children: [
          { name: "utm_source регистрации", dataKey: "reg_utm_source" },
          { name: "utm_medium регистрации", dataKey: "reg_utm_medium" },
          { name: "utm_campaign регистрации", dataKey: "reg_utm_campaign" },
          { name: "utm_content регистрации", dataKey: "reg_utm_content" },
          { name: "utm_term регистрации", dataKey: "reg_utm_term" },
          { name: "utm_group регистрации", dataKey: "reg_utm_group" },
        ],
      },
      {
        name: "Доп. поля регистрации",
        description: "Группировка доп. полей регистрации",
        children: [],
        checkable: false,
      },
      {
        name: "URL-адрес регистрации",
        description:
          "Группировка данных по URL адресу регистрации. Данная группировка работает только с типом регистрации: SCAN. Будет сгруппировано заполнение формы по URL адресу.",
        dataKey: "reg_url",
      },
      {
        name: "Домен регистрации",
        description:
          "Группировка данных по домену, где были заполнены формы регистрации. Данная группировка работает только с типом регистрации: SCAN. ",
        dataKey: "reg_domain",
      },
      {
        name: "Название регистрации",
        description:
          "Группировка регистраций по названию регистраций. <br/>" +
          "Если выбран тип регистрации: SCAN, то название регистрации - название страницы, на которой была заполнена форма. <br/>" +
          "Если выбран тип регистрации: Нулевые заказы GС, то названием регистрации будет являться название предложения в нулевом заказе GetCourse.",
        dataKey: "reg_title",
      },
      { name: "Тег регистрации", description: "", dataKey: "reg_tag" },
      {
        name: "Номер регистрации",
        description:
          "Для типа регистрации: нулевые заказы GC это вывод номера нулевого заказа.",
        dataKey: "reg_number",
      },
      {
        name: "Технология регистрации",
        description:
          "Группировка данных по характеристикам устройств, с которых были сделаны регистрации. Данная группировка работает только с типом регистрации: SCAN. ",
        children: [
          {
            name: "Тип устройства регистрации",
            description:
              "Группировка данных по типу устройств, с которых были сделаны регистрации (компьютер, планшет или телефон). Данная группировка работает только с типом регистрации: SCAN.",
            dataKey: "reg_device_type",
          },
          {
            name: "Модель устройства регистрации",
            description:
              "Группировка регистрации по модели устройств, с которых были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_device_model",
          },
          {
            name: "Операционная система регистрации",
            description:
              "Группировка регистрации по операционной системе устройств, с которых были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_device_os",
          },
          {
            name: "Версия операционной системы регистрации",
            description:
              "Группировка регистрации по версии операционных устройств, с которых были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_device_os_ver",
          },
          {
            name: "Браузер регистрации",
            description:
              "Группировка регистрации по названию браузера, через который были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_browser",
          },
          {
            name: "Версия браузера регистрации",
            description:
              "Группировка регистрации по версии браузера, через который были сделаны регистрации. Данная группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_browser_ver",
          },
          { name: "Язык браузера регистрации", dataKey: "reg_browser_lang" },
        ],
      },
      {
        name: "Геолокация регистрации",
        description:
          "Группировка регистраций по геолокации на основе определяемого IP-адреса. Группировка работает только при типе регистрации: SCAN.",
        children: [
          {
            name: "Страна регистрации",
            description:
              "Группировка регистраций по странам, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_country",
          },
          {
            name: "Регион регистрации",
            description:
              "Группировка регистраций по регионам страны, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_region",
          },
          {
            name: "Город регистрации",
            description:
              "Группировка регистраций по городам, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_city",
          },
          {
            name: "Часовой пояс регистрации",
            description:
              "Группировка регистраций по часовым поясам, где были сделаны регистрации. Геолокация определяется по IP-адресу. Группировка работает только при типе регистрации: SCAN.",
            dataKey: "reg_geo_tz",
          },
        ],
      },
    ],
  },
  {
    name: "Пользователи",
    children: [
      {
        name: "E-mail",
        description:
          "Вывод данных по Email пользователю. Ссылка Email адреса пользователя будет активная, при переходе откроется карточка пользователя в GetCourse.",
        dataKey: "user_email",
      },
      {
        name: "id пользователя",
        description:
          "Вывод данных по ID пользователя. Ссылка ID пользователя будет активная, при переходе откроется карточка пользователя в GetCourse.",
        dataKey: "user_id",
      },
      {
        name: "id - Email - Имя - Телефон",
        description: "",
        dataKey: "user_id_ext",
      },
      {
        name: "Пол",
        description:
          "Группировка регистраций по полу. Данные собираются через дополнительные формы.",
        dataKey: "user_gender",
      },
      {
        name: "Возраст",
        description:
          "Группировка регистраций по возрасту. Данные собираются через дополнительные формы.",
        dataKey: "user_age",
      },
      {
        name: "Доп. поля пользователя",
        description: "Группировка доп. полей пользователя",
        children: [],
        checkable: false,
      },
    ],
  },
  {
    name: "Заказы",
    description:
      "Группировка регистраций пользователей по заказам. Данные группировки покажут сколько и какие заказы были созданы пользователями в разрезе по регистрациям.",
    children: [
      {
        name: "Дата заказа",
        description:
          "Группировка заказов пользователей по дате создания заказов.",
        children: [
          {
            name: "Дни заказов",
            description: "Группировка заказов по дням создания заказов.",
            dataKey: "order_date",
          },
          {
            name: "Недели заказов",
            description: "Группировка заказов по неделям создания заказов.",
            dataKey: "order_date_w",
          },
          {
            name: "Дни недели заказов",
            description: "Группировка заказов по дням недели создания заказов.",
            dataKey: "order_date_dw",
          },
          {
            name: "Месяца заказов",
            description: "Группировка заказов по месяцам создания заказов.",
            dataKey: "order_date_m",
          },
          {
            name: "Кварталы заказов",
            description: "Группировка заказов по кварталам создания заказов.",
            dataKey: "order_date_q",
          },
          {
            name: "Года заказов",
            description: "Группировка заказов по годам создания заказов.",
            dataKey: "order_date_y",
          },
        ],
      },
      {
        name: "Период от регистрации до создания заказа",
        description:
          "Группировка заказов пользователей по периоду от регистрации до создания заказа. Сможете увидеть через сколько дней или недель после регистрации пользователь создаёт заказ",
        children: [
          {
            name: "Дней от регистрации до заказа",
            description:
              "Группировка заказов по количеству дней прошедших от регистрации до создания заказа. Узнаете на какой день после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней<br>" +
              "<br>" +
              "В период 1 день попадут все заказы, у которых дата заказа = дате регистрации. <br>" +
              "<br>" +
              "В период 2 день попадут заказы, созданные на следующий день после регистрации.<br>",
            dataKey: "order_after_reg_d",
          },
          {
            name: "Недель от регистрации до заказа",
            description:
              "Группировка заказов по количеству недель прошедших от регистрации до создания заказа. Узнаете в какую неделю после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель<br>" +
              "<br>" +
              "В период 1 неделя попадут все заказы, созданные в течение 7 дней после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 по 7 марта. <br>" +
              "<br>" +
              "В период 2 неделя попадут все заказы, созданные в период с 8 по 14 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 8 по 14 марта.<br>",
            dataKey: "order_after_reg_w",
          },
          {
            name: "Месяцев от регистрации до заказа",
            description:
              "Группировка заказов по количеству месяцев прошедших от регистрации до создания заказа. Узнаете в какой месяц после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев<br>" +
              "<br>" +
              "В период 1 месяц попадут все заказы, созданные в течение 30 дней после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 по 30 марта. <br>" +
              "<br>" +
              "В период 2 месяца попадут все заказы, созданные в период с 31 по 60 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 31 марта по 29 апреля.<br>" +
              "<br>" +
              "Условно 1 месяц = 30 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 30 = число округляем до большего значения.",
            dataKey: "order_after_reg_m",
          },
          {
            name: "Кварталов от регистрации до заказа",
            description:
              "Группировка заказов по количеству кварталов прошедших от регистрации до создания заказа. Узнаете в какой квартал после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов<br>" +
              "<br>" +
              "В период 1 квартал попадут все заказы, созданные в течение 91 дня после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 1 марта по 30 мая. <br>" +
              "<br>" +
              "В период 2 квартал попадут все заказы, созданные в период с 92 по 182 день после регистрации. Если регистрация 1 марта, то попадут заказы созданные с 31 мая по 29 августа.<br>" +
              "<br>" +
              "Условно 1 квартал = 91 день.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 91 = число округляем до большего значения.",
            dataKey: "order_after_reg_q",
          },
          {
            name: "Лет от регистрации до заказа",
            description:
              "Группировка заказов по количеству лет прошедших от регистрации до создания заказа. Узнаете в какой год после регистрации больше всего создаётся заказов.<br>" +
              "<br>" +
              "Заказы сгруппируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет<br>" +
              "<br>" +
              "В период 1 год попадут все заказы, созданные в течение 365 дней после регистрации. <br>" +
              "<br>" +
              "В период 2 год попадут все заказы, созданные в период с 366 по 730 день после регистрации.<br>" +
              "<br>" +
              "Условно 1 год = 365 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата заказа - дата регистрации +1) / 365 = число округляем до большего значения.",
            dataKey: "order_after_reg_y",
          },
        ],
      },
      {
        name: "Период от создания заказа до его оплаты",
        description:
          "Группировка завершенных заказов пользователей по периоду от создания заказа до его полной оплаты. Сможете увидеть за какой период времени после создания заказа, заказ полностью оплачивается. <br>" +
          "<br>" +
          "Формула расчёта: Дата и время последнего платежа заказа со статусом завершён - Дата и время создания заказа.",
        children: [
          {
            name: "Минут от создания до оплаты заказа",
            description:
              "Группировка заказов по минутным периодом от создания заказа до его полной оплаты. Узнаете сколько проходит минут от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 мин.<br>" +
              "2 мин.<br>" +
              "3 мин.<br>" +
              "4 мин.<br>" +
              "5 мин.<br>" +
              "6 мин.<br>" +
              "7 мин.<br>" +
              "8 мин.<br>" +
              "9 мин.<br>" +
              "10 мин.<br>" +
              "11 мин.<br>" +
              "12 мин.<br>" +
              "13 мин.<br>" +
              "14 мин.<br>" +
              "15 мин.<br>" +
              "> 15 мин.",
            dataKey: "pay_after_order_m",
          },
          {
            name: "Декаминут от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в декаминутах (по десять минут) от создания заказа до его полной оплаты. Узнаете сколько проходит декаминут от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "10 мин.<br>" +
              "20 мин.<br>" +
              "30 мин.<br>" +
              "40 мин.<br>" +
              "50 мин.<br>" +
              "60 мин.<br>" +
              "> 1 час.",
            dataKey: "pay_after_order_dm",
          },
          {
            name: "Часов от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в часах от создания заказа до его полной оплаты. Узнаете сколько проходит часов от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 ч.<br>" +
              "2 ч.<br>" +
              "3 ч.<br>" +
              "4 ч.<br>" +
              "5 ч.<br>" +
              "6 ч.<br>" +
              "7 ч.<br>" +
              "8 ч.<br>" +
              "9 ч.<br>" +
              "10 ч.<br>" +
              "11 ч.<br>" +
              "12 ч.<br>" +
              "13 ч.<br>" +
              "14 ч.<br>" +
              "15 ч.<br>" +
              "16 ч.<br>" +
              "17 ч.<br>" +
              "18 ч.<br>" +
              "19 ч.<br>" +
              "20 ч.<br>" +
              "21 ч.<br>" +
              "22 ч.<br>" +
              "23 ч.<br>" +
              "24 ч.<br>" +
              "> 24 ч.",
            dataKey: "pay_after_order_h",
          },
          {
            name: "Дней от создания заказа до оплаты",
            description:
              "Группировка заказов по периодам в днях от создания заказа до его полной оплаты. Узнаете сколько проходит дней от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней.",
            dataKey: "pay_after_order_d",
          },
          {
            name: "Недель от создания заказа до оплаты",
            description:
              "Группировка заказов по недельным периодам от создания заказа до его полной оплаты. Узнаете сколько проходит недель от создания заказа до его оплаты.<br>" +
              "<br>" +
              "Заказы группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель.",
            dataKey: "pay_after_order_w",
          },
        ],
      },
      {
        name: "Доп. поля заказа",
        description: "Группировка доп. полей заказа",
        children: [],
        checkable: false,
      },
      {
        name: "Предложения",
        description:
          "Группировка данных по предложениям заказов, которые пользователи создали после регистрации.<br/>" +
          "<br/>" +
          "Эта группировка позволяет увидеть, на какие предложения, сколько заказов и оплат было создано людьми в разрезе по определённым регистрациям.",
        dataKey: "order_offers",
      },
      {
        name: "Номер заказа",
        description:
          "Данной группировкой можно вывести список номеров заказов GetCourse пользователями, которые сделали заказ.",
        dataKey: "order_number",
      },
      {
        name: "Тег предложения",
        description:
          "Группировка данных по тегам предложений заказов, которые пользователи создали после регистрации.<br>" +
          "<br>" +
          "Теги предложение получаем автоматически из выгрузки заказов.<br>" +
          "<br>" +
          "Эта группировка позволяет увидеть заказы по каждому тегу предложения.<br>" +
          "<br>" +
          "Заказы, у предложений которых не будет тегов предложений, будут сгруппированы в группу (Без тегов)<br>",
        dataKey: "order_tag",
      },
      {
        name: "Тег заказа",
        description:
          "Группировка заказов по тегам заказов, которые пользователи создали после регистрации.<br>" +
          "<br>" +
          "Теги заказов получаем автоматически из выгрузки заказов.<br>" +
          "<br>" +
          "Эта группировка позволяет увидеть заказы каждого тега заказа. <br>" +
          "<br>" +
          "Заказы, у которых нет тега заказа, будут сгруппированы в группу (Без тега).<br>",
        dataKey: "order_tag_3",
      },
      {
        name: "Есть заказ",
        description:
          "Группировка регистраций на 2 группы. В первую группу (Есть заказ) попадут регистрации, после которых пользователей создал заказа. Во вторую группу (Нет заказа) попадут регистрации, после которых пользователь НЕ сделал заказ. В фильтре можно выбрать предложение заказа, по которому нужна группировка.",
        dataKey: "has_orders",
      },
      {
        name: "Количество заказов",
        description:
          "Группировка регистраций пользователей на 9 групп по количеству созданных заказов одним пользователем после регистрации (0; 1; 2; 3; 4; 5; 6-10; 11-20; >20). То есть, в группу 6-10 попадут пользователи кто сделал от 6 до 10 заказов после регистрации.",
        dataKey: "has_orders_count",
      },
      {
        name: "Менеджер заказа",
        description:
          "Группировка данных по менеджерам заказов, которые пользователи создали после регистрации. Данные берутся из выгрузки GetCourse.<br/>" +
          "<br/>" +
          "Эта группировка позволяет увидеть статистику менеджеров в разрезе по определенным регистрациям.",
        dataKey: "order_manager",
      },
      {
        name: "Статус заказа",
        description:
          "Группировка данных по статусам заказов, которые пользователи создали после регистрации.<br/>" +
          "<br/>" +
          "Эта группировка позволяет увидеть, в каких статусах находятся заказы, созданные людьми в разрезе по определенным регистрациям.",
        dataKey: "order_status",
      },
      {
        name: "UTM-метка заказа",
        description:
          "Группировка заказов по UTM - меткам заказов. Данные берутся из выгрузки GetCourse.",
        children: [
          { name: "utm_source заказа", dataKey: "order_utm_source" },
          { name: "utm_medium заказа", dataKey: "order_utm_medium" },
          { name: "utm_campaign заказа", dataKey: "order_utm_campaign" },
          { name: "utm_content заказа", dataKey: "order_utm_content" },
          { name: "utm_term заказа", dataKey: "order_utm_term" },
          { name: "utm_group заказа", dataKey: "order_utm_group" },
        ],
      },
      {
        name: "Партнер заказа",
        description: "",
        children: [
          { name: "id партнера заказа", dataKey: "order_partner_id" },
          { name: "Email партнера заказа", dataKey: "order_partner_email" },
          { name: "Имя партнера заказа", dataKey: "order_partner_name" },
        ],
      },
    ],
  },
  {
    name: "Платежи",
    description: "Группировка платежей по разным параметрам.",
    children: [
      {
        name: "Дата платежа",
        description: "Группировка платежей по дате получения платежа.",
        children: [
          {
            name: "День платежа",
            description: "Группировка платежей по дням получения платежей.",
            dataKey: "payment_date",
          },
          {
            name: "Неделя платежа",
            description: "Группировка платежей по неделям получения платежей.",
            dataKey: "payment_date_w",
          },
          {
            name: "День недели платежа",
            description:
              "Группировка платежей по дням недели получения платежей.",
            dataKey: "payment_date_dw",
          },
          {
            name: "Месяц платежа",
            description: "Группировка платежей по месяцам получения платежей.",
            dataKey: "payment_date_m",
          },
          {
            name: "Квартал платежа",
            description:
              "Группировка платежей по кварталам получения платежей.",
            dataKey: "payment_date_q",
          },
          {
            name: "Год платежа",
            description: "Группировка платежей по годам получения платежей.",
            dataKey: "payment_date_y",
          },
        ],
      },
      {
        name: "id платежа",
        description: "Группировка платежей по ID платежа.",
        dataKey: "payment_id",
      },
      {
        name: "Период от регистрации до платежа",
        description:
          "Группировка платежей пользователей по периоду от регистрации до платежа. Сможете увидеть в какой день или неделю после регистрации происходит больше всего оплат",
        children: [
          {
            name: "Дней от регистрации до платежа",
            description:
              "Группировка платежей по количеству дней прошедших от регистрации до совершения платежа. Узнаете на какой день после регистрации больше всего совершается оплат.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней<br>" +
              "<br>" +
              "В период 1 день попадут все платежи, у которых дата платежа = дате регистрации.<br>" +
              "<br>" +
              "В период 2 день попадут платежи, совершённые на следующий день после регистрации.<br>",
            dataKey: "pay_after_reg_d",
          },
          {
            name: "Недель от регистрации до платежа",
            description:
              "Группировка платежей по количеству недель прошедших от регистрации до совершения платежа. Узнаете в какую неделю после регистрации больше всего совершается оплат.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель<br>" +
              "<br>" +
              "В период 1 неделя попадут все платежи, совершенные в течение 7 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 7 марта.<br>" +
              "<br>" +
              "В период 2 неделя попадут все платежи, совершенные в период с 8 по 14 день после регистрации. Если регистрация 1 марта, то попадут платежи созданные с 8 по 14 марта.<br>",
            dataKey: "pay_after_reg_w",
          },
          {
            name: "Месяцев от регистрации до платежа",
            description:
              "Группировка платежи по количеству месяцев прошедших от регистрации до совершения платежа. Узнаете в какой месяц после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев<br>" +
              "<br>" +
              "В период 1 месяц попадут все платежи, совершенные в течение 30 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 30 марта.<br>" +
              "<br>" +
              "В период 2 месяца попадут все платежи, совершенные в период с 31 по 60 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 марта по 29 апреля.<br>" +
              "<br>" +
              "Условно 1 месяц = 30 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 30 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_m",
          },
          {
            name: "Кварталов от регистрации до платежа",
            description:
              "Группировка платежей по количеству кварталов прошедших от регистрации до совершения платежа. Узнаете в какой квартал после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов<br>" +
              "<br>" +
              "В период 1 квартал попадут все платежи, совершенные в течение 91 дня после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 марта по 30 мая.<br>" +
              "<br>" +
              "В период 2 квартал попадут все платежи, совершенные в период с 92 по 182 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 мая по 29 августа.<br>" +
              "<br>" +
              "Условно 1 квартал = 91 день.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 91 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_q",
          },
          {
            name: "Лет от регистрации до платежа",
            description:
              "Группировка платежей по количеству лет прошедших от регистрации до совершения платежа. Узнаете в какой год после регистрации больше всего совершается платежей.<br>" +
              "<br>" +
              "Платежи группируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет<br>" +
              "<br>" +
              "В период 1 год попадут все платежи, совершенные в течение 365 дней после регистрации.<br>" +
              "<br>" +
              "В период 2 год попадут все платежи, совершенные в период с 366 по 730 день после регистрации.<br>" +
              "<br>" +
              "Условно 1 год = 365 дней.<br>" +
              "<br>" +
              "Формула расчёта: (Дата платежа - дата регистрации + 1) / 365 = число округляем до большего значения.<br>",
            dataKey: "pay_after_reg_y",
          },
        ],
      },
      {
        name: "Тип платежа",
        description: "Группировка данных по типу платежной системы.",
        dataKey: "payment_type",
      },
      {
        name: "Статус платежа",
        description: "Группировка данных по статусу платежа.",
        dataKey: "payment_status",
      },
      {
        name: "Есть платеж",
        description:
          "Группировка регистраций на 2 группы: Есть платёж и Нет платежа. В группу Есть платёж попадут пользователи, которые сделали платёж после регистрации. В группу Нет платежа, соответственно, попадут пользователи без платежей после регистрации.<br/>" +
          "<br/>" +
          "Данной группировкой можно посмотреть, какой процент базы делает платёж после входа в ту или иную воронку.",
        dataKey: "has_payments",
      },
      {
        name: "Количество платежей",
        description:
          "Группировка пользователей на 9 групп по количеству платежей, сделанные одним пользователем после регистрации (0; 1; 2; 3; 4; 5; 6-10; 11-20; >20). То есть, в группу 6-10 попадут пользователи кто сделал от 6 до 10 платежей после регистрации.",
        dataKey: "has_payments_count",
      },
      {
        name: "Сумма всех платежей",
        description:
          "Группировка пользователей по общей сумме платежей, сделанные после регистрации. Разбивка групп по 100 руб, 1 000 руб., 10 000 руб., 100 000 руб.",
        children: [
          {
            name: "Интервал по 100 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 100 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 100 руб.<br/>" +
              "101 - 200 руб.<br/>" +
              "201 - 300 руб.<br/>" +
              "301 - 400 руб.<br/>" +
              "401 - 500 руб.<br/>" +
              "501 - 600 руб.<br/>" +
              "601 - 700 руб.<br/>" +
              "701 - 800 руб.<br/>" +
              "801 - 900 руб.<br/>" +
              "901 - 1 000 руб.<br/>" +
              "Больше 1 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 301 - 400 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 301 до 400 рублей.",
            dataKey: "has_payments_sum_100",
          },
          {
            name: "Интервал по 1 000 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 1 000 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 1 000 руб.<br/>" +
              "1 001 - 2 000 руб.<br/>" +
              "2 001 - 3 000 руб.<br/>" +
              "3 001 - 4 000 руб.<br/>" +
              "4 001 - 5 000 руб.<br/>" +
              "5 001 - 6 000 руб.<br/>" +
              "6 001 - 7 000 руб.<br/>" +
              "7 001 - 8 000 руб.<br/>" +
              "8 001 - 9 000 руб.<br/>" +
              "9 001 - 10 000 руб.<br/>" +
              "Больше 10 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 3 001 - 4 000 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 3 001 до 4 000 рублей.",
            dataKey: "has_payments_sum_1000",
          },
          {
            name: "Интервал по 10 000 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 10 000 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 10 000 руб.<br/>" +
              "10 001 - 20 000 руб.<br/>" +
              "20 001 - 30 000 руб.<br/>" +
              "30 001 - 40 000 руб.<br/>" +
              "40 001 - 50 000 руб.<br/>" +
              "50 001 - 60 000 руб.<br/>" +
              "60 001 - 70 000 руб.<br/>" +
              "70 001 - 80 000 руб.<br/>" +
              "80 001 - 90 000 руб.<br/>" +
              "90 001 - 100 000 руб.<br/>" +
              "Больше 100 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 30 001 - 40 000 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 30 001 до 40 000 рублей.",
            dataKey: "has_payments_sum_10000",
          },
          {
            name: "Интервал по 100 000 руб.",
            description:
              "Группировка пользователей на 12 групп по общей сумме платежей на каждого пользователя, разбитые по 100 000 рублей:<br/>" +
              "<br/>" +
              "Нет платежей<br/>" +
              "До 100 000 руб.<br/>" +
              "100 001 - 200 000 руб.<br/>" +
              "200 001 - 300 000 руб.<br/>" +
              "300 001 - 400 000 руб.<br/>" +
              "400 001 - 500 000 руб.<br/>" +
              "500 001 - 600 000 руб.<br/>" +
              "600 001 - 700 000 руб.<br/>" +
              "700 001 - 800 000 руб.<br/>" +
              "800 001 - 900 000 руб.<br/>" +
              "900 001 - 1 000 000 руб.<br/>" +
              "Больше 1 000 000 руб.<br/>" +
              "<br/>" +
              "Например, в группу 300 001 - 400 000 руб. попадут пользователи с общей суммой платежей после выбранной регистрации от 300 001 до 400 000 рублей.",
            dataKey: "has_payments_sum_100000",
          },
        ],
      },
    ],
  },
  {
    name: "Вебинары",
    description: "",
    children: [
      {
        name: "Дата вебинара",
        description:
          "Группировка пользователей по дате посещению вебинаров, в которых они участвовали после регистрации.",
        children: [
          {
            name: "День вебинара",
            description: "Группировка участников вебинаров по дням.",
            dataKey: "web_date",
          },
          {
            name: "Неделя вебинара",
            description: "Группировка участников вебинаров по неделям.",
            dataKey: "web_date_w",
          },
          {
            name: "Дни недели вебинара",
            description: "Группировка участников вебинаров по дням недели.",
            dataKey: "web_date_dw",
          },
          {
            name: "Месяц вебинара",
            description: "Группировка участников вебинаров по месяцам.",
            dataKey: "web_date_m",
          },
          {
            name: "Квартал вебинара",
            description: "Группировка участников вебинаров по кварталам.",
            dataKey: "web_date_q",
          },
          {
            name: "Год вебинара",
            description: "Группировка участников вебинаров по годам.",
            dataKey: "web_date_y",
          },
        ],
      },
      {
        name: "Период от регистрации до участия в вебинаре",
        description:
          "Группировка пользователей по периодам от регистрации до посещения вебинаров, в которых они участвовали после регистрации.",
        children: [
          {
            name: "Дней от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по количеству дней прошедших от регистрации до участия в вебинаре. Узнаете сколько людей на какой день после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней",
            dataKey: "web_after_reg_d",
          },
          {
            name: "Недель от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим неделям от регистрации до участия в вебинаре. Узнаете сколько людей на какую неделю после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель",
            dataKey: "web_after_reg_w",
          },
          {
            name: "Месяцев от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим месяцам от регистрации до участия в вебинаре. Узнаете сколько людей на какой месяц после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев",
            dataKey: "web_after_reg_m",
          },
          {
            name: "Кварталов от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим кварталам от регистрации до участия в вебинаре. Узнаете сколько людей на какой квартал после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов",
            dataKey: "web_after_reg_q",
          },
          {
            name: "Лет от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим годам от регистрации до участия в вебинаре. Узнаете сколько людей на какой год после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет",
            dataKey: "web_after_reg_y",
          },
        ],
      },
      {
        name: "Название вебинара",
        description:
          "Группировка пользователей по названию вебинаров, в которых они участвовали после регистрации.",
        dataKey: "web_name",
      },
      {
        name: "Коментировал на вебинаре",
        description:
          "Группировка пользователей на 2 группы: Комментировал на вебинаре и Не комментировал на вебинаре. Учитываются вебинары на которых пользователей участвовал после регистрации.",
        dataKey: "web_commentator",
      },
      {
        name: "Длительность участия на вебинаре",
        description:
          "Группировка пользователей на 9 групп по длительности участия на вебинаре, на которых они присутствовали после регистрации.<br/>" +
          "<br/>" +
          "Не участвовал<br/>" +
          "До 1 минуты<br/>" +
          "От 1 до 10 минут<br/>" +
          "От 10 до 30 минут<br/>" +
          "От 30 до 60 минут<br/>" +
          "От 1 часа до 2 часов<br/>" +
          "От 2 часов до 3 часов<br/>" +
          "От 3 часов до 4 часов<br/>" +
          "Больше 4 часов",
        dataKey: "web_duration",
      },
    ],
  },
];

/**
 Для добавления в метрику информационной иконки требуется добавить
 в объект метрики парметр description
 @param {string} `description`

 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Пользователи", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */
export const metrics = [
  {
    name: "Регистрации",
    description: "",
    children: [
      {
        name: "Регистрации",
        description: "Кол-во регистраций за выбранный период.",
        dataKey: "regs_count",
      },
      {
        name: "Пользователи",
        description:
          "Количество людей, кто зарегистрировался за выбранный период. Людей может быть меньше регистраций, если один человек зарегистрировался несколько раз. Но если выбран тип регистрации: Регистрации GC, то количество пользователей совпадает с количество регистраций.",
        dataKey: "users_count",
      },
      {
        name: "Старые пользователи",
        description:
          "Кол-во людей, кто уже был в базе и ещё раз зарегистрировался. Если человека НЕ было у Вас в базе и он зарегистрировался, то он не попадёт в эту метрику.",
        dataKey: "old_users_count",
      },
      {
        name: "Новые пользователи",
        description:
          "Кол-во людей, кто первый раз зарегистрировался и попал в базу школы. Если человек уже был у Вас в базе и зарегистрировался вновь, то он не попадёт в эту метрику.",
        dataKey: "new_users_count",
      },
      {
        name: "Доля новых пользователей",
        description:
          "Процент новых пользователей, которые первый раз зарегистрировались и попали в базу за отчетный период.",
        dataKey: "new_users_part",
      },
    ],
  },
  {
    name: "Заявки",
    description: "",
    children: [
      {
        name: "Заявки",
        description:
          "Количество людей, у кого есть выбранная заявка в фильтре: Заявки",
        dataKey: "no_app_count",
      },
      {
        name: "Создатели заявок",
        description:
            "Количество людей, у кого есть выбранная заявка в фильтре: Заявки",
        dataKey: "no_app_user_count",
      },
    ],
  },
  {
    name: "Заказы",
    children: [
      {
        name: "Заказы",
        description:
          "Общее количество всех заказов пользователей кроме заказов со статусом “ЛОЖНЫЙ”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "orders_count",
      },
      {
        name: "Сумма заказов",
        description:
          "Сумма всех заказов пользователей кроме заказов со статусом “ЛОЖНЫЙ”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "orders_sum",
      },
      {
        name: "Создатели заказов",
        description:
          "Количество людей у кого есть созданные заказы кроме заказов со статусом “ЛОЖНЫЙ” созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "orders_creators_count",
      },
      {
        name: "Ср. сумма заказа",
        description:
          "Средняя сумма созданных заказов. <br/> <br/> Формула расчёта: Сумма заказов / Заказы",
        dataKey: "orders_sum_avg",
      },
      {
        name: "Ср. сумма заказов на 1 пользователя",
        description:
          "Средняя сумма заказов кроме статуса ложный, на одного пользователя, который зарегистрировался за отчётный период. <br/> <br/> Формула расчёта: Сумма заказов / Пользователей",
        dataKey: "orders_sum_avg_user",
      },
      {
        name: "CR в создателя заказа",
        description:
          "Конверсия в создателя заказа из зарегистрировавшихся пользователей. <br/> <br/> Формула расчёта: Создатели заказов / Пользователи * 100",
        dataKey: "user_to_creator_converse",
      },
      {
        name: "Заказы [Завершён]",
        description:
          "Общее количество всех заказов со статусом “Завершен”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "forders_count",
      },
      {
        name: "CR заказа в завершенный заказ",
        description:
          "Конверсия в полную оплату заказа. <br/> <br/> Формула расчёта: Заказы [Завершён] / Заказы * 100",
        dataKey: "orders_to_forders_converse",
      },
      {
        name: "Сумма заказов [Завершён]",
        description:
          "Сумма заказов со статусом “Завершен”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "forders_sum",
      },
      {
        name: "Заказы [Частично оплачен]",
        description:
          "Общее количество всех заказов со статусом “Частично оплачен”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.\n" +
          "\n",
        dataKey: "porders_count",
      },
      {
        name: "Сумма заказов [Частично оплачен]",
        description:
          "Сумма заказов со статусом “Частично оплачен”, созданные после регистрации.",
        dataKey: "porders_sum",
      },
      {
        name: "Заказы [Ожидаем оплаты]",
        description:
          "Общее количество всех заказов со статусом “Ожидаем оплаты”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций",
        dataKey: "worders_count",
      },
      {
        name: "Сумма заказов [Ожидаем оплаты]",
        description:
          "Сумма заказов со статусом “Ожидаем оплаты”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций",
        dataKey: "worders_sum",
      },
      {
        name: "Актуальные заказы",
        description:
          "Общее количество всех заказов со статусами: Завершён, Частично оплачен и Ожидаем оплаты. созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций",
        dataKey: "aorders_count",
      },
      {
        name: "Сумма актуальных заказов",
        description:
          "Сумма заказов со статусами:  Завершён, Частично оплачен и Ожидаем оплаты. Созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "aorders_sum",
      },
    ],
  },
  {
    name: "Платежи",
    children: [
      {
        name: "Платежи",
        description:
          "Количество платежей пользователей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "payments_count",
      },
      {
        name: "Сумма платежей",
        description:
          "Сумма платежей пользователей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "payments_sum",
      },
      {
        name: "Сумма платежей с комиссией платёжных систем",
        description:
          "Сумма платежей пользователей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций БЕЗ вычета комиссии платёжных систем.",
        dataKey: "payments_sum_with_commission",
      },
      {
        name: "Комиссия платёжных систем",
        description: "Сумма комиссий платёжных систем.",
        dataKey: "payments_commission_sum",
      },
      {
        name: "Покупатели",
        description:
          "Количество людей у кого есть хоть один платёж по заказу созданный после регистрации и подходящий под условия выбранного сегмента и атрибуций.",
        dataKey: "buyers_count",
      },
      {
        name: "Средний чек платежа",
        description:
          "Средняя сумма платежей. <br/> <br/> Формула расчёта: Сумма платежей / Платежи.",
        dataKey: "payments_sum_avg",
      },
      {
        name: "CR из созд. заказа в покупателя",
        description:
          "Конверсия из создателя заказа в покупателя. <br/> <br/> Формула расчёта: Создатели заказов / Покупатели * 100.",
        dataKey: "creator_to_buyer_converse",
      },
      {
        name: "CR из польз. в покупателя",
        description:
          "Конверсия из пользователя, кто зарегистрировался в покупателя. <br/> <br/> Формула расчёта: Пользователи / Покупатели * 100.",
        dataKey: "user_to_buyer_converse",
      },
      {
        name: "CR из суммы заказов в сумму платежей",
        description:
          "Конверсия из суммы заказов в сумму платежей. <br/> <br/> Формула расчёта: Сумма заказов / Сумма платежей * 100",
        dataKey: "orders_to_payments_converse",
      },
      {
        name: "Сумма платежей на 1 рег.",
        description:
          "Сумма платежей на 1 регистрацию пользователей, привлеченных за выбранный период. <br/> <br/> Формула расчёта: Сумма платежей / Регистрации.",
        dataKey: "payments_sum_per_reg",
      },
      {
        name: "Сумма платежей на 1 польз.",
        description:
          "Сумма платежей на 1  пользователей, привлеченных за выбранный период.\n" +
          "\n" +
          "Формула расчёта: Сумма платежей / Пользователи",
        dataKey: "payments_sum_per_user",
      },
      {
        name: "Ожидаемая сумма платежей",
        description:
          "Сумма оставшихся платежей по заказам со статусами: Частично оплачен и Ожидаем оплаты.\n" +
          "\n" +
          "<b>Формула:</b> Сумма заказов со статусом Ожидаем оплаты + сумма заказов со статусом частично оплачен - Сумма платежей с комиссией платёжных систем по частично оплаченным заказам",
        dataKey: "payments_sum_partial_remain",
      },
      {
        name: "Возвраты",
        description:
          "Количество платежей пользователей со статусами (ВОВЗВРАЩЁН) созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "refunds_count",
      },
      {
        name: "Сумма возвратов",
        description: "Сумма платежей со статусом - ВОЗВРАЩЕН.",
        dataKey: "refunds_sum",
      },
      {
        name: "Оформившие возврат",
        description: "Кол-во людей, у кого есть платёж со статусом ВОЗВРАЩЕН.",
        dataKey: "buyers_with_refund_count",
      },
      {
        name: "CR из пользователей в оформившего возврат",
        description:
          "Конверсия из пользователей, кто зарегистрировался в оформивших возврат. <br/> <br/> Формула расчёта: Оформившие возврат / Пользователи * 100",
        dataKey: "user_to_refunder_converse",
      },
      {
        name: "CR из покупателя в оформившего возврат",
        description:
          "Конверсия из людей, кто сделал платёж в оформивших возврат. <br/> <br/> Формула расчёта: Оформившие возврат / Покупатели * 100",
        dataKey: "buyer_to_refunder_converse",
      },
    ],
  },
  {
    name: "Вебинары",
    children: [
      {
        name: "Участники вебинара",
        description:
          "Количество людей, кто был на вебинаре после регистрации. В фильтре можете выбрать нужный вебинар. Если в фильтре не указывать вебинар, то будут учитываться все вебинары.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_users_count",
      },
      {
        name: "Был на веб. < 1 мин.",
        description:
          "Количество людей, кто был на вебинаре меньше 1 минуты. То есть, зашёл, быстро вышел и больше не заходил. <br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_1min_users_count",
      },
      {
        name: "Был на веб. < 5 мин.",
        description:
          "Количество людей, кто был на вебинаре меньше 5 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_5min_users_count",
      },
      {
        name: "Был на веб. < 15 мин.",
        description:
          "Количество людей, кто был на вебинаре меньше 15 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_15min_users_count",
      },
      {
        name: "Был на веб. < 30 мин.",
        description:
          "Количество людей, кто был на вебинаре меньше 30 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_users_count",
      },
      {
        name: "Был на веб. < 1 час.",
        description:
          "Количество людей, кто был на вебинаре меньше 60 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_users_count",
      },
      {
        name: "Был на веб. > 30 мин.",
        description:
          "Количество людей, кто был на вебинаре больше 30 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_g_users_count",
      },
      {
        name: "Был на веб. > 1 час.",
        description:
          "Количество людей, кто был на вебинаре больше 60 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_g_users_count",
      },
      {
        name: "Был на веб. > 2 час.",
        description:
          "Количество людей, кто был на вебинаре больше 120 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_120min_g_users_count",
      },
      {
        name: "Был на веб. > 3 час.",
        description:
          "Количество людей, кто был на вебинаре больше 180 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_180min_g_users_count",
      },
      {
        name: "Был на веб. > 4 час.",
        description:
          "Количество людей, кто был на вебинаре больше 240 минут.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_240min_g_users_count",
      },
      {
        name: "Доля кто был на веб. < 1 мин.",
        description:
          "Доля людей, кто был на вебинаре меньше 1 минуты от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещенные пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар еще на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_1min_users_part",
      },
      {
        name: "Доля кто был на веб. < 5 мин.",
        description:
          "Доля людей, кто был на вебинаре меньше 5 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_5min_users_part",
      },
      {
        name: "Доля кто был на веб. < 15 мин.",
        description:
          "Доля людей, кто был на вебинаре меньше 15 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_15min_users_part",
      },
      {
        name: "Доля кто был на веб. < 30 мин.",
        description:
          "Доля людей, кто был на вебинаре меньше 30 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_users_part",
      },
      {
        name: "Доля кто был на веб. < 1 час.",
        description:
          "Доля людей, кто был на вебинаре меньше 60 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_users_part",
      },
      {
        name: "Доля кто был на веб. > 30 мин.",
        description:
          "Доля людей, кто был на вебинаре больше 30 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_g_users_part",
      },
      {
        name: "Доля кто был на веб. > 1 час.",
        description:
          "Доля людей, кто был на вебинаре больше 60 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_g_users_part",
      },
      {
        name: "Доля кто был на веб. > 2 час.",
        description:
          "Доля людей, кто был на вебинаре больше 120 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_120min_g_users_part",
      },
      {
        name: "Доля кто был на веб. > 3 час.",
        description:
          "Доля людей, кто был на вебинаре больше 180 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_180min_g_users_part",
      },
      {
        name: "Доля кто был на веб. > 4 час.",
        description:
          "Доля людей, кто был на вебинаре больше 240 минут от общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_240min_g_users_part",
      },
      {
        name: "Нажал кнопку на веб.",
        description:
          "Количество людей, кто нажал на кнопку или на картинку под вебинаром. В фильтре: Действия на вебинаре можно выбрать нужный тип. Если не выбирать, то будут учитываться все нажатия и на кнопку и на картинку, зависит от того, что Вы используете.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_clickers_count",
      },
      {
        name: "CR из участника в нажав. кнопку",
        description:
          "Конверсия показывает сколько людей нажали на кнопку на вебинаре из общего количества участников вебинара.<br>" +
          "<br>" +
          "Формула расчета: Нажал кнопку на веб. / Участники вебинара * 100<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещенные пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар еще на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_user_to_clicker_converse",
      },
      {
        name: "CR из нажав. кнопку в создат. заказа",
        description:
          "Конверсия показывает сколько людей создала заказ после нажатия на кнопку на вебинаре.<br>" +
          "<br>" +
          "Учитываются только люди кто посетил вебинар и у которых дата посещения вебинара = дате созданного заказа. В фильтре можно выбрать нужный вебинар и необходимые заказы по предложению или по тегу предложения.<br>" +
          "<br>" +
          "Формула расчёта: Создатели заказов / Нажал кнопку на веб. * 10<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации. Если не выбрано предложение заказа или тег предложения, то будут учитываться все заказы пользователя, который посетил вебинар и у которого дата создания заказа = дате участия на вебинаре. Если не выбран тип действия на вебинаре, то будут учитываться и нажатия на кнопку и нажатия на картинку.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "clicker_to_creator_converse",
      },
      {
        name: "CR из пользователя в участника веб.",
        description:
          "Конверсия показывает сколько людей посетило вебинар из общего количества зарегистрировавшихся на вебинар.<br>" +
          "<br>" +
          "Формула расчёта: Участники вебинара / Пользователь * 100<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "user_to_web_user_converse",
      },
      {
        name: "CR из участника веб. в создат. заказа",
        description:
          "Конверсия показывает сколько людей создали заказ из общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитываются только люди кто посетил вебинар и у которых дата посещения вебинара = дате созданного заказа. В фильтре можно выбрать нужный вебинар и необходимые заказы по предложению или по тегу предложения.<br>" +
          "<br>" +
          "Формула расчёта: Создатели заказов / Участники вебинара * 100<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации. И если не выбрано предложение заказа или тег предложения, то будут учитываться все заказы пользователя, который посетил вебинар и у которого дата создания заказа = дате участия на вебинаре.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_user_to_creator_converse",
      },
      {
        name: "CR из участника веб. в заявку",
        description:
          "Конверсия показывает сколько людей оставили заявку из общего количества участников вебинара.<br>" +
          "<br>" +
          "Учитываются только люди кто посетил вебинар и у которых дата посещения вебинара = дате заявки. В фильтре можно выбрать нужный вебинар и необходимые заявки.<br>" +
          "<br>" +
          "Формула расчёта: Создатели заказов / Участники вебинара * 100<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации. И если не выбрано предложение заказа или тег предложения, то будут учитываться все заказы пользователя, который посетил вебинар и у которого дата создания заказа = дате участия на вебинаре.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_user_to_no_app_converse",
      },
    ],
  },
  {
    name: "Реклама",
    children: [
      {
        name: "Клики",
        description:
          "Количество кликов по рекламным объявлениям. Данная метрика подтягивается автоматически из рекламных кабинетов.",
        dataKey: "ads_clicks_count",
      },
      {
        name: "Показы",
        description: "Количество показов объявлений",
        dataKey: "ads_impressions",
      },
      {
        name: "CPM",
        description:
          "Cost per Mille, плата за 1000 показов.<br>" +
          "<br>" +
          "Формула расчета: Рекламные расходы / Показы",
        dataKey: "cpm",
      },
      {
        name: "CPC",
        description:
          "Цена клика.<br>" +
          "<br>" +
          "Формула расчета: Рекламные расходы / Клики",
        dataKey: "cpc",
      },
      {
        name: "CTR",
        description:
          "Конверсия из показа по рекламному объявлению в клик.<br>" +
          "<br>" +
          "Формула расчета: Клики / Показы * 100",
        dataKey: "ctr",
      },
      {
        name: "CR страницы",
        description:
          "Конверсия в регистрацию. <br>" +
          "<br>" +
          "Формула расчета: Регистрации / Клики * 100",
        dataKey: "ads_click_to_reg_converse",
      },
      {
        name: "Рекламные расходы",
        description:
          "Сумма рекламных расходов рекламных объявлений, по которым были регистрации. Данные берём из рекламных кабинетов. <br>" +
          "<br>" +
          "Для того, чтобы корректно работал сервис аналитики и Вы видели какое объявление сколько принесло регистраций и денег, необходимо в ссылке каждого рекламного объявления изменить UTM - метку. Нужно, чтобы ссылка каждого объявления имела utm_content=№ объявления. Только таким образом система будет понимать сколько пользователей зарегистрировалось по данному объявлению, сколько денег принесли эти пользователи  и сколько было рекламного расхода по данному объявлению. Номер объявления проставляется автоматически в каждой системе, если в UTM метку добавить шаблон.<br>" +
          "<br>" +
          "Для Google Adwords - utm_content={creative}<br>" +
          "Для таргетированной рекламы в Facebook - utm_content={{ad.id}}<br>" +
          "Для таргетированной рекламы в Вконтакте - utm_content={ad_id}<br>" +
          "Для Яндекс.Директ - utm_content={ad_id}<br>" +
          "<br>" +
          "Если по рекламному объявлению был расход, но не было регистраций или регистрации были, но в метке utm_content не было номера объявления, то этот рекламный расход не попадёт в эту метрику.<br>",
        dataKey: "ads_spend",
      },
      // { name: 'Рекламные расходы РК', description: 'Общая сумма рекламных расходов из рекламных кабинетов.', dataKey: 'ads_spend_cabinet' },
      {
        name: "Прибыль",
        description: "Формула расчета: Сумма платежей - Рекламные расходы",
        dataKey: "profit",
      },
      {
        name: "Прибыль на 1 рег.",
        description:
          "Сколько в среднем денег приносит 1 регистрация.<br>" +
          "<br>" +
          "Формула расчета: Прибыль / Регистрации",
        dataKey: "profit_per_reg",
      },
      {
        name: "Цена регистрации",
        description:
          "Среднее количество денег, потраченное на одну регистрацию за выбранный период. ",
        dataKey: "reg_price",
      },
      {
        name: "Цена пользователя",
        description:
          "Среднее количество денег потраченное на привлечение 1 человека, который зарегистрировался за выбранный период. <br/> <br/> Формула расчёта: Сумма рекламных расходов за выбранный период / кол-во людей, кто зарегистрировался за выбранный период.",
        dataKey: "user_price",
      },
      {
        name: "Цена заказа",
        description:
          "Среднее количество денег потраченное на создание одного заказа пользователями, привлеченными рекламой за выбранный период. <br/> <br/> Формула расчёта: Сумма рекламных расходов за выбранный период / Заказы",
        dataKey: "orders_price",
      },
      {
        name: "Цена создателя заказа",
        description:
          "Среднее количество денег потраченное на привлечение одного создателя заказа, привлеченного рекламой за выбранный период. <br/> <br/> Формула расчёта: Сумма рекламных расходов за выбранный период / Создатели заказов",
        dataKey: "orders_creators_price",
      },
      {
        name: "Цена заказа [Завершён]",
        description:
          "Среднее количество денег потраченное на создание заказа со статусом [Завершен], пользователями, привлеченными рекламой за выбранный период. <br/> <br/> Формула расчёта: Сумма рекламных расходов за выбранный период / Заказы со статусом [Завершён]",
        dataKey: "forders_price",
      },
      {
        name: "Цена покупателя",
        description:
          "Среднее количество денег потраченное на привлечение одного покупатели за выбранный период. <br/> <br/> Формула расчёта: Сумма рекламных расходов за выбранный период / Покупатели.",
        dataKey: "buyers_price",
      },
      {
        name: "Цена платежа",
        description:
          "Среднее количество денег потраченное на создание одного платежа пользователями, привлеченными рекламой за выбранный период. <br/> <br/> Формула расчёта: Сумма рекламных расходов за выбранный период / Платежи.",
        dataKey: "payments_price",
      },
      {
        name: "Цена участника вебинара",
        description:
          "Средняя стоимость участника вебинара, показывает, сколько в среднем нужно потратить денег на привлечение одного участника вебинара.<br>" +
          "<br>" +
          "Формула расчёта: Сумма рекламных расходов / участники вебинара<br>" +
          "<br>" +
          "<br>" +
          "В формуле учитывается расход из рекламных кабинетов, который был потрачен на привлечение людейй на вебинаре. Т.е. в выбранной регистрации в метке UTM content должен содержаться номер рекламного объявления, по которому мы знаем сколько и когда было расхода.<br>" +
          "<br>" +
          "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
          "<br>" +
          "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
          "<br>" +
          "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_user_price",
      },
      {
        name: "Прибыль на 1 пользователя",
        description:
          "Сколько в среднем денег приносит 1 пользователь.<br>" +
          "<br>" +
          "Формула расчета: Прибыль / Пользователи",
        dataKey: "profit_per_user",
      },
      {
        name: "ROMI",
        description:
          "Формула расчета: Сумма платежей - Рекламные расходы / Рекламные расходы * 100",
        dataKey: "romi",
      },
      {
        name: "ROAS",
        description: "Формула расчета: Сумма платежей / Рекламные расходы",
        dataKey: "roas",
      },
      {
        name: "ДРР",
        description:
          "Формула расчета: Рекламные расходы / Сумма платежей * 100",
        dataKey: "drr",
      },
      {
        name: "Переход с рекламы",
        description:
          "Количество просмотренных рекламируемых страниц. Данные собираются скриптом отслеживания. Учитываются только страницы, у которых в метке хита (просмотра страницы) utm_conten есть номер объявления.",
        dataKey: "visits_count",
      },
      {
        name: "Посетители рекламируемой страницы",
        description:
          "Уникальное количество людей кто перешёл с рекламы на рекламируемую страницу. Данные собираются скриптом отслеживания. Учитываются только страницы, у которых в ",
        dataKey: "visitors_count",
      },
      {
        name: "CR из перехода в регистрацию",
        description:
          "Конверсия из количества просмотренных рекламируемых страниц в регистрацию. Данные собираются скриптом отслеживания. Учитываются только страницы, у которых в метке хита (просмотра страницы) utm_conten есть номер объявления. ",
        dataKey: "visit_to_reg_converse",
      },
      {
        name: "CR из посетителей в пользователей",
        description:
          "Конверсия из уникального количества людей кто просмотрел рекламируемую страницу в тех кто зарегистрировался. Данные собираются скриптом отслеживания. Учитываются только страницы, у которых в метке хита (просмотра страницы) utm_conten есть номер объявления.",
        dataKey: "visitor_to_user_converse",
      },
    ],
  },
  {
    name: "Просмотры страниц после рег.",
    children: [
      {
        name: "Просмотры страниц после рег.",
        description:
          "Количество просмотренных страниц пользователем после регистрации в воронку.",
        dataKey: "reg_visits_count",
      },
      {
        name: "Посетители страниц после рег.",
        description:
          "Количество людей кто просмотрел страницу после регистрации в воронку.",
        dataKey: "reg_visitors_count",
      },
    ],
  },
];
