import React, { FC, useEffect, useMemo, useState } from "react";

import {
  DownOutlined,
  CloseOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Tree, Input, Modal, Row, Col, Button, Tooltip } from "antd";
import { ReactSortable } from "react-sortablejs";

import { ads_keys } from "./Groupings";
import { ads_metrics_keys } from "./Metrics";
import {
  filterGroupsDisabledData,
  getChildrenKey,
  loopSearch,
  getMetricsParentKey,
} from "../../../../../../helpers/utils/reports";
import { useActions } from "../../../../hooks/useActions";
import { useForceUpdate } from "../../../../hooks/useForceUpdate";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { getLineHeightForModalSettings } from "../../../../../../helpers/utils/functions";
import { useLoadGroupingsData } from "../../../../hooks/useLoadGroupingsData";
function ClearOutlined() {
  return null;
}

const ModalSettings: FC<any> = ({
  title,
  dataItems,
  listKeys,
  defSelected,
  defChecked,
  actionSet,
  isDisable,
  hidePersonalDataGroups = false,
}) => {
  const forceUpdate = useForceUpdate();

  const { groupings, metrics, metrics_filters_update } = useTypedSelector(
    (state) => state.table
  );
  const { setMetricsFiltersUpdate } = useActions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState(dataItems);
  const [selected, setSelected] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const userRole = useTypedSelector((state) => state.currentUser.role);
  const { onLoadData } = useLoadGroupingsData(setData);

  useEffect(() => {
    setTimeout(() => {
      if (metrics_filters_update) {
        if (title === "Группировки") {
          let _gr = groupings.map((item) => {
            let _key = getChildrenKey(item.dataKey, data);

            if (_key) {
              return {
                ...item,
                key: _key,
              };
            } else {
              return item;
            }
          });

          setSelected(_gr);
          setCheckedKeys(_gr.map((item) => item.key));
        } else if (title === "Метрики") {
          let _mr = metrics.map((item) => {
            let _key = getChildrenKey(item.dataKey, data);

            if (_key) {
              return {
                ...item,
                key: _key,
              };
            } else {
              return item;
            }
          });

          setSelected(_mr);
          setCheckedKeys(_mr.map((item) => item.key));
        }

        setMetricsFiltersUpdate(false);
      }
    }, 100);
  }, [metrics_filters_update]);

  useEffect(() => {
    if (title === "Группировки") {
      if (selected.length > 0) {
        const _keys = title === "Группировки" ? ads_keys : ads_metrics_keys;
        selected.forEach((item: any, index: number) => {
          if (_keys.includes(item.dataKey)) {
            return;
          }
        });
      } else {
        let _data = data;
        setData(_data);
      }

      forceUpdate();
    }
  }, [selected]);

  useEffect(() => {
    if (title === "Метрики") {
      let _data = data;
      setData(_data);
      forceUpdate();
    }
  }, []);

  useEffect(() => {
    if (title === "Группировки") {
      let _data = data;
      setData(_data);
      forceUpdate();
    }
  }, []);

  useEffect(() => {
    if (hidePersonalDataGroups) {
      setData(
        filterGroupsDisabledData(data, [
          "user_id_3",
          "user_email_3",
          "user_id_ext_3",
        ])
      );
    } else {
      setData(filterGroupsDisabledData(data, [""]));
    }
  }, [hidePersonalDataGroups, data, dataItems]);

  if (userRole === "user") {
    hidePersonalDataGroups = true;
  }

  useEffect(() => {
    setSelected(defSelected);
  }, [defSelected]);

  useEffect(() => {
    setCheckedKeys(defChecked);
  }, [defChecked]);
  useEffect(() => {
    if (title === "Метрики") {
      checkedKeys.length !== selected.length &&
        setCheckedKeys(() => selected.map((el: { key: string }) => el.key));
    }
  }, [checkedKeys]);
  const removeSelect = (key: string) => {
    setSelected((prev: any) => {
      return [...prev].filter((item: any) => item.key !== key);
    });

    setCheckedKeys((prev: any) => {
      return [...prev].filter((item) => item !== key);
    });
  };

  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (_checkedKeys: any, e: any) => {
    if (
      e.nativeEvent.srcElement.nodeName === "svg" ||
      e.nativeEvent.srcElement.innerText === "Да" ||
      e.nativeEvent.srcElement.innerText === "Нет" ||
      e.nativeEvent?.srcElement?.className === "settings" ||
      e.nativeEvent.srcElement.nodeName === "path"
    ) {
      return;
    }
    setCheckedKeys(_checkedKeys);
    setSelected((prev: any) => {
      let newData: any = [...prev];
      if (e.checked) {
        newData.push({ ...e.node });
      } else {
        newData = newData.filter((item: any) => item.key !== e.node.key);
      }
      return newData;
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value !== "") {
      const reg = new RegExp(value, "i");

      let _expandedKeys: any = listKeys
        .map((item: any) => {
          if (item.name.search(reg) > -1) {
            return getMetricsParentKey(item.key, dataItems);
          }
          return null;
        })
        .filter(
          (item: any, i: number, self: any) => item && self.indexOf(item) === i
        );

      setExpandedKeys(_expandedKeys);
      setSearchValue(value);
      setAutoExpandParent(true);
    } else {
      setExpandedKeys([]);
      setSearchValue("");
      setAutoExpandParent(false);
    }
  };

  const handleOk = () => {
    actionSet(selected);
    setIsModalVisible(false);
    setExpandedKeys([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const clearCheckboxes = () => {
    setSelected([]);
    setCheckedKeys([]);
    forceUpdate();
  };
  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto scroll";
    }

    return () => {
      document.body.style.overflow = "auto scroll";
    };
  }, [isModalVisible]);

  const listHeight = useMemo(
    () => getLineHeightForModalSettings(selected?.length || 0),
    [selected?.length]
  );

  return (
    <>
      <Button
        type="default"
        disabled={isDisable}
        onClick={() => setIsModalVisible(true)}
      >
        {title}
      </Button>

      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Применить"
        onCancel={handleCancel}
        cancelText="Отмена"
        centered
        width={800}
        className="modal-settings"
        destroyOnClose
      >
        <Row gutter={12}>
          <Col span={10}>
            <Input
              style={{ marginBottom: 8 }}
              placeholder={"Поиск " + title.toLowerCase()}
              onChange={onChange}
              allowClear
            />
            <div style={{ height: listHeight, overflow: "auto" }}>
              <Tree.DirectoryTree
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                blockNode
                checkable
                switcherIcon={<DownOutlined />}
                showIcon={false}
                selectable={false}
                loadData={onLoadData}
                treeData={loopSearch(data, searchValue)}
                titleRender={(nodeData: any) => {
                  return (
                    <>
                      {nodeData.description ? (
                        <>
                          {nodeData.disableCheckbox ? (
                            <Tooltip
                              placement="top"
                              title={
                                <div className="page-title-tooltip-text">
                                  Контактные данные пользователей не доступны.
                                  Обратитесь к администратору, чтобы получить к
                                  ним доступ.
                                </div>
                              }
                              color="#fff"
                              zIndex={9999}
                              overlayClassName="page-title-tooltip"
                            >
                              <span className="group-item-icon-wrapper">
                                {nodeData.title}
                                <span
                                  style={{ marginRight: 10, marginLeft: 5 }}
                                >
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      <div
                                        className="page-title-tooltip-text"
                                        dangerouslySetInnerHTML={{
                                          __html: nodeData.description,
                                        }}
                                      ></div>
                                    }
                                    color="#fff"
                                    zIndex={9999}
                                    overlayClassName="page-title-tooltip"
                                  >
                                    <QuestionCircleOutlined
                                      style={{ color: "#ccc", fontSize: 16 }}
                                    />
                                  </Tooltip>
                                </span>
                              </span>
                            </Tooltip>
                          ) : (
                            <span className="group-item-icon-wrapper">
                              {nodeData.title}
                              <span style={{ marginRight: 10, marginLeft: 5 }}>
                                <Tooltip
                                  placement="bottom"
                                  title={
                                    <div
                                      className="page-title-tooltip-text"
                                      dangerouslySetInnerHTML={{
                                        __html: nodeData.description,
                                      }}
                                    ></div>
                                  }
                                  color="#fff"
                                  zIndex={9999}
                                  overlayClassName="page-title-tooltip"
                                >
                                  <QuestionCircleOutlined
                                    style={{ color: "#ccc", fontSize: 16 }}
                                  />
                                </Tooltip>
                              </span>
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {nodeData.disableCheckbox ? (
                            <Tooltip
                              placement="top"
                              title={
                                <div className="page-title-tooltip-text">
                                  Контактные данные пользователей не доступны.
                                  Обратитесь к администратору, чтобы получить к
                                  ним доступ.
                                </div>
                              }
                              color="#fff"
                              zIndex={9999}
                              overlayClassName="page-title-tooltip"
                            >
                              <span>{nodeData.title}</span>
                            </Tooltip>
                          ) : (
                            <span>{nodeData.title}</span>
                          )}
                        </>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                type="default"
                icon={<ClearOutlined />}
                onClick={() => clearCheckboxes()}
              >
                Очистить выбор
              </Button>
            </div>
          </Col>

          <Col span={14}>
            <ReactSortable
              list={selected.map((item: any) => ({ ...item, chosen: true }))}
              className="modalSortable__container"
              setList={setSelected}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
            >
              {selected.map((item: any) => (
                <div className="drag-item" key={item.key}>
                  <span className="drag-handler">
                    <EllipsisOutlined rotate={90} />
                    <span
                      style={{
                        backgroundColor: item?.background
                          ? item.background
                          : "unset",
                        width: "100%",
                      }}
                      className="drag-label"
                    >
                      {item.label || item.name}
                    </span>
                  </span>
                  <div
                    onClick={() => removeSelect(item.key)}
                    className="drag-remove"
                  >
                    <CloseOutlined />
                  </div>
                </div>
              ))}
            </ReactSortable>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalSettings;
