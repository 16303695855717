import React, { FC, useEffect } from "react";
import { useTypedSelector } from "../../../shared/common/hooks/useTypedSelector";
import { useActions } from "../../../shared/common/hooks/useActions";
import TypeIcon from "../../../shared/common/components/reports/params/attribution/icons/TypeIcon";
import FirstRegIcon from "../../../shared/common/components/reports/params/attribution/icons/FirstRegIcon";
import LastRegIcon from "../../../shared/common/components/reports/params/attribution/icons/LastRegIcon";
import {
  defaultGroupings,
  defaultMetrics,
} from "../../../app/providers/redux/slices/reports/table/tableSlice";
import MainLayout from "../../../shared/common/components/layouts/MainLayout";
import PageHead from "../../../shared/common/components/page-header/PageHeader";
import Loader from "../../../shared/common/components/loader/Loader";
import UniversalReportTitle from "../../../shared/common/components/reports/un-title/UniversalReportTitle";
import { ToggleChart } from "../../../widgets/ToggleChart";
import { chartDetails } from "../../../shared/common/constants/details";
import Params from "../../../shared/common/components/reports/params/Params";
import Chart from "../../../shared/common/components/reports/ad-chart/Chart";
import Table from "../../../shared/common/components/reports/visit-table/Table";
import { listKeysMetrics } from "../../../shared/common/components/reports/visit-table/components/Metrics";
import { listKeysGroupings } from "../../../shared/common/components/reports/visit-table/components/Groupings";

const defaultMetricsOrderNotPrepare = [
  // Дефолтные метрики
  "regs_count_6",
  "visitors_count_6",
  "hits_count_6",
  "orders_count_6",
].map((dataKey) =>
  listKeysMetrics.find((item: any) => item.dataKey === dataKey)
);
export const defaultMetricsVisits = defaultMetricsOrderNotPrepare.map(
  (item) => {
    return { ...item };
  }
);
const defaultGroupingsOrderNotPrepare = [
  // Дефолтные группировки
  "first_hit_url_6",
  "hit_url_6",
].map((dataKey) =>
  listKeysGroupings.find((item: any) => item.dataKey === dataKey)
);
export const defaultGroupingsVisits = defaultGroupingsOrderNotPrepare.map(
  (item) => {
    return { ...item };
  }
);
const Visits: FC = () => {
  const { isChartVisible } = useTypedSelector((state) => state.chart);
  useEffect(() => {
    document.body.style.overflow = "scroll";
    document.body.style.overflowX = "auto";
  }, []);
  const { isChartLoading, isTableLoading } = useTypedSelector(
    (state) => state.meta
  );
  const {
    setFilters,
    setFiltersTable,
    setCurrentReport,
    setMetrics,
    setCurrentSegment,
    setSortTable,
    setMetaCompare,
    setGroupings,
    setSeries,
    setMetaChartFetching,
    setCurrentFunnel,
  } = useActions();
  const attrItems = [
    {
      id: "type_applications",
      name: "Тип регистрации:",
      selectValues: [
        { name: "Нулевые заказы GC", id: "2", icon: null },
        { name: "Прямая продажа", id: "4", icon: null },
        { name: "Регистрации GC", id: "0", icon: null },
        { name: "SCAN", id: "1", icon: null },
        //{ name: "Ручное добавление", id: "3", icon: null },
      ],
      description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Прямая продажа - эта регистрация создаётся автоматически в SCANе при создании платного заказа. Название регистрации будет являться название платного предложения;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
      icon: <TypeIcon />,
    },
    {
      id: "attribution",
      name: "Атрибуция:",
      selectValues: [
        { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
        { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
      ],
      description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
      icon: null,
    },
  ];

  useEffect(() => {
    setMetaCompare({ value: false });
    setMetrics({ data: defaultMetricsVisits });
    setGroupings({ data: defaultGroupingsVisits });
    //setFilters([])
    setFiltersTable([]);
    setCurrentReport({});
    setSortTable({});
    setCurrentSegment(null);
    setSeries([]);
    setTimeout(() => {
      setMetaChartFetching({ value: true });
    }, 700);
    return () => {
      setMetaCompare({ value: false });
      setMetrics({ data: defaultMetrics });
      setGroupings({ data: defaultGroupings });
      setFilters([]);
      setFiltersTable([]);
      setCurrentReport({});
      setSortTable({});
      setCurrentSegment(null);
      setSeries([]);
      setCurrentFunnel([]);
    };
  }, []);
  return (
    <MainLayout pageTitle="Отчёт по посещениям">
      <div className="page-wrapper">
        <PageHead title="Отчёт по посещениям" description={""} />
        <div className="page-content">
          <Loader loading={isTableLoading || isChartLoading}>
            <UniversalReportTitle />

            <Params
              filtersFilter={[
                "Пользователи",
                "Регистрации",
                "Заявки",
                "Заказы",
                "Платежи",
                "Вебинары",
              ]}
              attributionItems={attrItems}
              detailsItems={chartDetails}
            />
          </Loader>

          <Loader loading={isTableLoading || isChartLoading}>
            {!isChartVisible && <ToggleChart />}
          </Loader>
          {isChartVisible && <Chart />}
          <Table />
        </div>
      </div>
    </MainLayout>
  );
};

export default Visits;
